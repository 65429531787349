import React from 'react';
import StepInput from '../Fields/StepInput/StepInput';
import PhoneInput from '../Fields/StepInput/PhoneInput';
import Select from '../Fields/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import LocationSearchField from '../Fields/SearchField/LocationSearchField';
import BankSearchField from '../Fields/SearchField/BankSearchField';
import EGNInput from '../Fields/StepInput/EGNInput';
import StepNumberInput from '../Fields/StepInput/StepNumberInput';

const MANDATORY_ERROR_MESSAGE = 'Полето е задължително';

/**
 * Generates different field types based on the given attributes.
 *
 * @param {Array} attributes: array of field definitions (name, displayName, required)
 * @param {Function} onFieldChange: called when a field changes its value
 * @param {Function} onFieldBlur: called when a field is blurred to check the field state (error or success)
 * @param {Array} errorFields: an array of attribute names representing fields with an error
 * @param {Object} locationCodes: key-value pairs of location-code: location-object
 * @param {Array} bankData: bank data items: code, name
 * @param {Function} getFieldLabel: returns the field label by the given displayName of the attribute
 */
const FieldGenerator = ({ attributes, onFieldChange, onFieldBlur, errorFields, locationCodes, bankData, getFieldLabel }) => (
  <>
    {attributes.map((attribute) => {
      const commonProps = {
        key: attribute.name,
        label: getFieldLabel(attribute.displayName),
        tooltip: attribute.info,
        isMandatory: attribute.required,
        defaultValue: attribute.value,
        onChange: (nextValue) => {
          onFieldChange({
            name: attribute.name,
            value: nextValue
          });
        },
        onBlur: () => {
          onFieldBlur({
            name: attribute.name
          });
        },
        error: errorFields[attribute.name]
          && (errorFields[attribute.name] !== true ? errorFields[attribute.name] : MANDATORY_ERROR_MESSAGE)
      };

      switch (attribute.type) {
        case 'STRING':
          return <StepInput {...commonProps} />;
        case 'PHONE':
          return <PhoneInput {...commonProps} />;
        case 'EGN':
          return <EGNInput {...commonProps} />;
        case 'NUMBER':
          return <StepNumberInput {...commonProps} />;
        case 'BOOLEAN':
          return <Checkbox {...commonProps} />;
        case 'ENUM':
          return <Select {...commonProps} options={attribute.enumValues.map((item) => item.value)} />;
        case 'LOCATION':
          return (
            <LocationSearchField
              {...commonProps}
              defaultValue={locationCodes[attribute.value]}
              idName="code"
              getOptionLabel={(option) => (
                option ? `${option.name} (${option.region})` : ''
              )}
              onChange={(option) => {
                onFieldChange({
                  name: attribute.name,
                  value: option && option.code,
                  type: attribute.type,
                  location: option
                });
              }}
            />
          );
        case 'BANKDATA':
          return (
            <BankSearchField
              {...commonProps}
              defaultValue={attribute.value && bankData.find((item) => item.code === attribute.value)}
              idName="code"
              getOptionLabel={(option) => (
                option ? option.name : ''
              )}
              onChange={(option) => {
                onFieldChange({
                  name: attribute.name,
                  value: option && option.code
                });
              }}
            />
          );
        default:
          return <div key={attribute.name} style={{ lineHeight: '60px' }}>Input type {attribute.type} not yet implemented</div>;
      }
    })}
  </>
);

export default FieldGenerator;
