import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import LatestNewsCarousel from 'components/LatestNewsCarousel/LatestNewsCarousel';
import Button from 'components/Button/Button';
import { PATHS } from 'config';
import './News.scss';

const News = () => (
  <div className="news-section">
    <SectionTitle label="Новини" />
    <LatestNewsCarousel />
    <Button label="Всички новини" className="button" href={PATHS.NEWS} id="all-news" />
  </div>
);

export default News;
