import React from 'react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page/Page';
import img from 'assets/img/faq.svg';
import FaqAccordion from 'components/FaqAccordion/FaqAccordion';
import Button from 'components/Button/Button';
import './FaqPage.scss';

const FaqPage = () => (
  <Page className="faq-page">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Често задавани въпроси | Kredy.bg</title>
    </Helmet>

    <div className="faq-page-wrapper">
      <h1>Често задавани въпроси</h1>

      <div className="faq-page-content">
        <FaqAccordion />
        <img src={img} alt="" className="background" />

        <div className="additional-question">
          <div className="title">Имате въпрос, на който не намерихте отговор тук?</div>
          <div className="text">Свържете се с нас и ние ще Ви отговорим лично.</div>
          <Button theme="primary" href="/contacts" label="Контакти" id="faq-to-contacts" />
        </div>
      </div>
    </div>
  </Page>
);

export default FaqPage;
