import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSuccessField } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldIndicatorIcon from '../FieldIndicatorIcon/FieldIndicatorIcon';
import './StepInput.scss';

const FORBIDDEN_KEYS = [69, 173, 188, 189];
const MAX_NUMBER = 1000000000;

/**
 * Represents a restricted number input.
 */
const StepNumberInput = ({ label, tooltip, isMandatory = true, error, defaultValue, onChange, onBlur, ...props }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });

  const htmlId = useMemo(() => (
    uuid()
  ), []);

  const handleChange = (e) => {
    const userInput = e.target.value;
    const userValue = userInput.length > 0 ? parseInt(userInput) : '';

    if (userValue > MAX_NUMBER || userValue < 0 || Number.isNaN(userValue)) {
      return;
    }

    setValue(userValue);
    onChange(userValue);
  };

  const handleBlur = (e) => {
    onBlur();

    if (e.target.value && !error) {
      setIsSuccess(true);
    }
  };

  const handleKey = (e) => {
    if (FORBIDDEN_KEYS.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  return (
    <FieldWrapper
      className="step-input-wrapper"
      htmlId={htmlId}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
    >
      <div className="input">
        <input
          {...props}
          type="number"
          onBlur={handleBlur}
          id={htmlId}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKey}
          min="0"
          max={MAX_NUMBER}
        />

        <FieldIndicatorIcon isError={!!error} isSuccess={isSuccess} />
      </div>
    </FieldWrapper>

  );
};

export default StepNumberInput;
