import { takeEvery, put, call, select } from 'redux-saga/effects';
import * as api from 'api';

import {
  fetchUserData,
  login as loginAction,
  saveUserDataStep,
  sendForgottenPasswordRequest,
  setUserData,
  successfulLogin,
  updatePassword,
  updateUserAction
} from 'store/reducers/user';

import { startRequest, successRequest, failRequest } from 'store/reducers/requestMonitor';
import { setWrongCredentials } from 'store/reducers/wrongCredentials';
import { storeAccessToken } from 'lib/app';
import { handleRequestFailure } from './utils/errors';

const INVALID_LOGIN_ERROR = 'invalid_grant';

function* watchLogin() {
  yield takeEvery(loginAction.type, login);
}

function* login({ payload }) {
  try {
    yield put(startRequest('login'));
    const [result] = yield call(api.login, {
      grant_type: 'password',
      username: payload.username,
      password: payload.password,
      scope: 'webclient'
    });

    storeAccessToken(result.access_token);
    yield put(successRequest('login'));
    yield put(successfulLogin());

    yield put(fetchUserData());
  } catch (e) {
    yield put(failRequest('login'));

    e.result && e.result.error === INVALID_LOGIN_ERROR
      ? yield put(setWrongCredentials())
      : yield call(handleRequestFailure, e);
  }
}

function* watchForgottenPassword() {
  yield takeEvery(sendForgottenPasswordRequest.type, forgottenPassword);
}

function* forgottenPassword({ payload }) {
  try {
    yield put(startRequest('forgottenPassword'));
    yield call(api.sendForgottenPasswordRequest, payload.email);
    yield put(successRequest('forgottenPassword'));
  } catch (e) {
    yield put(failRequest('forgottenPassword'));
    yield call(handleRequestFailure, e);
  }
}

function* watchUpdatePassword() {
  yield takeEvery(updatePassword.type, update);
}

function* update({ payload }) {
  try {
    yield put(startRequest('updatePassword'));
    const [result] = yield call(api.updatePassword, payload);

    storeAccessToken(result.access_token);
    yield put(successRequest('updatePassword'));
    yield put(successfulLogin());
  } catch (e) {
    yield put(failRequest('updatePassword'));
    yield call(handleRequestFailure, e, { token: payload.token });
  }
}

function* watchFetchUserData() {
  yield takeEvery(fetchUserData.type, fetchClientData);
}

function* fetchClientData() {
  try {
    const [result] = yield call(api.fetchClientData);
    yield put(setUserData(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

function* watchSaveUserData() {
  yield takeEvery(saveUserDataStep.type, saveClientData);
}

function* saveClientData() {
  const errors = yield select((state) => state.userDraftErrorFields);

  if (Object.keys(errors).length > 0) {
    return;
  }

  const data = yield select((state) => state.userDraft);

  try {
    yield put(startRequest('updateClientData'));

    const [updatedData] = yield call(api.updateClientData, data);
    yield put(setUserData(updatedData));

    yield put(successRequest('updateClientData'));
  } catch (e) {
    yield put(failRequest('updateClientData'));
    yield call(handleRequestFailure, e);
  }
}

function* watchUpdateUser() {
  yield takeEvery(updateUserAction.type, updateUser);
}

function* updateUser({ payload }) {
  try {
    yield put(startRequest('updateUser'));

    const [updatedData] = yield call(api.updateClientData, payload);
    yield put(setUserData(updatedData));

    yield put(successRequest('updateUser'));
  } catch (e) {
    yield put(failRequest('updateUser'));
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchLogin,
  watchForgottenPassword,
  watchUpdatePassword,
  watchFetchUserData,
  watchSaveUserData,
  watchUpdateUser
];
