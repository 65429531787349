/* eslint-disable quotes */
/* eslint-disable quote-props */
export const MOBILE_WIDTH = 1260;
export const PASSWORD_MIN_LENGTH = 8;
export const VALID_PROVIDER_IDS_WITH_ICONS = [1, 2, 3];
export const PATHS = {
  "LOGIN": "/login",
  "REQUEST_CREATION_FLOW": "/create-request",
  "MY_REQUESTS": "/my-requests",
  "ERROR": "/error",
  "MY_PROFILE": "/profile",
  "MY_CREDITS": "/my-credits",
  "PRIVACY": "/privacy-policy",
  "TERMS": "/terms-and-conditions",
  "NEWS": "/news",
  "FAQ": "/faq",
  "CONTACTS": "/contacts",
  "ABOUT_US": "/about-us"
};
