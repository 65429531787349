import React from 'react';
import cn from 'classnames';
import { Notification } from 'svg';
import styles from './index.module.scss';

export default function NotificationIconUI({ data }) {
  return (
    <>
      <Notification />

      {data && data.totalUnseen > 0 && (
        <div className={cn(styles.number, { [styles.bigNumber]: data.totalUnseen >= 100 })}>
          {data.totalUnseen >= 100 ? '99+' : data.totalUnseen}
        </div>
      )}
    </>
  );
}
