import { takeEvery, call, put, select } from 'redux-saga/effects';
import { createClient, verifyClient, continueWithSocial, successfulLogin, fetchUserData } from 'store/reducers/user';
import { changeCreateClientUI, changeCreateClientEmailSentUI } from 'store/reducers/ui';
import { selectServiceTypeIdByCode } from 'store/reducers/serviceTypes';
import { selectLoanAttributes } from 'store/reducers/initialLoanDetails';
import { startRequest, successRequest, failRequest } from 'store/reducers/requestMonitor';
import * as api from 'api';
import { storeAccessToken } from 'lib/app';
import { handleRequestFailure } from './utils/errors';

function* watchCreateClient() {
  yield takeEvery(createClient.type, create);
}

function* create({ payload }) {
  try {
    const serviceTypeId = yield select((state) => selectServiceTypeIdByCode(state.serviceTypes, state.initialLoanDetails.type));

    const registerWithStepZero = yield select((state) => state.ui.registerWithStepZero);

    yield put(startRequest('createClient'));
    yield call(api.createClient, {
      email: payload.email,
      phone: payload.phone,
      ...(registerWithStepZero ? {
        requestStep0: {
          serviceTypeId,
          attributes: yield select((state) => selectLoanAttributes(state.initialLoanDetails))
        }
      } : {})
    });

    yield put(successRequest('createClient'));
    yield put(changeCreateClientUI(false));
    yield put(changeCreateClientEmailSentUI(payload.email));
  } catch (e) {
    yield put(failRequest('createClient'));
    yield call(handleRequestFailure, e, { email: payload.email });
  }
}

function* watchContinueWithSocial() {
  yield takeEvery(continueWithSocial.type, continueSocial);
}

function* continueSocial({ payload }) {
  try {
    const serviceTypeId = yield select((state) => selectServiceTypeIdByCode(state.serviceTypes, state.initialLoanDetails.type));

    const registerWithStepZero = yield select((state) => state.ui.registerWithStepZero);

    yield put(startRequest('continueWithSocial'));
    const [result] = yield call(api.loginSocialClient, {
      idToken: payload.idToken,
      socialType: payload.socialType,
      ...(registerWithStepZero ? {
        requestStep0: {
          serviceTypeId,
          attributes: yield select((state) => selectLoanAttributes(state.initialLoanDetails))
        }
      } : {})
    });

    storeAccessToken(result.accessToken.access_token);
    yield put(successRequest('continueWithSocial'));

    if (payload.isLogin) {
      yield put(successfulLogin());
    }

    yield put(fetchUserData());
  } catch (e) {
    yield put(failRequest('continueWithSocial'));
    yield call(handleRequestFailure, e);
  }
}

function* watchVerifyClient() {
  yield takeEvery(verifyClient.type, verify);
}

function* verify({ payload }) {
  try {
    yield put(startRequest('verifyClient'));

    const [result] = yield call(api.verifyClient, {
      password: payload.password,
      token: payload.token
    });

    storeAccessToken(result.access_token);
    yield put(successRequest('verifyClient'));

    yield put(fetchUserData());
  } catch (e) {
    yield put(failRequest('verifyClient'));
    yield call(handleRequestFailure, e, { token: payload.token });
  }
}

export default [
  watchCreateClient,
  watchContinueWithSocial,
  watchVerifyClient
];
