import { useQuery } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

/**
 * Retrieves the about us info
 */
const useAboutUsData = () => {
  const { isLoading, error, data } = useQuery(
    'about-us',
    () => (
      api.fetchAboutUs()
    )
  );

  useHTTPError(error);

  return {
    isLoading,
    result: data && data[0]
  };
};

export default useAboutUsData;
