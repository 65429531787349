/**
 * Calls the given callback with grecaptcha functionality.
 */
const withGrecaptcha = (callback) => {
  window.grecaptcha.ready(() => {
    window.grecaptcha.execute('6LcnUB8eAAAAAGI6U2gnR0TfDyLBjPylhcHrvBYb', { action: 'submit' }).then((token) => {
      window.grecaptcha_token = token;
      callback();
    });
  });
};

export default withGrecaptcha;
