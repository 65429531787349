import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useKeyboardSelection, useSuccessField } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldIndicatorIcon from '../FieldIndicatorIcon/FieldIndicatorIcon';
import arrowIcon from 'assets/img/dropdown-arrow.svg';
import './SearchField.scss';

/**
 * Represents a Search field with a dropdown options.
 *
 * @param {String} label: text label
 * @param {Boolean} isMandatory: indicates if the value selection is mandatory
 * @param {Array} options: list of option objects displayed when the component is open
 * @param {String} idName: name of the object field representing an uniq identifier for each option (e.g. "id", "code", ...)
 * @param {Function} getOptionLabel: retrieves the option label displayed in the dropdown by the given option object
 * @param {Function} search: called with the input value to initiate a search
 * @param {Object} defaultValue: initial selected option
 * @param {String} error: error message
 * @param {Function} onChange: called when a new value is selected
 * @param {Function} onBlur: called on blur to check the field state (error or success)
 * @param {Function} onInputFocus: optional focus callback
 * @param {String} tooltip: help tooltip displayed next to the component
 */
const SearchField = ({ label, isMandatory = true, options, idName, getOptionLabel, search, defaultValue, error, onChange, onBlur, onInputFocus, tooltip }) => {
  const fieldRef = useRef();
  const valueRef = useRef();
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const onFocus = () => {
    setIsOpen(true);
    onInputFocus && onInputFocus();
  };

  const handleBlur = () => {
    setIsOpen(false);

    if (valueRef.current.value.length > 0) {
      valueRef.current.value = getOptionLabel(selectedOption);
    } else {
      setSelectedOption(null);
      onChange();
    }

    setTimeout(() => {
      setIsSuccess(valueRef.current.value.length > 0);
      onBlur();
    }, 1);
  };

  const onOptionClick = (option) => {
    valueRef.current.value = getOptionLabel(option);
    setSelectedOption(option);
    onChange(option);
    setTimeout(() => {
      fieldRef.current.blur();
    }, 1);
  };

  const keyboardOptionIndex = useKeyboardSelection({
    options,
    selectedOption,
    isOpen,
    onSelectOption: onOptionClick,
    ref: [valueRef, fieldRef]
  });

  return (
    <FieldWrapper
      className={cn('search-field-wrapper', { open: isOpen && options.length > 0 })}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
      onFocus={onFocus}
      onBlur={handleBlur}
      role="button"
      tabIndex="-1"
      ref={fieldRef}
    >
      <div className="input">
        <input
          type="text"
          ref={valueRef}
          defaultValue={getOptionLabel(selectedOption)}
          className="value"
          onChange={(e) => { search(e.target.value); }}
        />

        {!error && !isSuccess && (
          <img src={arrowIcon} className="arrow-icon" alt="" />
        )}

        <FieldIndicatorIcon isError={!!error} isSuccess={isSuccess} />
      </div>

      {options.length > 0 && (
        <ul className="option-list">
          {options.map((option, index) => (
            <li
              key={option[idName]}
              onClick={() => { onOptionClick(option); }}
              className={cn({ selected: selectedOption && selectedOption[idName] === option[idName], 'keyboard-hovered': keyboardOptionIndex === index })}
            >
              {getOptionLabel(option)}
            </li>
          ))}
        </ul>
      )}
    </FieldWrapper>
  );
};

export default SearchField;
