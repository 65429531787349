import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import './ConfirmPhonePopup.scss';

const ConfirmPhonePopup = ({ onConfirm, close, phone }) => {
  usePreventGlobalScroll();

  return (
    <Popup onClose={close} title="Потвърждение" className="confirm-phone-popup">
      <div className="details">
        <p>Сигурни ли сте, че това е вашият телефонен номер?</p>
        <p>
          <strong>{phone}</strong>
        </p>
      </div>
      <div className="buttons">
        <Button label="Да" theme="primary" onClick={onConfirm} id="confirm-phone-yes" />
        <Button label="Не" onClick={close} id="confirm-phone-no" />
      </div>
    </Popup>
  );
};

export default ConfirmPhonePopup;
