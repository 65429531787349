import React, { useState, useEffect } from 'react';
import InputBoundSlider from './InputBoundSlider';

/**
 * Represents the slider indicating the loan period.
 *
 * @param {Number}   value
 * @param {Function} onChange
 * @param {Object}   rangeConfig: specifies the time range (min, max values) of the loan. Contains minValue, maxValue, minLabel, maxLabel
 */
const YearSlider = ({ value, onChange, rangeConfig }) => {
  const [postInputLabel, setPostInputLabel] = useState('години');

  useEffect(() => {
    setPostInputLabel(value === 1 ? 'година' : 'години');
  }, [value]);

  return (
    <InputBoundSlider
      className="year-slider"
      value={value}
      preInputLabel="Период"
      postInputLabel={postInputLabel}
      minValue={rangeConfig.minValue}
      maxValue={rangeConfig.maxValue}
      minLabel={rangeConfig.minLabel}
      maxLabel={rangeConfig.maxLabel}
      onChange={onChange}
    />
  );
};

export default YearSlider;
