import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import AppPortal from 'components/AppPortal';
import Icon from 'components/Icon/Icon';
import closeIcon from 'assets/img/close.svg';
import './Popup.scss';

const Popup = ({ children, title, onClose, className = '' }) => {
  usePreventGlobalScroll();

  return (
    <AppPortal>
      <div className="popup-overlay">
        <div className={`popup ${className}`}>
          <Icon icon={closeIcon} className="close" onClick={onClose} />
          <h2>{title}</h2>

          {children}
        </div>
      </div>
    </AppPortal>
  );
};

export default Popup;
