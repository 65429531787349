import { takeEvery, call, put, select } from 'redux-saga/effects';
import { fetchStepDefinitions, getStepDefinitions, addStepDefinitions } from 'store/reducers/stepDefinitions';
import { selectServiceTypeIdByCode, setServiceTypes } from 'store/reducers/serviceTypes';
import * as api from 'api';
import { waitForState } from './utils';
import { handleRequestFailure } from './utils/errors';

function* watchFetch() {
  yield takeEvery(fetchStepDefinitions.type, fetch);
}

function* fetch({ payload }) {
  yield waitForState({
    actionType: setServiceTypes.type,
    selector: (state) => state.serviceTypes,
    waitUntil: (serviceTypes) => serviceTypes.length > 0
  });

  const serviceTypeId = yield select((state) => selectServiceTypeIdByCode(state.serviceTypes, payload));

  try {
    const [result] = yield call(api.fetchStepDefinitions, serviceTypeId);
    yield put(addStepDefinitions({
      data: result,
      serviceType: payload
    }));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

function* watchGet() {
  yield takeEvery(getStepDefinitions.type, checkFetch);
}

function* checkFetch({ payload }) {
  const stepDefinition = yield select((state) => state.stepDefinitions[payload]);
  if (stepDefinition) {
    return;
  }

  yield put(fetchStepDefinitions(payload));
}

export default [
  watchFetch,
  watchGet
];
