import ReactDOM from 'react-dom';

/**
 * The primary Portal in the application used to create popups and overlays.
 * https://reactjs.org/docs/portals.html
 *
 * @param {React.Component} children
 */
const AppPortal = ({ children }) => (
  ReactDOM.createPortal(
    children,
    document.querySelector('#root')
  )
);

export default AppPortal;
