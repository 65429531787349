import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import img from 'assets/img/faq.svg';
import FaqAccordion from 'components/FaqAccordion/FaqAccordion';
import { PATHS } from 'config';
import Button from 'components/Button/Button';
import './Faq.scss';

const MAX_ITEMS = 5;

const Faq = () => (
  <div className="faq-section">
    <SectionTitle label="Често Задавани Въпроси" />

    <div className="faq-section-wrapper">
      <img src={img} alt="" className="background" />

      <FaqAccordion maxItems={MAX_ITEMS} />
    </div>

    <Button label="Научи всички отговори" href={PATHS.FAQ} id="learn-all-answers" />
  </div>
);

export default Faq;
