import React, { useEffect, useMemo } from 'react';
import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import { useStepZeroData } from 'hooks';
import { fetchProviderData } from 'store/reducers/providers';
import { fetchOfferFile, clearOfferFile } from 'store/reducers/offerFile';
import { acceptOffer } from 'store/reducers/offer';
import { clearRequest } from 'store/reducers/requestMonitor';
import { getOfferRejectFeedbackDefinition } from 'store/reducers/offerRejectFeedbackDefinition';
import { getBankData } from 'store/reducers/bankData';
import { formatDate } from 'lib/app';
import { downloadFile } from 'lib/downloadFile';
import Popup from 'components/Popup/Popup';
import { getStepZeroSummary } from 'components/RequestSummary/getStepZeroSummary';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import acceptIcon from 'assets/img/accept.svg';
import rejectIcon from 'assets/img/reject.svg';
import pdfIcon from 'assets/img/pdf.svg';
import './OfferPopup.scss';

/**
 * Represents a popup containing offer details.
 *
 * @param {Function} init: fetches provider data and initializes the state
 * @param {Function} cleanUp: clears file state
 * @param {Object} offer: offer data to display
 * @param {String} providerName: provider name for this offer
 * @param {Function} fetchFile: fetches the offer file details
 * @param {Object} file: represents the downloadable offer file
 * @param {Array} bankData
 * @param {Function} accept: accepts the offer on the server
 * @param {Boolean} areButtonsDisabled: indicates if the file fetch or another request is in progress so the buttons should be disabled
 * @param {Boolean} isOfferAccepted: when true, the offer is accepted on the server, so the popup can close
 * @param {Function} close: called to close the popup
 * @param {Function} onRejectClick: called when the user clicks on the reject button
 */
const OfferPopup = ({ init, cleanUp, offer, providerName, fetchFile, file, bankData, accept, areButtonsDisabled, isOfferAccepted, close, onRejectClick }) => {
  useEffect(() => {
    init(offer.providerId);

    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (!file) {
      return;
    }

    downloadFile({
      blob: file.blob,
      fileName: file.fileName
    });
  }, [file]);

  useEffect(() => {
    isOfferAccepted && close();
  }, [isOfferAccepted]);

  const bankName = useMemo(() => {
    if (offer.bankCode) {
      const bankItem = bankData.find((item) => item.code === offer.bankCode);
      return bankItem ? bankItem.name : offer.bankCode;
    }
  }, [bankData, offer]);

  const stepZeroData = useStepZeroData(offer.request);

  const onFileClick = () => {
    fetchFile(offer.fileId);
  };

  const onAdditionalFileClick = (fileId) => {
    fetchFile(fileId);
  };

  return (
    <Popup
      onClose={close}
      title={`Оферта по заявка № ${offer.request.id} от ${formatDate(offer.request.createdTs)} г.`}
      className="offer-popup"
    >
      <div className="header">
        <img src={`/img/providers/large/${offer.providerId}.svg`} alt="" />
        <div className="provider-name">{providerName}</div>
      </div>

      <div className="main">
        {bankName && (
          <>
            <div className="title">Банка:</div>
            <div className="details">
              {bankName}
            </div>
          </>
        )}

        <div className="title">Заявлание:</div>
        <div className="details">
          {getStepZeroSummary(stepZeroData)}
        </div>

        <div className="title">Предложение на банката:</div>
        <div className="details">
          <div data-richtext dangerouslySetInnerHTML={{ __html: Base64.decode(offer.description) }} />
        </div>

        <div className="footer">
          <p>Офертата е валидна до: <strong>{formatDate(offer.expirationDate)}</strong></p>
          <p>Статус: <strong>{offer.status.displayName}</strong></p>
          <p>
            Свали офертата:
            <Icon icon={pdfIcon} onClick={onFileClick} disabled={areButtonsDisabled} />
          </p>

          {offer.secondaryFileIds.length > 0 && (
            <div className="additional-files-line">
              <p>Допълнителни файлове:</p>
              <ul className="additional-files">
                {offer.secondaryFileIds.map((fileId) => (
                  <li key={fileId}>
                    <Icon icon={pdfIcon} onClick={() => { onAdditionalFileClick(fileId); }} disabled={areButtonsDisabled} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {offer.status.name === 'SENT' && (
        <div className="buttons">
          <Button
            theme="primary"
            label={(
              <>
                <img className="button-icon" src={acceptIcon} alt="" />
                Приемам
              </>
            )}
            onClick={accept}
            disabled={areButtonsDisabled}
            id="accept-offer"
          />
          <Button
            theme="red"
            label={(
              <>
                <img className="button-icon" src={rejectIcon} alt="" />
                Отказвам
              </>
            )}
            onClick={() => { onRejectClick(offer); }}
            disabled={areButtonsDisabled}
            id="reject-offer"
          />
        </div>
      )}
    </Popup>
  );
};

export default connect(
  (state, ownProps) => ({
    providerName: state.providers[ownProps.offer.providerId],
    file: state.offerFile,
    bankData: state.bankData.data,
    areButtonsDisabled: state.requestMonitor.fetchOfferFile === 'pending' || state.requestMonitor.acceptOffer === 'pending',
    isOfferAccepted: state.requestMonitor.acceptOffer === 'success'
  }),
  (dispatch, ownProps) => ({
    init: (id) => {
      dispatch(fetchProviderData(id));
      dispatch(getOfferRejectFeedbackDefinition());
      dispatch(getBankData());
    },
    fetchFile: (fileId) => {
      dispatch(fetchOfferFile(fileId));
    },
    accept: () => {
      dispatch(acceptOffer(ownProps.offer.id));
    },
    cleanUp: () => {
      dispatch(clearOfferFile());
      dispatch(clearRequest('acceptOffer'));
    }
  })
)(OfferPopup);
