import { useState } from 'react';

/**
 * A custom hook used to track error states of input fields.
 *
 * @return {Object} { errors, addError, hasErrors, clearErrors }:
 *                   - errors: object, keys represent input fields, values represent related messages
 *                   - addError: a function to add a new error
 *                   - hasErrors: an async function to check whether there is at least one error
 *                   - clearErrors: clears errors
 */
const useFieldErrors = () => {
  const [errors, setErrors] = useState({});

  const addError = (fieldMessage) => {
    setErrors((state) => ({
      ...state,
      ...fieldMessage
    }));
  };

  const hasErrors = () => (
    new Promise((resolve) => {
      setErrors((state) => {
        resolve(Object.keys(state).length > 0);
        return state;
      });
    })
  );

  const clearFieldError = (field) => {
    setErrors((state) => {
      const nextState = { ...state };
      delete nextState[field];
      return nextState;
    });
  };

  const clearErrors = () => {
    setErrors({});
  };

  return { errors, addError, hasErrors, clearFieldError, clearErrors };
};

export default useFieldErrors;
