import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { datadogRum } from '@datadog/browser-rum';
import 'object-assign-polyfill';
import 'array.prototype.fill';
import 'number.isnan';
import store from 'store';
import App from './App';

if (window.location.origin === 'https://kredy.bg' || window.location.origin === 'https://www.kredy.bg') {
  datadogRum.init({
    applicationId: '87125c8f-f66f-48c1-a18e-4c5cb010fd1e',
    clientToken: 'pub372e78c17d8754646cbb26ac81e654e0',
    site: 'datadoghq.com',
    service: 'kredy-client-app',
    env: (window.location.origin === 'https://kredy.bg' || window.location.origin === 'https://www.kredy.bg') ? 'prod' : 'test',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 80,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}

datadogRum.startSessionReplayRecording();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
