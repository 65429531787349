import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const StyledSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&:hover': {
      background: 'transparent',
    },
    '&$checked': {
      color: 'white',
      '&:hover': {
        background: 'transparent'
      }
    },
  },
  track: {
    border: '1px solid #16B14B',
    background: 'linear-gradient(to right, white, white)',
    height: '12px'
  },
  checked: {
    '& + $track': {
      background: 'linear-gradient(to right, white, white)',
    }
  },
  thumb: {
    background: 'linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%)',
  }
})(Switch);

const Toggle = ({ isChecked, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <StyledSwitch checked={isChecked} onChange={handleChange} />
  );
};

export default Toggle;
