import { createSlice, createAction } from '@reduxjs/toolkit';

export const checkUserDraftValuesForError = createAction('userDraftErrorFields/checkUserDraftValuesForError');

/**
 * Stores error field data related to the Personal Data step as part of the Request Creation Flow.
 */
const slice = createSlice({
  name: 'userDraftErrorFields',
  initialState: {},
  reducers: {
    setUserDraftErrorFields: (state, { payload }) => (
      payload.forEach((item) => {
        state[item.name] = item.error;
      })
    ),
    addUserDraftErrorField: (state, { payload }) => {
      state[payload.name] = payload.error;
    },
    removeUserDraftErrorField: (state, { payload }) => {
      delete state[payload];
    },
    clearUserDraftErrorFields: () => (
      {}
    )
  }
});

/**
 * Checks whether the user data step can be saved (there are no errors).
 *
 * @param {Object} state: this reducer's state
 */
export const selectCanSaveUserDataStep = (state) => (
  Object.keys(state).length === 0
);

export const { setUserDraftErrorFields, addUserDraftErrorField, removeUserDraftErrorField, clearUserDraftErrorFields } = slice.actions;
export default slice.reducer;
