import { useQuery } from 'react-query';
import * as api from 'api';
import useHTTPError from 'hooks/useHTTPError';

/**
 * Uses the list of news.
 */
const useNewsList = () => {
  const { isLoading, error, data } = useQuery(
    'news-list',
    () => (
      api.fetchNewsList()
    ), {
      staleTime: 5 * 60 * 1000
    }
  );

  useHTTPError(error);

  return {
    isLoading,
    result: data && data[0]
  };
};

export default useNewsList;
