import React from 'react';
import Stars from 'components/Stars/Stars';
import quoteImg from 'assets/img/quote.svg';

/**
 * Represents a single feedback item (slide).
 *
 * @param {SVG} profileImg
 * @param {String} text
 * @param {String} name
 * @param {String} city
 */
const FeedbackItem = ({ profileImg, text, name, city }) => (
  <div className="feedback-item">
    {profileImg && (
      <img src={profileImg} alt="" className="profile-pic" />
    )}

    <div className="content">
      <img src={quoteImg} alt="" className="quote-background" />

      <div className="text">
        {text}
      </div>

      <div className="details">
        <span className="name">{name}</span>, {city}
      </div>

      <Stars count={5} />
    </div>
  </div>
);

export default FeedbackItem;
