import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { useIsMobile } from 'hooks';
import { VALID_PROVIDER_IDS_WITH_ICONS, PATHS } from 'config';
import { formatDate } from 'lib/app';
import { LOAN_TYPES, LOAN_ATTRIBUTE_NAMES } from 'const';
import Tabs from './Tabs';
import SummaryPopup from 'components/SummaryPopup/SummaryPopup';
import offerAcceptedIcon from 'assets/img/offer-accepted.svg';
import offerRejectedIcon from 'assets/img/offer-rejected.svg';
import OfferPopup from './OfferPopup';
import RejectFeedbackPopup from './RejectFeedbackPopup';
import EmptyMessage from 'components/EmptyMessage';

const LOAN_LABEL = {
  [LOAN_TYPES.PERSONAL]: 'Потребителски',
  [LOAN_TYPES.MORTGAGE]: 'Ипотечен'
};

const CELL_LABELS = {
  date: 'Дата',
  request: 'Заявка',
  type: 'Вид кредит',
  amount: 'Сума',
  period: 'Период',
  status: 'Статус',
  offers: 'Оферти'
};

/**
 * Represents a list of user requests.
 *
 * @param {Array} list: request list
 */
const RequestList = ({ list }) => {
  const history = useHistory();
  const location = useLocation();
  const { offer: popupOfferId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState('active');
  const [summaryData, setSummaryData] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [rejectFeedbackData, setRejectFeedbackData] = useState(null);

  const selectedList = useMemo(() => (
    list.filter((item) => (
      item.finalState === (activeTab !== 'active')
    ))
  ), [activeTab, list]);

  const hasHistory = useMemo(() => (
    list.some((item) => (
      item.finalState
    ))
  ), [list]);

  const openSummary = (request) => {
    setSummaryData(request);
  };

  /**
   * Opens and closes the offer popup when URL changes (?offer=[id]).
   */
  useEffect(() => {
    if (!popupOfferId) {
      setOfferData(null);
    } else {
      let offer = null;

      const request = list.find((req) => {
        offer = req.offers.find((off) => (
          off.id === parseInt(popupOfferId)
        ));

        return offer;
      });

      if (request && offer) {
        setOfferData({
          ...offer,
          request
        });
      }
    }

    return () => {
      setOfferData(null);
    };
  }, [popupOfferId, list]);

  const onOfferClick = (offer) => {
    history.push(`?offer=${offer.id}`);
  };

  const onOfferClose = () => {
    history.push('?');
  };

  return (
    <div className="request-list">
      {hasHistory && (
        <Tabs activeTab={activeTab} onTabChange={setActiveTab} />
      )}

      {selectedList.length === 0 ? (
        <EmptyMessage>Все още нямате заявки</EmptyMessage>
      ) : (
        isMobile
          ? (
            <div className="mobile-list">
              {selectedList.map((request) => (
                <div className="request-box" key={request.id}>
                  <table border="0" cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr className="heading-cells">
                        <td>{CELL_LABELS.date}</td>
                        <td>{CELL_LABELS.request}</td>
                        <td>{CELL_LABELS.type}</td>
                      </tr>
                      <tr>
                        {renderFirstThreeCells(request, openSummary)}
                      </tr>
                      <tr className="heading-cells">
                        <td>{CELL_LABELS.amount}</td>
                        <td>{CELL_LABELS.period}</td>
                        <td>{CELL_LABELS.status}</td>
                      </tr>
                      <tr>
                        {renderSecondThreeCells(request)}
                      </tr>
                    </tbody>
                  </table>

                  <div className="offer-text">{CELL_LABELS.offers}</div>
                  <ul className="offer-list">
                    {request.offers.map((offer) => (
                      <Fragment key={offer.id}>
                        {renderProviderIcon(offer, () => {
                          onOfferClick(offer);
                        })}
                      </Fragment>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <table className="list" border="0" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th>{CELL_LABELS.date}</th>
                  <th>{CELL_LABELS.request}</th>
                  <th>{CELL_LABELS.type}</th>
                  <th>{CELL_LABELS.amount}</th>
                  <th>{CELL_LABELS.period}</th>
                  <th>{CELL_LABELS.status}</th>
                  <th>{CELL_LABELS.offers}</th>
                </tr>
              </thead>
              <tbody>
                {selectedList.map((request) => (
                  <tr key={request.id}>
                    {renderFirstThreeCells(request, openSummary)}
                    {renderSecondThreeCells(request)}
                    <td>
                      <ul className="offer-list">
                        {request.offers.map((offer) => (
                          <Fragment key={offer.id}>
                            {renderProviderIcon(offer, () => {
                              onOfferClick(offer);
                            })}
                          </Fragment>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
      )}

      {summaryData && (
        <SummaryPopup
          request={summaryData}
          close={() => { setSummaryData(null); }}
        />
      )}

      {offerData && (
        <OfferPopup
          offer={offerData}
          close={onOfferClose}
          onRejectClick={(offer) => {
            setOfferData(null);
            setRejectFeedbackData(offer);
          }}
        />
      )}

      {rejectFeedbackData && (
        <RejectFeedbackPopup
          offer={rejectFeedbackData}
          close={() => { setRejectFeedbackData(null); }}
        />
      )}
    </div>
  );
};

const renderFirstThreeCells = (request, openSummary) => (
  <>
    <td>{request.createdTs ? formatDate(request.createdTs) : ''}</td>
    <td>
      <button type="button" className="link" onClick={() => { openSummary(request); }}>{request.id}</button>
    </td>
    <td>{LOAN_LABEL[request.serviceTypeCode]}</td>
  </>
);

const renderSecondThreeCells = (request) => (
  <>
    <td>{formatAmount(request)}</td>
    <td>{formatPeriod(request)}</td>
    <td>
      {request.requestStatus.name === 'INIT'
        ? <Link className="link" to={`${PATHS.REQUEST_CREATION_FLOW}?id=${request.id}`}>{request.requestStatus.displayName}</Link>
        : request.requestStatus.displayName}
    </td>
  </>
);

const renderProviderIcon = (offer, onClick) => (
  <li onClick={onClick}>
    <div className="provider">
      {VALID_PROVIDER_IDS_WITH_ICONS.includes(offer.providerId)
        ? <img src={`/img/providers/small/${offer.providerId}.svg`} alt="" />
        : <div className="no-provider">?</div>}

      {['ACCEPTED', 'SIGNED', 'NOT_SIGNED'].includes(offer.status.name) && (
        <img src={offerAcceptedIcon} className="status-icon" alt="" />
      )}

      {['OFFER_EXPIRED', 'REJECTED'].includes(offer.status.name) && (
        <img src={offerRejectedIcon} className="status-icon" alt="" />
      )}
    </div>
  </li>
);

const formatAmount = (request) => {
  const amount = request.attributes.find((attr) => (
    attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].amount
  )).value;

  const currency = request.attributes.find((attr) => (
    attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].currency
  )).value;

  if (currency === 'EUR') {
    return `€ ${formatNumber(amount)}`;
  }

  if (currency === 'BGN') {
    return `${formatNumber(amount)} лв.`;
  }

  throw new Error(`Unknown currency ${currency}`);
};

const formatNumber = (number) => (
  number.toLocaleString('en').replace(/,/g, ' ')
);

const formatPeriod = (request) => {
  const period = request.attributes.find((attr) => (
    attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].period
  )).value;

  return `${period} г.`;
};

export default RequestList;
