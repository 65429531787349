import React from 'react';
import checkIcon from 'assets/img/check-green.svg';
import './CookieBanner.scss';

export default function CookieBanner({ onAgree }) {
  return (
    <div id="cookie-banner">
      <div className="section">
        <div className="title">
          Този уебсайт използва бисквитки
        </div>
        <div className="details">
          <p>
            Бисквитките ни помагат да ви предоставим най-доброто онлайн изживяване.
          </p>
          <p>
            Моля отбележете дали сте съгласни с използването на бисквитки.
          </p>
        </div>
      </div>

      <div className="section right">
        <button type="button" className="agree" onClick={onAgree}>
          <img src={checkIcon} alt="" />
          Приемам
        </button>
      </div>
    </div>
  );
}
