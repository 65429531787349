import request from './request';

const REQUEST_MANAGEMENT = window.KREDY_ENVIRONMENT.requestManagementAPI;
const USER_MANAGEMENT = window.KREDY_ENVIRONMENT.userManagementAPI;
const SERVICE_TYPE_MANAGEMENT = window.KREDY_ENVIRONMENT.serviceTypeManagementAPI;
const SERVICE_PROVIDER_MANAGEMENT = window.KREDY_ENVIRONMENT.serviceProviderManagementAPI;
const NOTIFICATION_MANAGEMENT = window.KREDY_ENVIRONMENT.notificationManagementAPI;
const CLIENT_MANAGEMENT = window.KREDY_ENVIRONMENT.clientManagementAPI;
const CMS_API = window.KREDY_ENVIRONMENT.cmsAPI;

/**
 * Login into the application using oauth.
 *
 * @param  {Object}  data
 * @return {Promise}
 */
export const login = async (data) => {
  const formdata = new FormData();

  Object.keys(data).forEach((key) => {
    formdata.append(key, data[key]);
  });

  return request({
    endpoint: `${USER_MANAGEMENT}/oauth/token`,
    method: 'POST',
    data: formdata,
    isFormData: true,
    headers: {
      Authorization: 'Basic YWRtaW51aV90ZXN0Og=='
    }
  });
};

/**
 * Gets all service types.
 *
 * @return {Promise}
 */
export const getServiceTypes = async () => (
  request({
    endpoint: `${SERVICE_TYPE_MANAGEMENT}/servicetype-public/`
  })
);

/**
 * Creates a client with email, phone, and predefined credit requirements.
 *
 * @param  {Object}  data
 * @return {Promise}
 */
export const createClient = async (data) => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/client`,
    method: 'POST',
    data
  })
);

/**
 * Creates or logs-in a client with social idToken, and possible with predefined credit requirements.
 *
 * @param  {Object}  data
 * @return {Promise}
 */
export const loginSocialClient = async (data) => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/client/social/login`,
    method: 'POST',
    data
  })
);

/**
 * Returns user data.
 *
 * @return {Promise}
 */
export const fetchClientData = async () => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/client`
  })
);

/**
 * Updates user data.
 *
 * @param data: new user data { phone, firstName, lastName, egn }
 * @return {Promise}
 */
export const updateClientData = async (data) => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/client`,
    method: 'PUT',
    data
  })
);

/**
 * Verifies the client by creating a password.
 *
 * @param  {Object}  data: contains password and token
 * @return {Promise}
 */
export const verifyClient = async (data) => (
  request({
    endpoint: `${USER_MANAGEMENT}/verify/client`,
    method: 'POST',
    data
  })
);

/**
 * Generates a forgotten password link for the given email.
 *
 * @param {String} email
 */
export const sendForgottenPasswordRequest = async (email) => (
  request({
    endpoint: `${USER_MANAGEMENT}/generate/forgotten/password/link`,
    method: 'POST',
    data: email
  })
);

/**
 * Updates an user password.
 *
 * @param {Object} data: contains newPassword, repeatedPassword, token
 */
export const updatePassword = async (data) => (
  request({
    endpoint: `${USER_MANAGEMENT}/update/forgotten/password`,
    method: 'POST',
    data
  })
);

/**
 * Returns request data by the given requestId.
 *
 * @param  {String}  requestId
 * @return {Promise}
 */
export const fetchRequestData = async (requestId) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request/${requestId}`,
  })
);

/**
 * Returns request data for the current user.
 *
 * @return {Promise}
 */
export const fetchRequestInProgress = async () => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request/in-progress`
  })
);

/**
 * Saves the given data for the given request id.
 *
 * @param {Number} id: request id
 * @param {Object} data: data to save
 * @return {Promise}
 */
export const saveRequestData = async ({ requestId, data }) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request/${requestId}`,
    method: 'PUT',
    data
  })
);

/**
 * Creates a new request on the server for the current user.
 *
 * @param {Object} data: data to save
 * @return {Promise}
 */
export const createRequest = async ({ data }) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request`,
    method: 'POST',
    data
  })
);

/**
 * Submits the final request to the server.
 *
 * @param {Number} id: request id
 * @return {Promise}
 */
export const submitRequest = async (id) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request/${id}/submit`,
    method: 'POST',
  })
);

/**
 * Returns step definitions (input fields per credit type).
 *
 * @param  {String}  serviceTypeId
 * @return {Promise}
 */
export const fetchStepDefinitions = async (serviceTypeId) => (
  request({
    endpoint: `${SERVICE_TYPE_MANAGEMENT}/step/${serviceTypeId}`
  })
);

/**
 * Returns location results based on the given search criteria
 *
 * @param  {String}  location: search criteria
 * @return {Promise}
 */
export const searchLocations = async (location) => (
  request({
    endpoint: `${SERVICE_TYPE_MANAGEMENT}/location`,
    searchParams: { location }
  })
);

/**
 * Returns location data based on the provided location code.
 *
 * @param  {Number}  locationCode
 * @return {Promise}
 */
export const fetchLocationByCode = async (locationCode) => (
  request({
    endpoint: `${SERVICE_TYPE_MANAGEMENT}/location/${locationCode}`
  })
);

/**
 * Returns bank data (bank names, codes).
 *
 * @return {Promise}
 */
export const fetchBankData = async () => (
  request({
    endpoint: `${SERVICE_TYPE_MANAGEMENT}/bank-data`
  })
);

/**
 * Returns the user requests.
 *
 * @return {Promise}
 */
export const fetchUserRequests = async () => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/request`
  })
);

/**
 * Returns provider data.
 *
 * @param {Number} id: provider id
 */
export const fetchProviderData = async (id) => (
  request({
    endpoint: `${SERVICE_PROVIDER_MANAGEMENT}/provider-client/${id}`
  })
);

/**
 * Returns a byte representation of a file related to an offer.
 *
 * @param  {Number}  fileId
 * @return {Promise}
 */
export const fetchOfferFile = async (fileId) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/offer/download/${fileId}`,
    isBlob: true
  })
);

/**
 * Accepts the given offer.
 *
 * @param {Number} offerId
 * @return {Promise}
 */
export const acceptOffer = async (offerId) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/offer/accept?offerId=${offerId}`,
    method: 'POST'
  })
);

/**
 * Returns feedback data for offer rejection.
 *
 * @return {Promise}
 */
export const fetchOfferRejectFeedbackDefinition = async () => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/feedback/client/reject`
  })
);

/**
 * Rejects the given offer and saves a reason.
 *
 * @param {Object} payload: contains { offerId, reason, feedbackNote }
 * @return {Promise}
 */
export const rejectOffer = async (payload) => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/offer/reject`,
    method: 'POST',
    searchParams: payload
  })
);

/**
 * Returns all notifications.
 *
 * @return {Promise}
 */
export const fetchNotifications = async () => (
  request({
    endpoint: `${NOTIFICATION_MANAGEMENT}/notifications`,
    searchParams: {
      size: 9999999,
    }
  })
);

/**
 * Marks notifications as seen.
 */
export const markNotificationsAsSeen = async (data) => (
  request({
    endpoint: `${NOTIFICATION_MANAGEMENT}/notifications/seen`,
    method: 'POST',
    data
  })
);

/**
 * Fetches user notification preferences.
 */
export const fetchNotificationPreference = async () => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/notification/preference`
  })
);

/**
 * Changes user notification preferences.
 */
export const changeNotificationPreference = async (data) => (
  request({
    endpoint: `${CLIENT_MANAGEMENT}/notification/preference`,
    method: 'POST',
    data
  })
);

/**
 * Changes the user password.
 *
 * @param {Object} data: old and new password
 */
export const changePassword = async (data) => (
  request({
    endpoint: `${USER_MANAGEMENT}/change/password`,
    method: 'POST',
    data
  })
);

/**
 * Fetches client contracts (credits).
 */
export const fetchClientContracts = async () => (
  request({
    endpoint: `${REQUEST_MANAGEMENT}/client-contracts`,
    searchParams: {
      size: 9999999,
    }
  })
);

/**
 * Let's get some static pages...
 */
export const fetchStaticPages = async () => (
  request({
    endpoint: `${CMS_API}/statics`,
    skipAuthentication: true
  })
);

/**
 * Fetches the news
 */
export const fetchNewsList = async () => (
  request({
    endpoint: `${CMS_API}/client-news`,
    skipAuthentication: true
  })
);

/**
 * Fetches a single news
 */
export const fetchSingleNews = async (canonical) => (
  request({
    endpoint: `${CMS_API}/client-news?canonical=${canonical}`,
    skipAuthentication: true
  })
);

/**
 * Fetches client reviews
 */
export const fetchClientReviews = async () => (
  request({
    endpoint: `${CMS_API}/client-reviews`,
    skipAuthentication: true
  })
);

export const fetchFaq = async () => (
  request({
    endpoint: `${CMS_API}/client-faqs?_sort=order`,
    skipAuthentication: true
  })
);

/**
 * Sends a contact us message.
 */
export const sendContactUsMessage = async (data) => (
  request({
    endpoint: `${NOTIFICATION_MANAGEMENT}/contact-us/`,
    method: 'POST',
    data
  })
);

/**
 * Fetches about us info.
 */
export const fetchAboutUs = async () => (
  request({
    endpoint: `${CMS_API}/for-us`,
    skipAuthentication: true
  })
);
