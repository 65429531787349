import { useMemo } from 'react';
import { LOAN_ATTRIBUTE_NAMES } from 'const';

/**
 * Returns step zero data based on the given request.
 *
 * @param {Object} request: request data with attributes to extract the step zero data
 */
const useStepZeroData = (request) => (
  useMemo(() => {
    const amount = request.attributes.find((attr) => (
      attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].amount
    )).value;

    const currency = request.attributes.find((attr) => (
      attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].currency
    )).value;

    const period = request.attributes.find((attr) => (
      attr.name === LOAN_ATTRIBUTE_NAMES[request.serviceTypeCode].period
    )).value;

    return {
      amount,
      currency,
      period,
      type: request.serviceTypeCode
    };
  }, [request])
);

export default useStepZeroData;
