import React from 'react';
import ArrowButton from './ArrowButton';

const LeftArrowButton = ({ onClick, disabled }) => (
  <ArrowButton
    className="chevron-left"
    onClick={onClick}
    disabled={disabled}
    svgPath="M8.4021 15.2669L1.62257 8.36094L8.4021 1.45496"
  />
);

export default LeftArrowButton;
