import React from 'react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page/Page';
import { useNewsList } from 'hooks';
import PageLoader from 'components/PageLoader/PageLoader';
import NewsSummary from 'components/NewsSummary/NewsSummary';
import './NewsListPage.scss';

/**
 * Represents the news page.
 */
const NewsListPage = () => {
  const { isLoading, result: newsList } = useNewsList();

  return (
    <Page className="news-list-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Новини | Kredy.bg</title>
      </Helmet>

      {isLoading || !newsList ? (
        <PageLoader />
      ) : (
        <div className="news-list-wrapper">
          <h1>Новини</h1>

          <div className="news-list">
            <div className="list">
              {newsList.map((item) => (
                <NewsSummary item={item} key={item.canonical} />
              ))}
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default NewsListPage;
