import React from 'react';
import errorIcon from 'assets/img/field-error.svg';
import successIcon from 'assets/img/check-green.svg';
import './FieldIndicatorIcon.scss';

/**
 * Represents an indicator on the right of an input field that can indicate an error or a success state (can be neither).
 *
 * @param {Boolean} isError: indicates if the input field is in an error state
 * @param {Boolean} isSuccess: indicates if the input field is in a success state
 */
const FieldIndicatorIcon = ({ isError, isSuccess }) => (
  <>
    {isError && (
      <div className="field-indicator-icon error-icon">
        <img src={errorIcon} alt="" />
      </div>
    )}

    {isSuccess && (
      <div className="field-indicator-icon success-icon">
        <img src={successIcon} alt="" />
      </div>
    )}
  </>
);

export default FieldIndicatorIcon;
