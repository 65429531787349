/**
 * Returns true if the value of the given attribute with the given definition has the default error (required but empty).
 * Returns error string if it is any other error.
 * Returns false if there are no errors related to the given attribute.
 *
 * @param {Object} definition: attribute definition
 * @param {Object} attribute: attribute containing value
 * @return {Boolean || String}
 */
export const isAttributeError = ({ definition, attribute }) => {
  if (definition.type === 'BOOLEAN') {
    return false; // booleans can never have any error
  }

  if (definition.type === 'NUMBER' && attribute && attribute.value < 0) {
    return 'Моля, въведете положително число';
  }

  return definition.required
    && (!attribute || attribute.value === '' || attribute.value === null || attribute.value === undefined);
};
