import React, { useState } from 'react';
import cn from 'classnames';
import { useScroll } from 'hooks';
import { PATHS } from 'config';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Logo from 'components/Logo/Logo';
import hamburgerIcon from 'assets/img/hamburger.svg';
import profileIcon from 'assets/img/profile.svg';
import userSignedIcon from 'assets/img/user-signed.svg';
import MenuLinks from './menu/MenuLinks';
import MobileMenuContent from './menu/MobileMenuContent';
import MobileUserMenuContent from './userMenu/MobileUserMenuContent';
import UserMenu from './userMenu/UserMenu';
import './TopBar.scss';
import NotificationIcon from 'components/NotificationIcon';

const STICKY_THRESHOLD = 90;

/**
 * Represents the application top bar.
 */
const TopBar = () => {
  const [isMobileMenuContentOpen, setIsMobileMenuContentOpen] = useState(false);
  const [isMobileUserMenuContentOpen, setIsMobileUserMenuContentOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useScroll((offset) => {
    setIsSticky((state) => (
      state === false
        ? offset > STICKY_THRESHOLD
        : offset > 0
    ));
  });

  const openMobileMenuContent = () => {
    setIsMobileMenuContentOpen(true);
  };

  const openMobileUserMenuContent = () => {
    setIsMobileUserMenuContentOpen(true);
  };

  const closeMobileMenuContent = () => {
    setIsMobileMenuContentOpen(false);
  };

  const closeMobileUserMenuContent = () => {
    setIsMobileUserMenuContentOpen(false);
  };

  return (
    <>
      {isSticky && (
        <div className="fake-top-bar" />
      )}
      <div className={cn('top-bar', { sticky: isSticky })}>
        <div className="wrapper">
          <Logo />

          <div className="menu">
            <MenuLinks />
            {localStorage.accessToken ? (
              <>
                <div className="notification-icon-wrapper">
                  <NotificationIcon />
                </div>
                <UserMenu />
              </>
            ) : (
              <Button label="Вход" theme="login" href={PATHS.LOGIN} id="entrance" />
            )}
          </div>

          {localStorage.accessToken ? (
            <>
              <div className="mobile-notification-icon-wrapper">
                <NotificationIcon />
              </div>
              <Icon icon={userSignedIcon} className="mobile-user-menu-icon" onClick={openMobileUserMenuContent} />
            </>
          ) : (
            <Icon icon={profileIcon} className="mobile-profile-icon" href={PATHS.LOGIN} />
          )}
          <Icon icon={hamburgerIcon} className="mobile-menu-icon" onClick={openMobileMenuContent} />

          {isMobileMenuContentOpen && (
            <MobileMenuContent onClose={closeMobileMenuContent} />
          )}

          {isMobileUserMenuContentOpen && (
            <MobileUserMenuContent onClose={closeMobileUserMenuContent} />
          )}
        </div>
      </div>
    </>
  );
};

export default TopBar;
