import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { STEP_BEYOND_LAST_NAME } from 'const';
import { getAttributeFieldLabel } from 'lib/app';
import { selectRequestDraftStepDefinition, selectRequestAttributesPerStep, selectCanSaveUserDataStep } from 'store/reducers';
import { saveStepZero as saveStepZeroAction, saveStep as saveStepAction, changeRequestDraftAttributeValue } from 'store/reducers/requestDraft';
import { checkStepAttributeValueForError } from 'store/reducers/stepErrorFields';
import { saveUserDataStep } from 'store/reducers/user';
import { selectCurrencyLabel } from 'store/reducers/initialLoanDetails';
import SliderBox from 'components/SliderBox/SliderBox';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Summary from './Summary';
import FieldGenerator from './FieldGenerator';
import StepBeyondLast from './StepBeyondLast';
import ConfirmPhonePopup from './ConfirmPhonePopup';
import nextIcon from 'assets/img/next.svg';
import './Step.scss';

/**
 * Represents a single step in the Request flow.
 *
 * @param {Number} number: the step index in the list of steps
 * @param {Number} stepCount: number of steps defined in the server
 * @param {Array} stepDefinitionName: step name
 * @param {Array} stepAttributes: attributes that define the step
 * @param {Function} onContinue: called when the user saves the current step in order to go to the next step
 * @param {String} saveRequestStatus: the status of the save HTTP request used to track successful or failed saves
 * @param {String} saveStepBeyondLastRequestStatus: the status of the save HTTP request for the final hardcoded step
 * @param {String} submitFinalRequestRequestStatus: the status of the HTTP request for final request submit
 * @param {Function} saveStepZero: saves the zero step
 * @param {Function} saveStep: saves a particular step by index
 * @param {Boolean} canSaveStepBeyondLast: indicates if the step beyond the last one can be saved (no error fields)
 * @param {Function} saveStepBeyondLast: saves the hardcoded very last step
 * @param {Function} changeField: updates request draft's state when a field changes
 * @param {Array} errorFields: an array of attribute names representing fields with an error
 * @param {Function} onStepChange: called when the current step should change
 * @param {Function} checkFieldForError: checks a field for an error state and updates the redux state
 * @param {Object} locationCodes: key-value pairs of location-code: location-object
 * @param {Array} bankData: bank data items: code, name
 * @param {String} currencyLabel: a currency label used as part of the field labels
 * @param {Object} userDraft: user input data
 */
const Step = ({
  number,
  stepCount,
  stepDefinitionName,
  stepAttributes,
  onContinue,
  saveRequestStatus,
  saveStepBeyondLastRequestStatus,
  submitFinalRequestRequestStatus,
  saveStepZero,
  saveStep,
  canSaveStepBeyondLast,
  saveStepBeyondLast,
  changeField,
  errorFields,
  onStepChange,
  checkFieldForError,
  locationCodes,
  bankData,
  currencyLabel,
  userDraft
}) => {
  const beyondLastStepRef = useRef({});
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const isStepZero = useMemo(() => (
    number === 0
  ), [number]);

  const isStepBeyondLast = useMemo(() => (
    number === stepCount + 1
  ), [number, stepCount]);

  const isSaveInProgress = useMemo(() => (
    saveRequestStatus === 'pending' || saveStepBeyondLastRequestStatus === 'pending' || submitFinalRequestRequestStatus === 'pending'
  ), [saveRequestStatus, saveStepBeyondLastRequestStatus, submitFinalRequestRequestStatus]);

  useEffect(() => {
    if (saveRequestStatus === 'success') {
      onContinue();
    }
  }, [saveRequestStatus]);

  useEffect(() => {
    if (saveStepBeyondLastRequestStatus === 'success') {
      onContinue();
    }
  }, [saveStepBeyondLastRequestStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [number]);

  useEffect(() => {
    if (submitClicked && canSaveStepBeyondLast) {
      setShowConfirm(true);
    }
  }, [submitClicked, canSaveStepBeyondLast]);

  const onSaveClick = () => {
    isStepZero
      ? saveStepZero()
      : isStepBeyondLast
        ? onSaveStepBeyondLast()
        : saveStep(number);
  };

  const onFieldBlur = ({ name }) => {
    checkFieldForError({ name, number });
  };

  const onSaveStepBeyondLast = () => {
    beyondLastStepRef.current.validate();

    setSubmitClicked(true);

    setTimeout(() => {
      setSubmitClicked(false);
    }, 100);
  };

  const getAttributeLabel = (label) => (
    getAttributeFieldLabel(label, { currencyLabel })
  );

  return (
    <div className={cn('step-wrapper', { 'step-zero': isStepZero })}>
      {isSaveInProgress && (
        <Loader />
      )}

      <h1>
        {isStepZero
          ? 'Сума за кандидатстване'
          : isStepBeyondLast
            ? STEP_BEYOND_LAST_NAME
            : stepDefinitionName}
      </h1>
      {!isStepZero && (
        <div className="details">Всички полета със <span className="special-symbol">*</span> са задължителни</div>
      )}

      <div className="step-content">
        <div className="fields">
          {isStepZero ? (
            <SliderBox />
          ) : isStepBeyondLast ? (
            <StepBeyondLast ref={beyondLastStepRef} />
          ) : (
            <FieldGenerator
              attributes={stepAttributes}
              onFieldChange={changeField}
              onFieldBlur={onFieldBlur}
              errorFields={errorFields}
              locationCodes={locationCodes}
              bankData={bankData}
              getFieldLabel={getAttributeLabel}
            />
          )}

          <Button
            theme="primary"
            label={isStepBeyondLast ? 'Изпрати заявка' : 'Продължи'}
            className="continue-button"
            rightIcon={nextIcon}
            onClick={onSaveClick}
            disabled={isSaveInProgress}
            id={`request-process-${number}-${stepDefinitionName.replace(/\s/g, '-')}`}
          />
        </div>

        {!isStepZero && (
          <Summary
            currentStep={number}
            onStepChange={onStepChange}
            locationCodes={locationCodes}
            bankData={bankData}
            getFieldLabel={getAttributeLabel}
          />
        )}

        {showConfirm && (
          <ConfirmPhonePopup
            onConfirm={() => { saveStepBeyondLast(); setShowConfirm(false); }}
            close={() => { setShowConfirm(false); }}
            phone={userDraft.phone}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    stepDefinitionName: ownProps.number > 0 && ownProps.number <= ownProps.stepCount ? selectRequestDraftStepDefinition(state, ownProps.number).displayName : '',
    stepAttributes: ownProps.number > 0 && ownProps.number <= ownProps.stepCount && selectRequestAttributesPerStep(state, ownProps.number),
    saveRequestStatus: state.requestMonitor.saveRequestStep,
    saveStepBeyondLastRequestStatus: state.requestMonitor.updateClientData,
    submitFinalRequestRequestStatus: state.requestMonitor.submitRequest,
    errorFields: state.stepErrorFields,
    locationCodes: state.locationCodes,
    bankData: state.bankData.data,
    currencyLabel: selectCurrencyLabel(state.initialLoanDetails),
    canSaveStepBeyondLast: selectCanSaveUserDataStep(state),
    userDraft: state.userDraft
  }),
  (dispatch) => ({
    saveStepZero: () => {
      dispatch(saveStepZeroAction());
    },
    saveStep: (number) => {
      dispatch(saveStepAction(number));
    },
    saveStepBeyondLast: () => {
      dispatch(saveUserDataStep());
    },
    changeField: (payload) => {
      dispatch(changeRequestDraftAttributeValue(payload));
    },
    checkFieldForError: (payload) => {
      dispatch(checkStepAttributeValueForError(payload));
    }
  })
)(Step);
