import React from 'react';
import { LOAN_TYPES } from 'const';
import { CURRENCIES } from 'store/reducers/initialLoanDetails';

/**
 * Returns the step zero summary.
 *
 * @param {Object} data: contains { type, data, period }
 */
export const getStepZeroSummary = (data) => (
  <>{getServiceTypeLabel(data.type)}: <strong>{getMoneyText(data)} за {getPeriodText(data.period)}</strong></>
);

const BGN_LABEL = 'лв.';
const EUR_LABEL = 'евро';
const PCR_LABEL = 'Потребителски кредит';
const ICR_LABEL = 'Ипотечен кредит';

const getServiceTypeLabel = (type) => {
  switch (type) {
    case LOAN_TYPES.PERSONAL:
      return PCR_LABEL;
    case LOAN_TYPES.MORTGAGE:
      return ICR_LABEL;
    default:
      throw new Error(`Service type ${type} unknown`);
  }
};

const getCurrencyLabel = (currency) => {
  switch (currency.toLowerCase()) {
    case CURRENCIES.BGN:
      return BGN_LABEL;
    case CURRENCIES.EUR:
      return EUR_LABEL;
    default:
      throw new Error(`Currency ${currency} unknown`);
  }
};

const getMoneyText = ({ amount, currency }) => (
  `${amount} ${getCurrencyLabel(currency)}`
);

const getPeriodText = (period) => (
  `${period} ${period > 1 ? 'години' : 'година'}`
);
