import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'config';

/**
 * A custom hook to end the process of submitting a requests.
 * Redirects to My Requests Page and opens a popup.
 */
const useEndProcess = ({ shouldEnd }) => {
  const history = useHistory();

  useEffect(() => {
    if (shouldEnd) {
      localStorage.offerAccepted = true;
      history.push(PATHS.MY_REQUESTS);
    }
  }, [shouldEnd]);
};

export default useEndProcess;
