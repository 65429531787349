import React from 'react';
import { PATHS } from 'config';
import useInjectPinterestCode from './useInjectPinterestCode';
import facebookIcon from 'assets/img/facebook-color-square.svg';
import linkedinIcon from 'assets/img/linkedin-color-square.svg';
import pinterestIcon from 'assets/img/pinterest-color-square.svg';
import './SocialShareButtons.scss';

/**
 * Sharing on Facebook, LinkedIn, Pinterest.
 */
export default function SocialShareButtons({ news }) {
  const canonicalUrl = news && `${window.location.origin}${PATHS.NEWS}/${news.canonical}`;

  useInjectPinterestCode();

  const shareOnFacebook = () => {
    window.FB.ui({
      method: 'share',
      href: canonicalUrl,
    }, (response) => {
      console.log('fb response', response);
    });
  };

  const shareOnLinkedin = () => {
    window.open(
      `http://www.linkedin.com/shareArticle?mini=true&url=${canonicalUrl}&title=${news.title}&summary=${news.summary}&source=${window.location.origin}`,
      'linkedin-popup',
      'width=400,height=600,menubar=no,toolbar=no,location=no,status=no'
    );
  };

  return (
    <div className="social-share-buttons">
      <div className="text">Сподели:</div>
      <ul className="social-icons">
        <li>
          <button
            type="button"
            target="_blank"
            rel="noreferrer"
            onClick={shareOnFacebook}
          >
            <img src={facebookIcon} alt="Facebook" />
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={shareOnLinkedin}
          >
            <img src={linkedinIcon} alt="Linkedin" />
          </button>
        </li>
        <li>
          <a
            href="https://www.pinterest.com/pin/create/button/"
            data-pin-do="buttonBookmark"
            data-pin-custom
          >
            <img src={pinterestIcon} alt="Pinterest" />
          </a>
        </li>
      </ul>
    </div>
  );
}
