import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PATHS } from 'config';
import HomePage from 'pages/HomePage/HomePage';
import LoginPage from 'pages/LoginPage/LoginPage';
import VerifyAccountPage from 'pages/VerifyAccountPage/VerifyAccountPage';
import RequestCreationFlowPage from 'pages/RequestCreationFlowPage/RequestCreationFlowPage';
import MyRequestsPage from 'pages/MyRequestsPage/MyRequestsPage';
import ForgottenPasswordPage from 'pages/ForgottenPasswordPage/ForgottenPasswordPage';
import Error404Page from 'pages/Error404Page/Error404Page';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import ProfilePage from './ProfilePage/ProfilePage';
import MyCreditsPage from './MyCreditsPage/MyCreditsPage';
import useHandleRoute from './useHandleRoute';
import PrivacyPage from './PrivacyPage/PrivacyPage';
import TermsAndConditionsPage from './TermsAndConditionsPage/TermsAndConditionsPage';
import NewsListPage from './NewsListPage/NewsListPage';
import NewsSinglePage from './NewsSinglePage/NewsSinglePage';
import FaqPage from './FaqPage/FaqPage';
import ContactsPage from './ContactsPage/ContactsPage';
import AboutUsPage from './AboutUsPage/AboutUsPage';

const Routes = () => {
  useHandleRoute();

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path={PATHS.LOGIN}>
          <LoginPage />
        </Route>
        <Route path="/verify-account">
          <VerifyAccountPage />
        </Route>
        <Route path={PATHS.REQUEST_CREATION_FLOW}>
          <RequestCreationFlowPage />
        </Route>
        <Route path={PATHS.MY_REQUESTS}>
          <MyRequestsPage />
        </Route>
        <Route path={PATHS.MY_PROFILE}>
          <ProfilePage />
        </Route>
        <Route path={PATHS.MY_CREDITS}>
          <MyCreditsPage />
        </Route>
        <Route path="/forgotten-password">
          <ForgottenPasswordPage />
        </Route>
        <Route path={PATHS.PRIVACY}>
          <PrivacyPage />
        </Route>
        <Route path={PATHS.TERMS}>
          <TermsAndConditionsPage />
        </Route>
        <Route path={`${PATHS.NEWS}/:canonical`}>
          <NewsSinglePage />
        </Route>
        <Route path={PATHS.NEWS}>
          <NewsListPage />
        </Route>
        <Route path={PATHS.FAQ}>
          <FaqPage />
        </Route>
        <Route path={PATHS.CONTACTS}>
          <ContactsPage />
        </Route>
        <Route path={PATHS.ABOUT_US}>
          <AboutUsPage />
        </Route>
        <Route path={PATHS.ERROR}>
          <ErrorPage />
        </Route>
        <Route>
          <Error404Page />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
