import { useEffect, useState } from 'react';

const useCookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    if (!localStorage.cookieBannerAgree) {
      setShowCookieBanner(true);
    }
  }, []);

  const onCookiesAgree = () => {
    localStorage.cookieBannerAgree = true;
    setShowCookieBanner(false);
  };

  return {
    showCookieBanner,
    onCookiesAgree
  };
};

export default useCookieBanner;
