import { useNavigateWhen } from 'hooks';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { initiateRequestFlowWithStepZero } from 'store/reducers/requestDraft';
import { clearRequest } from 'store/reducers/requestMonitor';

/**
 * A component used to redirect correctly to the Request Creation Flow when a logged in user uses the sliders to create / update a request.
 *
 * @param {Function} init
 * @param {Boolean} saveSuccessful
 * @param {Function} clear
 * @param {Boolean} saveFailed: true when the request to save the request data fails
 * @param {Function} onFail: called when the request to save the request data fails in order to notify the parent.
 */
const RequestRedirect = ({ init, saveSuccessful, clear, saveFailed, onFail }) => {
  const [doRedirect, setDoRedirect] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (saveSuccessful) {
      clear();
      setDoRedirect(true);
    }
  }, [saveSuccessful]);

  useEffect(() => {
    if (saveFailed) {
      clear();
      onFail();
    }
  }, [saveFailed]);

  useNavigateWhen({ to: PATHS.REQUEST_CREATION_FLOW, condition: doRedirect });

  return null;
};

export default connect(
  (state) => ({
    saveSuccessful: state.requestMonitor.saveRequestStep === 'success',
    saveFailed: state.requestMonitor.saveRequestStep === 'fail'
  }),
  (dispatch) => ({
    init: () => {
      dispatch(initiateRequestFlowWithStepZero());
    },
    clear: () => {
      dispatch(clearRequest('saveRequestStep'));
    }
  })
)(RequestRedirect);
