import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'staticPages',
  initialState: [],
  reducers: {
    fetchStaticPages: (state) => state,
    setStaticPages: (state, action) => action.payload
  }
});

export const { fetchStaticPages, setStaticPages } = slice.actions;
export default slice.reducer;
