import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Page from 'components/Page/Page';
import PageLoader from 'components/PageLoader/PageLoader';
import { PATHS } from 'config';
import { CMS_BASE_URL } from 'const';
import { formatDate } from 'lib/app';
import useSingleNews from './useSingleNews';
import backIcon from 'assets/img/back.svg';
import SocialShareButtons from './SocialShareButtons/SocialShareButtons';
import LatestNewsCarousel from 'components/LatestNewsCarousel/LatestNewsCarousel';
import './NewsSinglePage.scss';
import { useIsMobile } from 'hooks';

/**
 * Represents a single news page.
 */
const NewsSinglePage = () => {
  const { canonical } = useParams();
  const { isLoading, result: news } = useSingleNews(canonical);
  const isMobile = useIsMobile();

  if (isLoading || !news || !canonical) {
    return <PageLoader />;
  }

  const coverImage = isMobile
    ? news.coverMobile
    : news.coverLarge;

  return (
    <Page
      className="news-single-page"
      prefixComponent={(
        <>
          <div className="main-cover-image">
            <img src={`${CMS_BASE_URL}${coverImage.url}`} alt={coverImage.alternativeText} />
          </div>
        </>
      )}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{news.title} | Kredy.bg</title>

        <meta name="description" content={news.summary} />
        <meta property="og:url" content={`https://kredy.bg${PATHS.NEWS}/${news.canonical}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={news.title} />
        <meta property="og:description" content={news.summary} />
        <meta property="og:image" content={`${CMS_BASE_URL}${news.coverLarge.url}`} />
        <meta property="article:published_time" content={news.published_at} />
        <meta property="article:modified_time" content={news.updated_at} />
      </Helmet>

      <div className="news-wrapper">
        <h1 className="title">{news.title}</h1>

        <div className="date">{formatDate(news.publishDate)}</div>

        <div className="content" data-richtext dangerouslySetInnerHTML={{ __html: news.content }} />

        <div className="share-section">
          <Link to={PATHS.NEWS} className="back">
            <img src={backIcon} alt="" className="back-icon" />
            Назад към новини
          </Link>

          <SocialShareButtons news={news} />
        </div>
      </div>

      <div className="latest-news">
        <div className="heading">Последни новини</div>
        <LatestNewsCarousel />
      </div>
    </Page>
  );
};

export default NewsSinglePage;
