import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useSuccessField, useKeyboardSelection, useRecent } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import arrowIcon from 'assets/img/dropdown-arrow.svg';
import './Select.scss';

/**
 * Represents a Select field used for enumerable option selection.
 *
 * @param {String} label: text label
 * @param {Boolean} isMandatory: indicates if the value selection is mandatory
 * @param {Array} options: list of option strings displayed when the component is open
 * @param {String} defaultValue: initial selected value
 * @param {String} error: error message
 * @param {Function} onChange: called when a new value is selected
 * @param {Function} onBlur: called on blur to check the field state (error or success)
 * @param {String} tooltip: help tooltip displayed next to the component
 */
const Select = ({ label, isMandatory = true, options, defaultValue, error, onChange, onBlur, tooltip }) => {
  const fieldRef = useRef();
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [isFocusedRecently, setIsFocusedRecently] = useRecent();

  const onFocus = () => {
    setIsOpen(true);
    setIsFocusedRecently(true);
  };

  /**
   * Closes (blurs) the component if it has not recently been open.
   */
  const onClick = () => {
    !isFocusedRecently && fieldRef.current.blur();
  };

  const handleBlur = () => {
    setIsOpen(false);
    onBlur();
  };

  const onOptionClick = (option, e) => {
    e.stopPropagation();
    setSelectedOption(option);
    onChange(option);
    setIsSuccess(true);
    fieldRef.current.blur();
  };

  const keyboardOptionIndex = useKeyboardSelection({
    options,
    selectedOption,
    isOpen,
    onSelectOption: onOptionClick,
    ref: fieldRef
  });

  return (
    <FieldWrapper
      className={cn('select-wrapper', { open: isOpen })}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
      onFocus={onFocus}
      onBlur={handleBlur}
      onClick={onClick}
      role="button"
      tabIndex="0"
      ref={fieldRef}
    >
      <div className="value">
        <span className="value-text">{selectedOption}</span>
        <img src={arrowIcon} className="arrow-icon" alt="" />
      </div>

      <ul className="option-list">
        {options.map((option, index) => (
          <li
            key={option}
            onClick={(e) => { onOptionClick(option, e); }}
            className={cn({ selected: selectedOption === option, 'keyboard-hovered': keyboardOptionIndex === index })}
          >
            {option}
          </li>
        ))}
      </ul>
    </FieldWrapper>
  );
};

export default Select;
