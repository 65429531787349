import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from 'api';
import { handleRequestFailure } from './utils/errors';
import { fetchStaticPages, setStaticPages } from 'store/reducers/staticPages';

function* watchFetchStaticPages() {
  yield takeEvery(fetchStaticPages.type, fetchList);
}

export function* fetchList() {
  try {
    const [result] = yield call(api.fetchStaticPages);
    yield put(setStaticPages(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchFetchStaticPages
];
