/**
 * Performs a request to kredy API.
 *
 * @param  {String}  endpoint: request URL
 * @param  {String}  method: GET, POST...
 * @param  {Object}  searchParams: URL params
 * @param  {Object}  data: post body
 * @param  {Object}  headers
 * @param  {Boolean} isBlob: indicates if the expected response is blob, otherwise it is json
 * @param  {Boolean} isFormData: indicates if the provided data is FormData
 * @param  {Boolean} skipAuthentication: prevents sending Authorization token even when the user is signed-in
 * @return {Promise}
 */
const request = async ({ endpoint, method = 'GET', searchParams, data, headers = {}, isBlob = false, isFormData = false, skipAuthentication = false }) => {
  const url = new URL(endpoint);

  if (searchParams) {
    url.search = new URLSearchParams(searchParams).toString();
  }

  const grecaptchaToken = window.grecaptcha_token;

  if (window.grecaptcha_token) {
    delete window.grecaptcha_token;
  }

  const response = await fetch(url, {
    method,
    headers: {
      ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
      ...(localStorage.accessToken && !skipAuthentication ? { Authorization: `Bearer ${localStorage.accessToken}` } : {}),
      ...(grecaptchaToken ? { 'x-google-recaptcha': grecaptchaToken } : {}),
      ...headers
    },
    body: data && (typeof data !== 'string' && !isFormData ? JSON.stringify(data) : data)
  });

  let result = null;

  if (response) {
    try {
      result = isBlob
        ? await response.blob()
        : await response.json();
    } catch (e) {
      // malformat response from the server - do nothing
    }
  }

  if (response && (response.status < 200 || response.status > 299)) {
    const error = new Error(`${method} Request to ${endpoint} failed with status ${response.status}. Check the response and result props of this exception.`);
    error.response = response;
    error.result = result;
    throw error;
  }

  return [result, response];
};

export default request;
