import { takeEvery, call, put, delay, select } from 'redux-saga/effects';
import * as api from 'api';
import { getOfferRejectFeedbackDefinition, setOfferRejectFeedbackDefinition } from 'store/reducers/offerRejectFeedbackDefinition';
import { handleRequestFailure } from './utils/errors';

function* watchGetOfferRejectFeedbackDefinition() {
  yield takeEvery(getOfferRejectFeedbackDefinition.type, getDefinition);
}

function* getDefinition() {
  try {
    yield delay(1000); // no need to hurry

    const existingData = yield select((state) => state.offerRejectFeedbackDefinition);

    if (existingData.length > 0) {
      return;
    }

    const [result] = yield call(api.fetchOfferRejectFeedbackDefinition);

    yield put(setOfferRejectFeedbackDefinition(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchGetOfferRejectFeedbackDefinition
];
