import { takeEvery, call, put, select } from 'redux-saga/effects';
import { searchLocations, setSearchLocationResults } from 'store/reducers/searchLocationResults';
import * as api from 'api';
import { handleRequestFailure } from './utils/errors';
import { addLocationData, getLocationByCode } from 'store/reducers/locationCodes';

function* watchSearchLocations() {
  yield takeEvery(searchLocations.type, search);
}

function* search({ payload }) {
  try {
    const [result] = yield call(api.searchLocations, payload);
    yield put(setSearchLocationResults(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

function* watchGetLocationByCode() {
  yield takeEvery(getLocationByCode.type, checkFetchLocation);
}

function* checkFetchLocation({ payload }) {
  const existingLocation = yield select((state) => state.locationCodes[payload]);

  if (existingLocation) {
    return;
  }

  try {
    const [location] = yield call(api.fetchLocationByCode, payload);
    yield put(addLocationData(location));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchSearchLocations,
  watchGetLocationByCode
];
