import { useQuery } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

const REFRESH_INTERVAL = 15000;

const useNotificationList = () => {
  const { isLoading, error, data } = useQuery(
    'notification-list',
    () => (
      api.fetchNotifications()
    ),
    {
      refetchInterval: REFRESH_INTERVAL
    }
  );

  useHTTPError(error);

  return {
    isLoading,
    data: data && data[0]
  };
};

export default useNotificationList;
