import React from 'react';
import cn from 'classnames';
import Clickable from 'components/Clickable';
import './Button.scss';

/**
 * The corporate button.
 *
 * @param {String}  label
 * @param {String}  theme: default (secondary), login, primary
 * @param {String}  className
 * @param {Boolean} disabled
 * @param {Boolean} visuallyDisabled: visually disable the button without removing pointer events
 * @param {SVG}     leftIcon: icon to display on the left
 * @param {SVG}     rightIcon: icon to display on the right
 * @param           props: the rest of the props passed to the <button>
 */
const Button = ({ label, theme = 'default', className = '', disabled = false, visuallyDisabled, leftIcon, rightIcon, ...props }) => (
  <Clickable
    disabled={disabled}
    className={cn(`kredy-button ${theme} ${className}`, { 'visually-disabled': visuallyDisabled })}
    {...props}
  >
    <div className="label">{label}</div>
    {leftIcon && (
      <img src={leftIcon} alt="" className="left-icon" />
    )}
    {rightIcon && (
      <img src={rightIcon} alt="" className="right-icon" />
    )}
  </Clickable>
);

export default Button;
