import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createClient } from 'store/reducers/user';
import { isValidEmail } from 'lib';
import LABELS from 'labels';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import img from 'assets/img/register-with-email.svg';
import './RegisterWithEmail.scss';
import withGrecaptcha from 'lib/grecaptcha';
import AcceptTncCheckbox from 'components/AcceptTncCheckbox/AcceptTncCheckbox';

/**
 * A section containing register with email functionality.
 *
 * @param {Function} register: register the user on the server
 * @param {Boolean}  isButtonDisabled: indicates if the register button is disabled
 */
const RegisterWithEmail = ({ register, isButtonDisabled }) => {
  const areTermsAcceptedRef = useRef();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const [hasTermsAndConditionsError, setHasTermsAndConditionsError] = useState(false);

  const handleRegister = (e) => {
    e.preventDefault();

    setHasTermsAndConditionsError(!areTermsAcceptedRef.current);

    if (email.length === 0) {
      setEmailError(LABELS.emailEmpty);
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError(LABELS.emailInvalid);
      return;
    }

    setEmailError();
    if (areTermsAcceptedRef.current) {
      withGrecaptcha(() => {
        register({ email });
      });
    }
  };

  return (
    <div className="register-with-email">
      <img src={img} alt="" />
      <div className="content">
        <div className="title">
          Започнете още сега с регистрацията си бързо и лесно!
        </div>
        <form noValidate onSubmit={handleRegister}>
          <InputField
            type="email"
            label="Email"
            isMandatory={false}
            value={email}
            error={emailError}
            onChange={(e) => { setEmail(e.target.value); }}
          />

          <AcceptTncCheckbox
            onChange={(isChecked) => { areTermsAcceptedRef.current = isChecked; }}
            showError={hasTermsAndConditionsError}
          />

          <Button label="Започни сега" theme="primary" type="submit" disabled={isButtonDisabled} id="start-now-with-email" />
        </form>

      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isButtonDisabled: state.requestMonitor.createClient === 'pending'
  }),
  (dispatch) => ({
    register: (args) => {
      dispatch(createClient(args));
    }
  })
)(RegisterWithEmail);
