import { useQuery } from 'react-query';
import * as api from 'api';
import useHTTPError from 'hooks/useHTTPError';

/**
 * Uses the FAQ data.
 */
const useFaq = () => {
  const { isLoading, error, data } = useQuery(
    'faq',
    () => (
      api.fetchFaq()
    )
  );

  useHTTPError(error);

  return {
    isLoading,
    result: data && data[0]
  };
};

export default useFaq;
