import React from 'react';
import cn from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import './Checkbox.scss';

/**
 * Represents a Checkbox component.
 *
 * @param {String} label: text label
 * @param {String} defaultValue: indicates if the checkbox is checked initially
 * @param {String} error: error message
 * @param {Function} onChange: called when the checkbox state changes
 * @param {String} tooltip: help tooltip displayed next to the component
 */
const Checkbox = ({ label, defaultValue, error, onChange, tooltip }) => (
  <div className={cn('checkbox-wrapper', { error })}>
    <label>
      <input type="checkbox" defaultChecked={defaultValue} onChange={(e) => { onChange(e.target.checked); }} />
      <span className="text">
        {label}
        {tooltip && (
          <Tooltip text={tooltip} />
        )}
      </span>
    </label>
  </div>
);

export default Checkbox;
