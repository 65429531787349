import { connect } from 'react-redux';
import { searchBank } from 'store/reducers/bankData';
import SearchField from './SearchField';

/**
 * Represents a search field for banks.
 */
export default connect(
  (state) => ({
    options: state.bankData.searchResults
  }),
  (dispatch) => ({
    search: (value) => {
      dispatch(searchBank(value));
    }
  })
)(SearchField);
