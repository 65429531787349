import { useDispatch } from 'react-redux';
import { redirectToError } from 'store/reducers/generalError';

const HTTP_UNAUTHORIZED = 401;

const useHTTPError = (e) => {
  const dispatch = useDispatch();

  if (!e) {
    return;
  }

  if (e.response && e.response.status === HTTP_UNAUTHORIZED) {
    delete localStorage.accessToken;
    window.location.reload();
    return;
  }

  const { result, response, message, ...exception } = e;

  console.log(result);
  console.log(response);

  if (result === undefined && response === undefined) {
    // prevent infinite loop
    return;
  }

  dispatch(redirectToError({
    message,
    exception,
    result,
    response
  }));
};

export default useHTTPError;
