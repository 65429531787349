import React, { useEffect } from 'react';
import { PATHS } from 'config';
import './MyCreditsPage.scss';
import SideMenuPage from 'components/SideMenuPage/SideMenuPage';
import useCreditList from './useCreditList';
import CreditList from './CreditList';
import { fetchUserRequests } from 'store/reducers/userRequests';
import { connect } from 'react-redux';
import { fetchProviderData } from 'store/reducers/providers';
import LoginPage from 'pages/LoginPage/LoginPage';

/**
 * Represents a page containing credits.
 */
const MyCreditsPage = ({ init, requestList, providersList, isLoadingRequests, initProviderData }) => {
  const { data, isLoading } = useCreditList();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    data && data[0] && data.forEach((credit) => {
      initProviderData(credit.providerId);
    });
  }, [data]);

  if (!localStorage.accessToken) {
    return <LoginPage />;
  }

  return (
    <SideMenuPage
      isLoading={isLoading || isLoadingRequests}
      activePath={PATHS.MY_CREDITS}
    >
      <h1>Моите кредити</h1>

      <CreditList
        creditList={data}
        requestList={requestList}
        providers={providersList}
      />
    </SideMenuPage>
  );
};

export default connect(
  (state) => ({
    requestList: state.userRequests,
    providersList: state.providers,
    isLoadingRequests: state.requestMonitor.userRequests === 'pending'
  }),
  (dispatch) => ({
    init: () => {
      dispatch(fetchUserRequests());
    },
    initProviderData: (id) => {
      dispatch(fetchProviderData(id));
    }
  })
)(MyCreditsPage);
