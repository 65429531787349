import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchUserRequests, setUserRequests } from 'store/reducers/userRequests';
import { failRequest, startRequest, successRequest } from 'store/reducers/requestMonitor';
import * as api from 'api';
import { handleRequestFailure } from './utils/errors';

function* watchFetchUserRequests() {
  yield takeEvery(fetchUserRequests.type, fetchRequests);
}

function* fetchRequests() {
  try {
    yield put(startRequest('userRequests'));

    const [result] = yield call(api.fetchUserRequests);
    yield put(setUserRequests(result));

    yield put(successRequest('userRequests'));
  } catch (e) {
    yield put(failRequest('userRequests'));
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchFetchUserRequests
];
