import React, { useState } from 'react';
import cn from 'classnames';
import { ACTIVE_COLOR, INACTIVE_COLOR, HOVER_COLOR } from './styles';
import './ArrowButton.scss';

const ArrowButton = ({ className, onClick, disabled, svgPath }) => {
  const [arrowColor, setArrowColor] = useState(ACTIVE_COLOR);

  return (
    <div
      className={cn(`arrow-button ${className}`, { disabled })}
      onClick={() => { !disabled && onClick(); }}
      onMouseOver={() => { setArrowColor(HOVER_COLOR); }}
      onMouseLeave={() => { setArrowColor(ACTIVE_COLOR); }}
    >
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={svgPath} stroke="white" strokeWidth="1.5" />
        <path d={svgPath} stroke="#16B14B" strokeWidth="1.5" />
        <path d={svgPath} stroke={disabled ? INACTIVE_COLOR : arrowColor} strokeWidth="1.5" />
      </svg>
    </div>
  );
};

export default ArrowButton;
