import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';

const StyledSlider = withStyles({
  root: {
    color: '#16b14b',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 24,
    width: 24,
    background: 'linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%)',
    border: '2px solid white',
    marginTop: -10,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {

  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
    color: '#DADADA',
    opacity: 1,
  },
  mark: {
    display: 'none'
  }
})(MaterialSlider);

const useStyles = makeStyles(() => ({
  marks: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: '500'
  }
}));

const Slider = ({ min, max, step = 1, minLabel, maxLabel, value, onChange }) => {
  const classes = useStyles();

  return (
    <div>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e, v) => { onChange(v); }}
      />
      <div className={classes.marks}>
        <div className="min">{minLabel}</div>
        <div className="max">{maxLabel}</div>
      </div>
    </div>
  );
};

export default Slider;
