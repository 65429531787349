import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSuccessField } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldIndicatorIcon from '../FieldIndicatorIcon/FieldIndicatorIcon';
import './StepInput.scss';

const REGEX = /^[0-9]{0,10}$/;

/**
 * An EGN-specific field that restricts user input to numbers-only.
 */
const EGNInput = ({ label, tooltip, isMandatory = true, error, defaultValue, onChange, onBlur, ...props }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });

  const htmlId = useMemo(() => (
    uuid()
  ), []);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const handleChange = (e) => {
    const userValue = e.target.value;

    if (userValue.match(REGEX)) {
      setValue(userValue);
    }
  };

  const handleBlur = (e) => {
    onBlur();

    if (e.target.value && !error) {
      setIsSuccess(true);
    }
  };

  return (
    <FieldWrapper
      className="step-input-wrapper"
      htmlId={htmlId}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
    >
      <div className="input">
        <input
          {...props}
          type="text"
          onBlur={handleBlur}
          id={htmlId}
          value={value}
          onChange={handleChange}
        />

        <FieldIndicatorIcon isError={!!error} isSuccess={isSuccess} />
      </div>
    </FieldWrapper>

  );
};

export default EGNInput;
