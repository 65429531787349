import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import AppPortal from 'components/AppPortal';
import Icon from 'components/Icon/Icon';
import closeIcon from 'assets/img/close.svg';
import Logo from 'components/Logo/Logo';
import UserMenuLinks from 'components/UserMenuLinks/UserMenuLinks';
import './MobileUserMenuContent.scss';

/**
 * An overlay shown when the user opens the user mobile menu.
 *
 * @param {Function} onClose
 */
const MobileUserMenuContent = ({ onClose }) => {
  usePreventGlobalScroll();

  return (
    <AppPortal>
      <div className="mobile-user-menu-content">
        <div className="header">
          <Logo />
          <Icon icon={closeIcon} className="close" onClick={onClose} />
        </div>

        <div className="menu-links">
          <UserMenuLinks active={window.location.pathname} />
        </div>
      </div>
    </AppPortal>
  );
};

export default MobileUserMenuContent;
