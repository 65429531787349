import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Navigates to the specified path when the given condition is met.
 *
 * @param  {String} to: url
 * @param  {Boolean} condition: navigation occurs when this condition is true
 */
const useNavigateWhen = ({ to, condition }) => {
  const history = useHistory();

  useEffect(() => {
    if (condition) {
      if (to === `${window.location.pathname}${window.location.search}`) {
        window.reloading = true;
        window.location.reload();
      } else {
        history.push(to);
      }
    }
  }, [condition]);
};

export default useNavigateWhen;
