import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'qs';
import { selectRequestDraftStepDefinitionList } from 'store/reducers';
import {
  initRequestInProgress,
  selectAreBothRequestsInNotInitState,
  selectHasRequestForServiceType,
  selectRequestDraftCompletedStep,
  setRequestDraftServiceTypeCode,
  submitFinalRequest as submit
} from 'store/reducers/requestDraft';
import Page from 'components/Page/Page';
import PageLoader from 'components/PageLoader/PageLoader';
import StepIndicator from '../StepIndicator/StepIndicator';
import Step from '../Step/Step';
import './Process.scss';
import useEndProcess from './useEndProcess';

const DEFAULT_STEP = 1;
const NUMBER_OF_HARDCODED_STEPS_BEYOND_THE_LAST = 1;

/**
 * The page responsible for collecting all user data used for requesting a credit.
 *
 * @param {Function} init: initializes data
 * @param {Boolean} isLoading: indicates if the initialization is still being processed
 * @param {Boolean} isInitializing: true when the initialization has already been started (e.g. from App.js)
 * @param {Array} existingRequests: an array of existing requests
 * @param {String} serviceTypeCode: the currently selected service type (pcr or icr)
 * @param {Function} setServiceTypeCode: sets the service type code (used when an id of the request is provided in the URL)
 * @param {Boolean} isExistingRequest: indicates if the request is an existing one, false when it is new
 * @param {Number} completedStep: indicates which step has been completed by the user for existing requests, null for new requests
 * @param {Array} stepDefinitions: an array of field definitions for each step
 * @param {Function} submitFinalRequest: submits the final request after the user confirms the last step
 * @param {Boolean} finalRequestSubmittedSuccessfully: true when the final request is saved on the server, so the Request Creation Process is completed
 * @param {Boolean} noEditableRequests: true when both requests (icr & pcr) are not editable
 */
const Process = ({
  init,
  isLoading,
  isInitializing,
  existingRequests,
  serviceTypeCode,
  setServiceTypeCode,
  isExistingRequest,
  completedStep,
  stepDefinitions,
  submitFinalRequest,
  finalRequestSubmittedSuccessfully,
  noEditableRequests
}) => {
  const location = useLocation();
  const { id: requestIdParam } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isChangingServiceTypeCode, setIsChangingServiceTypeCode] = useState(!!requestIdParam);

  const [step, setStep] = useState();

  /**
   * Checks if there is a requestId param in the URL. If so, makes sure the correct request is selected.
   */
  useEffect(() => {
    const requestId = requestIdParam ? parseInt(requestIdParam) : null;

    if (!serviceTypeCode && !isInitializing) {
      init(requestId);
    }

    if (serviceTypeCode && requestId) {
      const existingRequest = existingRequests.find((req) => (
        req.id === requestId
      ));

      if (existingRequest) {
        setServiceTypeCode(existingRequest.serviceTypeCode);
      }
    }

    setIsChangingServiceTypeCode(false);
  }, []);

  useEffect(() => {
    if (!isLoading && stepDefinitions && serviceTypeCode && step === undefined && !isChangingServiceTypeCode) {
      setStep(getInitialStep());
    }
  }, [isLoading, step, stepDefinitions, serviceTypeCode, isExistingRequest, isChangingServiceTypeCode]);

  useEndProcess({ shouldEnd: finalRequestSubmittedSuccessfully || noEditableRequests });

  /**
   * Returns the initial step number for the given service type.
   * The initial step is:
   * - whatever is saved in localStorage;
   * - completedStep + 1 if no localStorage data;
   * - 1 for existing requests if no completedStep;
   * - 0 for new requests
   */
  const getInitialStep = () => {
    if (!isExistingRequest) {
      return 0;
    }

    if (localStorage[`${serviceTypeCode}Step`]) {
      return Number(localStorage[`${serviceTypeCode}Step`]);
    }

    return completedStep !== undefined ? Math.min(completedStep + 1, stepDefinitions.length) : DEFAULT_STEP;
  };

  /**
   * Called when the user has saved the current step in order to go to the next one.
   */
  const goToNextStep = () => {
    if (step < stepDefinitions.length + NUMBER_OF_HARDCODED_STEPS_BEYOND_THE_LAST) {
      setStep((state) => {
        localStorage[`${serviceTypeCode}Step`] = state + 1;
        return state + 1;
      });
    } else {
      delete localStorage[`${serviceTypeCode}Step`];
      submitFinalRequest();
    }
  };

  const changeStep = (nextStep) => {
    localStorage[`${serviceTypeCode}Step`] = nextStep;
    setStep(nextStep);
  };

  return (
    <Page className="process-page">
      {step === undefined || isLoading || !stepDefinitions
        ? <PageLoader />
        : (
          <>
            <StepIndicator stepDefinitions={stepDefinitions} activeStep={step} onStepChange={changeStep} />
            <Step number={step} stepCount={stepDefinitions.length} onContinue={goToNextStep} onStepChange={changeStep} />
          </>
        )
      }
    </Page>
  );
};

export default connect(
  (state) => ({
    existingRequests: state.requestDraft.requests,
    serviceTypeCode: state.requestDraft.serviceTypeCode,
    isExistingRequest: selectHasRequestForServiceType(state.requestDraft, state.requestDraft.serviceTypeCode),
    isLoading: !state.requestDraft.serviceTypeCode && state.requestMonitor.initRequestInProgress !== 'success' && state.requestMonitor.initRequestInProgress !== 'fail',
    isInitializing: state.requestDraft.isInitializing,
    completedStep: selectRequestDraftCompletedStep(state.requestDraft),
    stepDefinitions: selectRequestDraftStepDefinitionList(state),
    finalRequestSubmittedSuccessfully: state.requestMonitor.submitRequest === 'success',
    noEditableRequests: selectAreBothRequestsInNotInitState(state.requestDraft)
  }),
  (dispatch) => ({
    init: (requestId) => {
      dispatch(initRequestInProgress({ requestId }));
    },
    setServiceTypeCode: (serviceTypeCode) => {
      dispatch(setRequestDraftServiceTypeCode(serviceTypeCode));
    },
    submitFinalRequest: () => {
      dispatch(submit());
    }
  })
)(Process);
