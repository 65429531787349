import React from 'react';
import img from 'assets/img/people.svg';
import './SecondaryBackground.scss';

const SecondaryBackground = () => (
  <div className="secondary-background">
    <img alt="kredy.bg" src={img} />
  </div>
);

export default SecondaryBackground;
