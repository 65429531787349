import { useEffect } from 'react';

/**
 * Custom hook for preventing global scroll across the application when a component is rendered.
 */
const usePreventGlobalScroll = () => {
  useEffect(() => {
    document.querySelector('html').style.overflow = 'hidden';

    return () => {
      document.querySelector('html').style.overflow = '';
    };
  });
};

export default usePreventGlobalScroll;
