import { createSlice } from '@reduxjs/toolkit';
import { setUserData } from './user';

/**
 * Stores info about the user.
 * The info can be changes in the Personal Data step of the Request Creation Flow.
 */
const slice = createSlice({
  name: 'userDraft',
  initialState: {},
  reducers: {
    changeFieldValue: (state, { payload }) => {
      state[payload.name] = payload.value;
    }
  },
  extraReducers: {
    [setUserData]: (state, { payload }) => ({
      ...payload
    })
  }
});

export const { changeFieldValue } = slice.actions;
export default slice.reducer;
