/* eslint-disable max-len */
import React from 'react';

export default function Success() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 1.83301C9.8905 1.83301 7.82821 2.4586 6.07408 3.63067C4.31996 4.80273 2.95279 6.46864 2.14545 8.41772C1.33812 10.3668 1.12688 12.5115 1.53846 14.5806C1.95003 16.6498 2.96594 18.5504 4.4577 20.0421C5.94946 21.5339 7.85007 22.5498 9.9192 22.9614C11.9883 23.373 14.133 23.1617 16.0821 22.3544C18.0312 21.5471 19.6971 20.1799 20.8692 18.4258C22.0412 16.6716 22.6668 14.6093 22.6668 12.4997C22.6668 9.6707 21.543 6.95759 19.5426 4.9572C17.5423 2.95681 14.8291 1.83301 12.0002 1.83301ZM12.0002 21.833C10.1542 21.833 8.3497 21.2856 6.81485 20.2601C5.27999 19.2345 4.08371 17.7768 3.37729 16.0714C2.67087 14.3659 2.48604 12.4893 2.84617 10.6788C3.2063 8.86834 4.09521 7.2053 5.4005 5.90001C6.70579 4.59472 8.36884 3.70581 10.1793 3.34568C11.9898 2.98555 13.8664 3.17038 15.5719 3.8768C17.2773 4.58322 18.735 5.77949 19.7605 7.31435C20.7861 8.84921 21.3335 10.6537 21.3335 12.4997C21.3335 14.975 20.3502 17.349 18.5998 19.0993C16.8495 20.8497 14.4755 21.833 12.0002 21.833Z"
      />
      <path
        d="M18.6665 8.56691C18.5416 8.44274 18.3726 8.37305 18.1965 8.37305C18.0203 8.37305 17.8514 8.44274 17.7265 8.56691L10.3265 15.9336L6.32646 11.9336C6.20446 11.8019 6.03513 11.724 5.85572 11.7171C5.67631 11.7102 5.50152 11.7749 5.36979 11.8969C5.23807 12.0189 5.16021 12.1882 5.15333 12.3676C5.14645 12.5471 5.21113 12.7219 5.33313 12.8536L10.3265 17.8336L18.6665 9.51358C18.7289 9.4516 18.7785 9.37787 18.8124 9.29663C18.8462 9.21539 18.8637 9.12825 18.8637 9.04024C18.8637 8.95223 18.8462 8.8651 18.8124 8.78386C18.7785 8.70262 18.7289 8.62888 18.6665 8.56691Z"
      />
    </svg>
  );
}
