import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import step1 from 'assets/img/how-it-works/1.svg';
import step2 from 'assets/img/how-it-works/2.svg';
import step3 from 'assets/img/how-it-works/3.svg';
import step4 from 'assets/img/how-it-works/4.svg';
import arrow from 'assets/img/arrow-right.svg';
import './HowItWorks.scss';

const HotItWorks = () => (
  <div className="how-it-works-section" id="how-it-works-section">
    <SectionTitle label="Как работи Kredy?" />

    <div className="steps">
      <Step
        number="01"
        imageSrc={step1}
        title="Попълвате заявката за кредит"
      />

      <div className="arrow">
        <img src={arrow} alt="" />
      </div>

      <Step
        number="02"
        imageSrc={step2}
        title="Получавате оферти"
      />

      <div className="arrow">
        <img src={arrow} alt="" />
      </div>

      <Step
        number="03"
        imageSrc={step3}
        title="Вие сравнявате и избирате"
      />

      <div className="arrow">
        <img src={arrow} alt="" />
      </div>

      <Step
        number="04"
        imageSrc={step4}
        title="Професионален консултант ще се свърже с Вас"
      />
    </div>
  </div>
);

const Step = ({ number, imageSrc, title }) => (
  <div className="step">
    <div className="number">{number}</div>
    <div className="title">{title}</div>
    <img src={imageSrc} alt={title} />
  </div>
);

export default HotItWorks;
