/* eslint-disable max-len */
import React from 'react';

export default function Edit() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.111 18.3333H3.66656V4.88885H11.7454L12.9677 3.66663H3.66656C3.3424 3.66663 3.03153 3.7954 2.80232 4.02461C2.57311 4.25382 2.44434 4.56469 2.44434 4.88885V18.3333C2.44434 18.6574 2.57311 18.9683 2.80232 19.1975C3.03153 19.4267 3.3424 19.5555 3.66656 19.5555H17.111C17.4352 19.5555 17.746 19.4267 17.9752 19.1975C18.2045 18.9683 18.3332 18.6574 18.3332 18.3333V9.16663L17.111 10.3888V18.3333Z"
      />
      <path
        d="M20.4905 3.56891L18.4311 1.50946C18.3397 1.41782 18.2311 1.3451 18.1115 1.29549C17.992 1.24588 17.8638 1.22034 17.7344 1.22034C17.605 1.22034 17.4768 1.24588 17.3572 1.29549C17.2377 1.3451 17.1291 1.41782 17.0377 1.50946L8.65939 9.93669L7.98106 12.8761C7.95216 13.0186 7.9552 13.1657 7.98995 13.3069C8.02471 13.4481 8.09032 13.5798 8.18207 13.6926C8.27381 13.8054 8.38941 13.8964 8.52056 13.9592C8.6517 14.0219 8.79512 14.0549 8.9405 14.0556C9.01565 14.0639 9.09147 14.0639 9.16661 14.0556L12.1305 13.4017L20.4905 4.96224C20.5821 4.87085 20.6549 4.76226 20.7045 4.64272C20.7541 4.52317 20.7796 4.39501 20.7796 4.26558C20.7796 4.13614 20.7541 4.00798 20.7045 3.88844C20.6549 3.76889 20.5821 3.66031 20.4905 3.56891ZM11.4949 12.2711L9.25828 12.7661L9.77772 10.5478L16.0844 4.19835L17.8077 5.92169L11.4949 12.2711ZM18.4983 5.23113L16.7749 3.5078L17.7222 2.54224L19.4577 4.2778L18.4983 5.23113Z"
      />
    </svg>
  );
}
