import React, { useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import DesktopNotificationDropdown from './DesktopNotificationDropdown';
import NotificationIconUI from './NotificationIconUI';

export default function DesktopNotificationIcon({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  const onBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const onDropdownClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={cn(styles.icon, styles.desktop, { [styles.unseen]: data && data.totalUnseen > 0 })}
      onClick={() => { setIsOpen((state) => !state); }}
      tabIndex={-1}
      onBlur={onBlur}
    >
      <NotificationIconUI data={data} />

      {data && isOpen && (
        <div onClick={onDropdownClick}>
          <DesktopNotificationDropdown data={data.notifications} />
        </div>
      )}
    </div>
  );
}
