import React from 'react';
import loaderIcon from 'assets/img/Spinner-1s-200px.gif';
import './PageLoader.scss';

const PageLoader = () => (
  <div className="page-loader">
    <img src={loaderIcon} alt="" />
  </div>
);

export default PageLoader;
