import StaticPage from 'components/StaticPage/StaticPage';
import { connect } from 'react-redux';

/**
 * Represents the terms and conditions static page.
 */
export default connect(
  (state) => ({
    page: state.staticPages.find((item) => item.canonical === 'terms-and-conditions')
  })
)(StaticPage);
