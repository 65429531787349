import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchStepDefinitions = createAction('stepDefinitions/fetchStepDefinitions');
export const getStepDefinitions = createAction('stepDefinitions/getStepDefinitions');

/**
 * Keeps the step definitions that represent the type and names of the fields in each step of the request creation flow.
 * There are different step definitions for the two service types (icr & pcr).
 */
const slice = createSlice({
  name: 'stepDefinitions',
  initialState: {}, // icr: {}, pcr: {}
  reducers: {
    addStepDefinitions: (state, action) => {
      state[action.payload.serviceType] = action.payload.data;
    }
  }
});

/**
 * Selects attributes related to the given step and serviceType.
 *
 * @param {Object} state: this reducer's state
 * @param {Number} stepNumber: the index of the step in the list of step definitions
 * @param {String} serviceType: the selected service type code
 * @return {Array} an array of attributes { name, type, enumValues, ... }
 */
export const selectDefinitionByStep = (state, step, serviceType) => (
  state[serviceType] && state[serviceType].find((item) => (
    item.number === step
  ))
);

/**
 * Selects attributes related to the given step, serviceType, and attribute name.
 *
 * @param {Object} state: this reducer's state
 * @param {Number} stepNumber: the index of the step in the list of step definitions
 * @param {String} serviceType: the selected service type code
 * @param {String} attrName: the attribute name
 * @return {Object} attribute data { name, type, ... }
 */
export const selectDefinitionByAttrName = (state, { step, serviceType, attrName }) => (
  selectDefinitionByStep(state, step, serviceType).attributes.find((item) => (
    item.name === attrName
  ))
);

export const { addStepDefinitions } = slice.actions;
export default slice.reducer;
