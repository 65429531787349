import { createAction, createSlice } from '@reduxjs/toolkit';

export const getBankData = createAction('bankData/getBankData');

const slice = createSlice({
  name: 'bankData',
  initialState: {
    data: [],
    searchResults: []
  },
  reducers: {
    setBankData: (state, { payload }) => {
      const data = [...payload].sort((first, second) => (
        first.name < second.name ? -1 : 1
      ));

      state.data = data;
      state.searchResults = data;
    },
    searchBank: (state, { payload }) => {
      state.searchResults = state.data.filter((item) => (
        item.name.toLowerCase().indexOf(payload.toLowerCase()) !== -1
      ));
    }
  }
});

export const { setBankData, searchBank } = slice.actions;
export default slice.reducer;
