import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page/Page';
import InputField from 'components/InputField/InputField';
import { isValidEmail, isValidBulgarianPhone } from 'lib';
import { useBulgarianPhone, useFieldErrors, useNavigateWhen } from 'hooks';
import LABELS from 'labels';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import useSendMessage from './useSendMessage';
import EmailSentPopup from 'components/EmailSentPopup/EmailSentPopup';
import './ContactsPage.scss';
import withGrecaptcha from 'lib/grecaptcha';

const ContactsPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone, hasPhoneError] = useBulgarianPhone();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const areTermsAcceptedRef = useRef();
  const [hasTermsAndConditionsError, setHasTermsAndConditionsError] = useState(false);

  const { errors, addError, hasErrors, clearFieldError, clearErrors } = useFieldErrors();

  const {
    sendMessage,
    isLoading,
    isSuccess: isSentSuccess,
    error: sendError
  } = useSendMessage();

  const [closeForm, setCloseForm] = useState(false);

  useNavigateWhen({ to: '/', condition: closeForm });

  const validateName = () => {
    console.log('name', name);
    name.length < 3 || name.length > 100
      ? addError({ name: 'Името трябва да е между 3 и 100 символа' })
      : clearFieldError('name');
  };

  const validateEmail = () => {
    !isValidEmail(email) && addError({ email: LABELS.emailInvalid });
    email.length === 0 && addError({ email: LABELS.emailEmpty });

    if (isValidEmail(email)) {
      clearFieldError('email');
    }
  };

  const validatePhone = () => {
    hasPhoneError || !isValidBulgarianPhone(phone)
      ? addError({ phone: LABELS.phoneInvalid })
      : clearFieldError('phone');
  };

  const validateSubject = () => {
    subject.length === 0 || subject.length > 100
      ? addError({ subject: 'Полето трябва да е между 1 и 100 символа' })
      : clearFieldError('subject');
  };

  const validateContent = () => {
    content.length === 0 || content.length > 1000
      ? addError({ content: 'Съобщението трябва да е между 1 и 1000 символа' })
      : clearFieldError('content');
  };

  const onSendClick = async () => {
    clearErrors();

    validateName();
    validateEmail();
    validatePhone();
    validateSubject();
    validateContent();

    const hasError = await hasErrors();
    setHasTermsAndConditionsError(!areTermsAcceptedRef.current);

    if (areTermsAcceptedRef.current && !hasError) {
      withGrecaptcha(() => {
        sendMessage({
          name,
          email,
          phone,
          subject,
          content
        });
      });
    }
  };

  return (
    <Page className="contacts-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Контакти | Kredy.bg</title>
      </Helmet>

      <div className="contacts-page-wrapper">
        <h1>Контакти</h1>

        {/* <ul className="contact-list">
          <li>
            <img src={emailIcon} alt="" />
            <div>hello@kredy.bg</div>
          </li>
          <li>
            <img src={phoneIcon} alt="" />
            <div>0888 123 456</div>
          </li>
        </ul> */}

        <h2>Форма за контакт</h2>

        <div className="contact-form">
          <div className="contacts-page-field">
            <InputField
              type="text"
              label="Име"
              value={name}
              onChange={(e) => { setName(e.target.value); }}
              onBlur={validateName}
              error={errors.name}
              noIcon
            />
          </div>

          <div className="contacts-page-field">
            <InputField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
              onBlur={validateEmail}
              error={errors.email}
              noIcon
            />
          </div>

          <div className="contacts-page-field">
            <InputField
              type="tel"
              label="Телефон"
              value={phone}
              onChange={(e) => { setPhone(e.target.value); }}
              onBlur={validatePhone}
              error={errors.phone}
              noIcon
            />
          </div>

          <div className="contacts-page-field">
            <InputField
              type="text"
              label="Относно"
              value={subject}
              onChange={(e) => { setSubject(e.target.value); }}
              onBlur={validateSubject}
              error={errors.subject}
              noIcon
            />
          </div>

          <div className="contacts-page-field">
            <InputField
              label="Съобщение"
              value={content}
              onChange={(e) => { setContent(e.target.value); }}
              onBlur={validateContent}
              error={errors.content}
              noIcon
              multiline
            />
          </div>

          <div className="contacts-page-field">
            <Checkbox
              label="Съгласявам се данните ми да се обработват за целите на осъществяване на комуникация с Kredy.bg"
              onChange={(isChecked) => { areTermsAcceptedRef.current = isChecked; }}
            />
          </div>

          {hasTermsAndConditionsError && (
            <div className="general-error">
              За да продължите напред, моля, отбележете съгласието си.
            </div>
          )}

          <Button label="Изпрати" theme="primary" disabled={isLoading} onClick={onSendClick} id="submit-contact-page-query" />

          {sendError && (
            <div className="general-error">
              Възникна грешка при изпращането на съобщението
            </div>
          )}
        </div>
      </div>

      {isSentSuccess && (
        <EmailSentPopup
          close={() => { setCloseForm(true); }}
          title="Успешно изпращане"
          text="Вашето съобщение е изпратено успешно"
          email=""
        />
      )}
    </Page>
  );
};

export default ContactsPage;
