import React, { Fragment, useState } from 'react';
import { useIsMobile } from 'hooks';
import { VALID_PROVIDER_IDS_WITH_ICONS } from 'config';
import { formatDate } from 'lib/app';
import { LOAN_TYPES } from 'const';
import SummaryPopup from 'components/SummaryPopup/SummaryPopup';
import EmptyMessage from 'components/EmptyMessage';

const LOAN_LABEL = {
  [LOAN_TYPES.PERSONAL]: 'Потребителски',
  [LOAN_TYPES.MORTGAGE]: 'Ипотечен'
};

const CELL_LABELS = {
  date: 'Дата на подпис',
  request: 'Заявка',
  type: 'Вид кредит',
  amount: 'Сума',
  bank: 'Банка'
};

/**
 * Represents a list of user credits.
 *
 * @param {Array} list: credit list
 * @param {Array} requestList: request list
 * @param {Object} providers: key-value pairs { id: providerName }
 */
const CreditList = ({ creditList, requestList, providers }) => {
  const isMobile = useIsMobile();

  const [summaryData, setSummaryData] = useState(null);

  const openSummary = (request) => {
    setSummaryData(request);
  };

  return (
    <div className="credit-list">

      {creditList.length === 0 ? (
        <EmptyMessage>Все още нямате кредити</EmptyMessage>
      ) : (
        isMobile
          ? (
            <div className="mobile-list">
              {creditList.map((credit) => (
                <div className="credit-box" key={credit.id}>
                  <table border="0" cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr className="heading-cells">
                        <td>{CELL_LABELS.date}</td>
                        <td>{CELL_LABELS.request}</td>
                        <td>{CELL_LABELS.type}</td>
                      </tr>
                      <tr>
                        {renderFirstThreeCells(credit, requestList, openSummary)}
                      </tr>
                      <tr className="heading-cells">
                        <td>{CELL_LABELS.amount}</td>
                        <td>{CELL_LABELS.bank}</td>
                      </tr>
                      <tr>
                        {renderSecondThreeCells(credit, providers)}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : (
            <table className="list" border="0" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th>{CELL_LABELS.date}</th>
                  <th>{CELL_LABELS.request}</th>
                  <th>{CELL_LABELS.type}</th>
                  <th>{CELL_LABELS.amount}</th>
                  <th>{CELL_LABELS.bank}</th>
                </tr>
              </thead>
              <tbody>
                {creditList.map((credit) => (
                  <tr key={credit.id}>
                    {renderFirstThreeCells(credit, requestList, openSummary)}
                    {renderSecondThreeCells(credit, providers)}
                  </tr>
                ))}
              </tbody>
            </table>
          )
      )}

      {summaryData && (
        <SummaryPopup
          request={summaryData}
          close={() => { setSummaryData(null); }}
        />
      )}
    </div>
  );
};

const renderFirstThreeCells = (credit, requestList, openSummary) => {
  const request = requestList.find((req) => req.id === credit.requestId);

  return (
    <>
      <td>{credit.createdTs ? formatDate(credit.createdTs) : ''}</td>
      <td>
        <button type="button" className="link" onClick={() => { openSummary(request); }}>{request.id}</button>
      </td>
      <td>{LOAN_LABEL[request.serviceTypeCode]}</td>
    </>
  );
};

const renderSecondThreeCells = (credit, providers) => (
  <>
    <td>{`${credit.amountInCents / 100} лв.`}</td>
    <td colSpan="2">
      <div className="bank-icon-name">
        {renderProviderIcon(credit.providerId)}
        {providers
          ? providers[credit.providerId]
          : ''
        }
      </div>
    </td>
  </>
);

const renderProviderIcon = (providerId) => (
  <div className="provider">
    {VALID_PROVIDER_IDS_WITH_ICONS.includes(providerId)
      ? <img src={`/img/providers/small/${providerId}.svg`} alt="" />
      : <div className="no-provider">?</div>}
  </div>
);

export default CreditList;
