export const CMS_BASE_URL = window.KREDY_ENVIRONMENT.cmsAPI;

export const LOAN_TYPES = {
  PERSONAL: 'pcr',
  MORTGAGE: 'icr'
};

export const getOppositeLoanType = (code) => (
  code === LOAN_TYPES.PERSONAL
    ? LOAN_TYPES.MORTGAGE
    : LOAN_TYPES.PERSONAL
);

export const LOAN_ATTRIBUTE_NAMES = {
  [LOAN_TYPES.PERSONAL]: {
    currency: 'Валута потребителски',
    amount: 'Сума потребителски',
    period: 'Период потребителски'
  },
  [LOAN_TYPES.MORTGAGE]: {
    currency: 'Валута ипотечен',
    amount: 'Сума ипотечен',
    period: 'Период ипотечен'
  }
};

export const CURRENCY_LABELS = {
  bgn: 'лева',
  eur: 'евро'
};

export const STEP_BEYOND_LAST_NAME = 'Персонална информация';

/**
 * Hardcoded attributes for the Step beyond the last one.
 * Uses the same format as the server.
 * Should the server changes the format, this change must be reflected here.
 */
export const USER_DATA_ATTRIBUTES = [
  {
    displayName: 'Име',
    info: 'Въведете първо име',
    name: 'firstName',
    required: true,
    type: 'STRING'
  },
  {
    displayName: 'Фамилия',
    info: 'Въведете фамилия',
    name: 'lastName',
    required: true,
    type: 'STRING'
  },
  {
    displayName: 'Телефон',
    info: 'Въведете телефон',
    name: 'phone',
    required: true,
    type: 'PHONE'
  },
  {
    displayName: 'ЕГН / ЛНЧ',
    info: 'Въведете ЕГН / ЛНЧ',
    name: 'egn',
    required: true,
    type: 'EGN'
  },
  {
    displayName: 'Съгласявам се личните ми данни да бъдат изпратени към партньорите на kredy.bg за получаване на оферти',
    name: 'tna',
    required: true,
    type: 'BOOLEAN',
    showInSummary: false
  }
];
