import React, { useReducer, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import LeftArrowButton from 'components/ArrowButtons/LeftArrowButton';
import RightArrowButton from 'components/ArrowButtons/RightArrowButton';
import { useResize } from 'hooks';
import './Carousel.scss';

const TRANSITION_DURATION = '300ms';

const DEFAULT_STATE = {
  slidePositions: [],
  isSlideTransitioning: [],
  activeSlide: 0,
  isAutoSlidingActive: true,
  isSwipingStarted: false
};

const ACTIONS = {
  INIT_SLIDE_POSITIONS: 'INIT_SLIDE_POSITIONS',
  GO_TO_PREV_SLIDE: 'GO_TO_PREV_SLIDE',
  GO_TO_NEXT_SLIDE: 'GO_TO_NEXT_SLIDE',
  GO_TO_SLIDE: 'GO_TO_SLIDE',
};

/**
 * Represents a carousel component used to rotate slides.
 * The user can also manually switch between slides by clicking on some of the navigation components.
 *
 * This component is transparent regarding the content it rotates.
 * Each slide should be provided as a child to this component, and only slides can be children. Any single child is concidered a slide.
 * The only requirement for the provided children is that they all have a unique data-key attribute, which is used to set a key attribute for each slide.
 *
 * Usage:
 * <Carousel>
 *   <div data-key="unique-key">This is a slide</div>
 *   <div data-key="another-key">This is another slide</div>
 * </Carousel>
 *
 * @param {React.Component} children: each child represents a slide of the carousel
 */
const Carousel = ({ children }) => {
  const carouselRef = useRef();

  /**
   * Sets carousel height based on the biggest slide.
   */
  const setCarouselHeight = () => {
    if (carouselRef.current) {
      const slides = carouselRef.current.querySelectorAll('[data-slide-index]');

      const maxHeight = Math.max(...Array.prototype.map.call(slides, (slide) => (
        slide.children[0].offsetHeight
      )));

      carouselRef.current.style.height = `${maxHeight + 60}px`;
    }
  };

  useResize(() => {
    setCarouselHeight();
  });

  const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case ACTIONS.INIT_SLIDE_POSITIONS:
        return {
          ...state,
          slidePositions: children.map((child, i) => (
            i < state.activeSlide
              ? '-100%' : i > state.activeSlide
                ? '100%' : 0
          ))
        };

      case ACTIONS.GO_TO_PREV_SLIDE:
        return {
          ...state,
          isSlideTransitioning: children.map((item, i) => (
            i === state.activeSlide || i === state.activeSlide - 1
          )),
          activeSlide: Math.max(0, state.activeSlide - 1)
        };

      case ACTIONS.GO_TO_NEXT_SLIDE:
        return {
          ...state,
          isSlideTransitioning: children.map((item, i) => (
            i === state.activeSlide || i === state.activeSlide + 1
          )),
          activeSlide: Math.min(children.length - 1, state.activeSlide + 1)
        };

      case ACTIONS.GO_TO_SLIDE:
        return {
          ...state,
          isSlideTransitioning: children.map((item, i) => (
            i === state.activeSlide || i === action.slide
          )),
          activeSlide: action.slide
        };

      default:
        return state;
    }
  };

  const [state, changeState] = useReducer(reducer, DEFAULT_STATE);

  useLayoutEffect(() => {
    changeState({ type: ACTIONS.INIT_SLIDE_POSITIONS });
  }, [children.length, state.activeSlide]);

  /**
   * Switches to the previous slide.
   */
  const goToPrevSlide = () => {
    changeState({ type: ACTIONS.GO_TO_PREV_SLIDE });
  };

  /**
   * Switches to the next slide.
   */
  const goToNextSlide = () => {
    changeState({ type: ACTIONS.GO_TO_NEXT_SLIDE });
  };

  /**
   * Switches to the given slide index.
   *
   * @param  {Number} slide: slide index
   */
  const goToSlide = (slide) => {
    changeState({ type: ACTIONS.GO_TO_SLIDE, slide });
  };

  /**
   * Called when the user manually changes to the previous slide.
   */
  const onPrevSlideClick = () => {
    goToPrevSlide();
  };

  /**
   * Called when the user manually changes to the next slide.
   */
  const onNextSlideClick = () => {
    goToNextSlide();
  };

  /**
   * Called when the user manually changes the slide.
   *
   * @param  {Number} slide: slide index
   */
  const onSlideNavigationClick = (slide) => {
    goToSlide(slide);
  };

  /**
   * Indicates if the active slide is the last one.
   *
   * @return {Boolean}
   */
  const isLastSlide = () => (
    state.activeSlide === children.length - 1
  );

  /**
   * Indicates if the active slide is the first one.
   *
   * @return {Boolean}
   */
  const isFirstSlide = () => (
    state.activeSlide === 0
  );

  return (
    <div className="reactive-carousel" ref={carouselRef} data-active-slide={state.activeSlide}>
      <div className="slides">
        {children.map((child, i) => (
          <div
            key={child.props['data-key']}
            data-slide-index={i}
            className="slide"
            style={{
              transform: `translateX(${state.slidePositions[i]})`,
              transitionDuration: `${state.isSlideTransitioning[i] ? TRANSITION_DURATION : '0ms'}`
            }}
          >
            {child}
          </div>
        ))}
      </div>

      <div className="arrow-navigation">
        <LeftArrowButton onClick={onPrevSlideClick} disabled={isFirstSlide()} />
        <RightArrowButton onClick={onNextSlideClick} disabled={isLastSlide()} />
      </div>

      <div className="bottom-navigation dot-navigation">
        {children.map((child, i) => (
          <div
            key={child.props['data-key']}
            className={cn('dot', { active: i === state.activeSlide })}
            onClick={() => { onSlideNavigationClick(i); }}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
