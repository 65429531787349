import React from 'react';
import { connect } from 'react-redux';
import RegistrationEmailSentPopup from './RegistrationEmailSentPopup';
import RegisterPopup from './RegisterPopup';

/**
 * Represents registration UI flow: showing and hiding register and mail sent popups.
 *
 * @param {Boolean}  isRegisterPopupOpen: indicates if the register popup is open
 * @param {String}   messageSentEmail: when non-empty, indicates if the message sent popup is open; also it contains the e-mail of the user
 */
const RegistrationUIFlow = ({ isRegisterPopupOpen, messageSentEmail }) => (
  <>
    {isRegisterPopupOpen && <RegisterPopup />}
    {messageSentEmail && <RegistrationEmailSentPopup email={messageSentEmail} />}
  </>
);

export default connect(
  (state) => ({
    isRegisterPopupOpen: state.ui.createClientOpen,
    messageSentEmail: state.ui.messageSentToEmail
  })
)(RegistrationUIFlow);
