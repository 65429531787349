import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFieldErrors } from 'hooks';
import { isValidEmail } from 'lib';
import { sendForgottenPasswordRequest } from 'store/reducers/user';
import { clearRequest } from 'store/reducers/requestMonitor';
import LABELS from 'labels';
import Popup from 'components/Popup/Popup';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import EmailSentPopup from 'components/EmailSentPopup/EmailSentPopup';
import './ForgottenPasswordPopup.scss';
import withGrecaptcha from 'lib/grecaptcha';

/**
 * A popup for forgotten password.
 *
 * @param {Function} onClose: called when the user closes this component
 * @param {Function} sendRequest: sends the forgotten password request to the server
 * @param {Boolean} isButtonDisabled: indicates if the continue button is disabled
 * @param {Boolean} displayEmailSent: true when the forgotten password request is successful and the email sent popup should be displayed
 * @param {Function} clear: clears the state
 */
const ForgottenPasswordPopup = ({ onClose, sendRequest, isButtonDisabled, displayEmailSent, clear }) => {
  const [email, setEmail] = useState('');

  const { errors, addError, hasErrors, clearErrors } = useFieldErrors();

  useEffect(() => (
    clear()
  ), []);

  const onSubmit = async (e) => {
    e.preventDefault();
    clearErrors();

    !isValidEmail(email) && addError({ email: LABELS.emailInvalid });
    email.length === 0 && addError({ email: LABELS.emailEmpty });

    const hasError = await hasErrors();

    if (!hasError) {
      withGrecaptcha(() => {
        sendRequest({ email });
      });
    }
  };

  return (
    displayEmailSent
      ? (
        <EmailSentPopup close={onClose} text="Изпратихме Ви писмо за смяна на паролата." email={email} />
      ) : (
        <Popup onClose={onClose} title="Забравена парола" className="forgotten-password-popup">
          <form noValidate onSubmit={onSubmit}>
            <InputField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
              error={errors.email}
            />

            <Button type="submit" label="Продължи" theme="primary" disabled={isButtonDisabled} id="forgotten-password-continue" />
          </form>
        </Popup>
      )
  );
};

export default connect(
  (state) => ({
    isButtonDisabled: state.requestMonitor.forgottenPassword === 'pending',
    displayEmailSent: state.requestMonitor.forgottenPassword === 'success'
  }),
  (dispatch) => ({
    sendRequest: (payload) => {
      dispatch(sendForgottenPasswordRequest(payload));
    },
    clear: () => {
      dispatch(clearRequest('forgottenPassword'));
    }
  })
)(ForgottenPasswordPopup);
