import React from 'react';
import Page from 'components/Page/Page';
import Button from 'components/Button/Button';
import errorImage from 'assets/img/404.svg';
import './Error404Page.scss';
import { Helmet } from 'react-helmet';

/**
 * Represents the 404 Page.
 */
const Error404Page = () => (
  <Page className="error-404-page">
    <Helmet>
      <meta name="prerender-status-code" content="404" />
    </Helmet>

    <h2>Грешка</h2>

    <div className="description">
      <p>Страницата, която търсите не съществува или е била преместена.</p>
      <p>Моля, проверете дали сте написали правилно адреса.</p>

      <img src={errorImage} alt="" />

      <Button theme="primary" label="Начало" href="/" id="page-404-dead-end" />
    </div>
  </Page>
);

export default Error404Page;
