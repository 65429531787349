import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { Edit, Success } from 'svg';

/**
 * Represents the change / save text next to the input field.
 */
export default function Action({ onClick, showSave, hasError }) {
  return (
    <div className={cn(styles.action, { [styles.inactive]: hasError && showSave })} onClick={onClick}>
      {showSave ? (
        <>
          <Success />
          <div className={styles.text}>Запази</div>
        </>
      ) : (
        <>
          <Edit />
          <div className={styles.text}>Промени</div>
        </>
      )}
    </div>
  );
}
