import { put } from 'redux-saga/effects';
import { redirectToLogin } from 'store/reducers/loginRedirection';
import { redirectToError } from 'store/reducers/generalError';

const HTTP_UNAUTHORIZED = 401;
const EMAIL_EXISTING = '35';
const TOKEN_EXPIRED = '33';
const TOKEN_INVALID = '32';

/**
 * Handles request failures for sagas.
 *
 * @param {Error} e: can contain result & response fields when this is an HTTP error.
 * @param {Object} options: additional options related to the error
 */
export function* handleRequestFailure(e, options = {}) {
  if (window.reloading) {
    return;
  }

  if (e.response && e.response.status === HTTP_UNAUTHORIZED) {
    delete localStorage.accessToken;
    return;
  }

  if (hasErrorCode(e, EMAIL_EXISTING)) {
    yield put(redirectToLogin({ email: options.email }));
    return;
  }

  if (hasErrorCode(e, TOKEN_EXPIRED) || hasErrorCode(e, TOKEN_INVALID)) {
    yield put(redirectToLogin({ token: options.token }));
    return;
  }

  const { result, response, message, ...exception } = e;

  yield put(redirectToError({
    message,
    exception,
    result,
    response
  }));
}

const hasErrorCode = (e, code) => (
  e.result && e.result.errors && e.result.errors.some((err) => err.code === code)
);
