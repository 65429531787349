import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { useNavigateWhen, useFieldErrors } from 'hooks';
import { PATHS, PASSWORD_MIN_LENGTH } from 'config';
import { verifyClient } from 'store/reducers/user';
import LABELS from 'labels';
import Page from 'components/Page/Page';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import './VerifyAccountPage.scss';

/**
 * Represents the page the user lands on when following the link from the verify account mail.
 *
 * @param {Object} location: react-router's location object
 * @param {Function} submitPassword
 * @param {Boolean} verificationSuccessful: true when the verification process is successful, so this page can navigate to the next one
 * @param {Boolean} isSubmitDisabled: indicates if the submit button is disabled
 */
const VerifyAccountPage = ({ location, submitPassword, verificationSuccessful, isSubmitDisabled }) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const { errors, addError, hasErrors, clearErrors } = useFieldErrors();
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useNavigateWhen({ to: PATHS.REQUEST_CREATION_FLOW, condition: verificationSuccessful });

  if (!token) {
    window.location.href = window.location.origin;
  }

  const createAccount = async (e) => {
    e.preventDefault();
    clearErrors();

    password.length === 0 && addError({ password: LABELS.passwordEmpty });
    repeatPassword.length === 0 && addError({ repeatPassword: LABELS.repeatPasswordEmpty });

    password.length > 0 && password.length < PASSWORD_MIN_LENGTH && addError({ password: LABELS.passwordShort });
    repeatPassword.length > 0 && repeatPassword.length < PASSWORD_MIN_LENGTH && addError({ repeatPassword: LABELS.repeatPasswordShort });

    const hasError = await hasErrors();
    !hasError && password !== repeatPassword && addError({ repeatPassword: LABELS.noMatch });

    if (await hasErrors()) {
      return;
    }

    submitPassword({
      password,
      token
    });
  };

  return (
    <Page className="verify-account-page" clearRequests={['verifyClient']}>
      <div className="verify-account-wrapper">
        <form noValidate className="box" onSubmit={createAccount}>
          <h1>Парола</h1>

          <div className="text">
            <p>Завърши регистрацията с въвеждане на парола</p>
            <p>Моля, въведете парола с минимум 8 символа</p>
          </div>

          <InputField
            type="password"
            label="Парола"
            onChange={(e) => { setPassword(e.target.value); }}
            error={errors.password}
          />

          <InputField
            type="password"
            label="Повтори паролата"
            onChange={(e) => { setRepeatPassword(e.target.value); }}
            error={errors.repeatPassword}
          />

          <Button type="submit" label="Продължи" theme="primary" disabled={isSubmitDisabled} id="verify-account-continue" />
        </form>
      </div>
    </Page>
  );
};

export default withRouter(connect(
  (state) => ({
    verificationSuccessful: state.requestMonitor.verifyClient === 'success',
    isSubmitDisabled: state.requestMonitor.verifyClient === 'pending' || state.requestMonitor.verifyClient === 'success'
  }),
  (dispatch) => ({
    submitPassword: (payload) => {
      dispatch(verifyClient(payload));
    }
  })
)(VerifyAccountPage));
