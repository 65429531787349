import React from 'react';
import styles from './index.module.scss';
import { differenceInMinutes } from 'date-fns';
import { formatDate } from 'lib/app';
import { useHistory } from 'react-router';
import { useMarkAsSeen } from 'hooks';

export default function NotificationList({ data }) {
  const history = useHistory();

  const { markAsSeen } = useMarkAsSeen();

  const getTime = (date) => {
    const minutes = differenceInMinutes(new Date(), new Date(date));

    return minutes < 60
      ? `Преди ${formatMinutes(minutes)}`
      : minutes < 60 * 24
        ? `Преди ${formatHours(parseInt(minutes / 60))}`
        : formatDate(date);
  };

  const formatMinutes = (minutes) => (
    minutes === 1 ? `${minutes} минута` : `${minutes} минути`
  );

  const formatHours = (hours) => (
    hours === 1 ? `${hours} час` : `${hours} часа`
  );

  /**
   * Opens the notification links after marking the notification as seen.
   */
  const openLink = (e, notification) => {
    e.preventDefault();

    markAsSeen(notification.id);

    history.location.pathname === notification.link
      ? window.location.reload()
      : notification.link && history.push(notification.link);
  };

  /**
   * Prevents focus, so the dropdown is not closed when the user right-clicks on the links.
   */
  const preventFocus = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles.title}>Нотификации</div>

      <ul className={styles.list}>
        {data.map((notification) => (
          <li key={notification.id}>
            <a
              href={getNotificationLink(notification)}
              onClick={(e) => { openLink(e, notification); }}
              onMouseDown={preventFocus}
            >
              <div className={styles.notificationTitle}>
                {!notification.seen && (
                  <div className={styles.unseenIndicator} />
                )}

                <span>{notification.title}</span>
              </div>

              <div className={styles.description} dangerouslySetInnerHTML={{ __html: notification.description }} />

              <div className={styles.date}>
                {getTime(notification.createdTs)}
              </div>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}

/**
 * This link will be executed only if the user opens it in a new tab.
 * In that case this function adds ?n=[notificationId] to the URL, so the notification can be marked as seen in the new tab.
 */
const getNotificationLink = (notification) => {
  if (notification.link) {
    const url = new URL(`${window.location.origin}${notification.link}`);
    url.searchParams.set('n', notification.id);
    return url.toString();
  }

  return '';
};
