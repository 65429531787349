import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'serviceTypes',
  initialState: [],
  reducers: {
    fetchServiceTypes: (state) => state,
    setServiceTypes: (state, action) => action.payload
  }
});

export const selectServiceTypeIdByCode = (state, code) => (
  state.find((serviceType) => (
    serviceType.code === code
  )).id
);

export const { fetchServiceTypes, setServiceTypes } = slice.actions;
export default slice.reducer;
