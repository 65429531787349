import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from 'api';
import { fetchOfferFile, setOfferFile } from 'store/reducers/offerFile';
import { failRequest, startRequest, successRequest } from 'store/reducers/requestMonitor';
import { acceptOffer, rejectOffer } from 'store/reducers/offer';
import { setUserRequests } from 'store/reducers/userRequests';
import { handleRequestFailure } from './utils/errors';

function* watchFetchOfferFile() {
  yield takeEvery(fetchOfferFile.type, fetchFile);
}

function* fetchFile({ payload }) {
  try {
    yield put(startRequest('fetchOfferFile'));

    const [result, response] = yield call(api.fetchOfferFile, payload);

    const contentDisposition = response.headers.get('content-disposition');

    yield put(setOfferFile({
      blob: result,
      contentType: response.headers.get('content-type'),
      fileName: contentDisposition && decodeURIComponent(contentDisposition.split('filename*=UTF-8\'\'')[1])
    }));

    yield put(successRequest('fetchOfferFile'));
  } catch (e) {
    yield put(failRequest('fetchOfferFile'));
    yield call(handleRequestFailure, e);
  }
}

function* watchAcceptOffer() {
  yield takeEvery(acceptOffer.type, accept);
}

function* accept({ payload }) {
  try {
    yield put(startRequest('acceptOffer'));

    yield call(api.acceptOffer, payload);

    const [result] = yield call(api.fetchUserRequests);
    yield put(setUserRequests(result));

    yield put(successRequest('acceptOffer'));
  } catch (e) {
    yield put(failRequest('acceptOffer'));
    yield call(handleRequestFailure, e);
  }
}

function* watchRejectOffer() {
  yield takeEvery(rejectOffer.type, reject);
}

function* reject({ payload }) {
  try {
    yield put(startRequest('rejectOffer'));

    yield call(api.rejectOffer, payload);

    const [result] = yield call(api.fetchUserRequests);
    yield put(setUserRequests(result));

    yield put(successRequest('rejectOffer'));
  } catch (e) {
    yield put(failRequest('rejectOffer'));
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchFetchOfferFile,
  watchAcceptOffer,
  watchRejectOffer
];
