const useLastPassEmailValidation = ({ setEmail, validateEmail }) => {
  const lpValidate = () => {
    setTimeout(() => {
      setEmail((state) => {
        validateEmail(state);
        return state;
      });
    }, 500);
  };

  const hasLastPass = () => (
    !!document.querySelector('[name=LPFrame]')
  );

  return { lpValidate, hasLastPass };
};

export default useLastPassEmailValidation;
