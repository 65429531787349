import { createAction, createSlice } from '@reduxjs/toolkit';

export const fetchOfferFile = createAction('offerFile/fetchOfferFile');

const slice = createSlice({
  name: 'offerFile',
  initialState: null,
  reducers: {
    setOfferFile: (state, { payload }) => (
      payload
    ),
    clearOfferFile: () => (
      null
    )
  }
});

export const { setOfferFile, clearOfferFile } = slice.actions;
export default slice.reducer;
