import React from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import Page from 'components/Page/Page';
import Process from './Process/Process';
import PageLoader from 'components/PageLoader/PageLoader';

/**
 * The page responsible for collecting all user data used for requesting a credit.
 *
 * @param {Boolean} isLoading: true if the service types info is still being requested
 */
const RequestCreationFlow = ({ isLoading }) => {
  if (!localStorage.accessToken) {
    window.location.href = PATHS.LOGIN;
  }

  return isLoading ? <Page><PageLoader /></Page> : <Process />;
};

export default connect(
  (state) => ({
    isLoading: state.serviceTypes.length === 0
  })
)(RequestCreationFlow);
