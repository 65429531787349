import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { CURRENCIES } from 'store/reducers/initialLoanDetails';
import InputBoundSlider from './InputBoundSlider';

/**
 * Represents the slider indicating the loan amount.
 *
 * @param {Number}   value
 * @param {Function} onChange
 * @param {String}   currency: bgn / eur: used to change the value and the slider range
 * @param {Object}   rangeConfig: specifies the range (min, max values) of the loan. Contains minValue, maxValue, minLabel, maxLabel
 */
const MoneySlider = ({ value, onChange, currency, rangeConfig }) => {
  const range = rangeConfig[currency];
  const prevCurrency = usePrevious(currency);

  const changesToEuro = () => (currency === CURRENCIES.EUR && prevCurrency === CURRENCIES.BGN);
  const changesToBGN = () => (currency === CURRENCIES.BGN && prevCurrency === CURRENCIES.EUR);

  useEffect(() => {
    if (changesToEuro()) {
      onChange(parseInt(value / 2, 10));
    }

    if (changesToBGN()) {
      onChange(value * 2);
    }
  }, [currency]);

  return (
    <InputBoundSlider
      value={value}
      preInputLabel="Сума"
      postInputLabel={currency === CURRENCIES.BGN ? 'лв.' : 'евро'}
      minValue={range.minValue}
      maxValue={range.maxValue}
      minLabel={range.minLabel}
      maxLabel={range.maxLabel}
      step={1000}
      onChange={onChange}
    />
  );
};

export default MoneySlider;
