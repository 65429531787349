import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { useFieldErrors, useNavigateWhen } from 'hooks';
import { selectRedirectionPathAfterLogin } from 'store/reducers';
import { updatePassword as updatePasswordAction } from 'store/reducers/user';
import LABELS from 'labels';
import Page from 'components/Page/Page';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import './ForgottenPasswordPage.scss';
import withGrecaptcha from 'lib/grecaptcha';

const PASSWORD_MIN_LENGTH = 8;

/**
 * Represents the forgotten password page where the user can update their password.
 *
 * @param {Object} location: from react-router
 * @param {Function} updatePassword: updates the user password on the server
 * @param {String} redirectTo: a link to redirect to when the login is successful, null while the user has not logged in
 * @param {Boolean} isLoading: indicates if the submit button is disabled and a loading indicator is shown
 */
const ForgottenPasswordPage = ({ location, updatePassword, redirectTo, isLoading }) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const { errors, addError, hasErrors, clearErrors } = useFieldErrors();

  useNavigateWhen({
    to: redirectTo,
    condition: !!redirectTo
  });

  const { token } = useMemo(() => (
    qs.parse(location.search, { ignoreQueryPrefix: true })
  ), [location.search]);

  if (!token) {
    window.location.href = window.location.origin;
  }

  const submitNewPassword = async (e) => {
    e.preventDefault();
    clearErrors();

    password.length === 0 && addError({ password: LABELS.passwordEmpty });
    repeatPassword.length === 0 && addError({ repeatPassword: LABELS.repeatPasswordEmpty });

    password.length > 0 && password.length < PASSWORD_MIN_LENGTH && addError({ password: LABELS.passwordShort });
    repeatPassword.length > 0 && repeatPassword.length < PASSWORD_MIN_LENGTH && addError({ repeatPassword: LABELS.repeatPasswordShort });

    const hasError = await hasErrors();
    !hasError && password !== repeatPassword && addError({ repeatPassword: LABELS.noMatch });

    if (await hasErrors()) {
      return;
    }

    withGrecaptcha(() => {
      updatePassword({
        newPassword: password,
        repeatedPassword: repeatPassword,
        token
      });
    });
  };

  return (
    <Page className="forgotten-password-page">
      {isLoading && <Loader />}
      <div className="forgotten-password-wrapper">
        <form noValidate className="box" onSubmit={submitNewPassword}>
          <h1>Парола</h1>

          <div className="text">
            <p>Въведете нова парола</p>
            <p>Моля, въведете парола с минимум 8 символа</p>
          </div>

          <InputField
            type="password"
            label="Парола"
            onChange={(e) => { setPassword(e.target.value); }}
            error={errors.password}
          />

          <InputField
            type="password"
            label="Повтори паролата"
            onChange={(e) => { setRepeatPassword(e.target.value); }}
            error={errors.repeatPassword}
          />

          <Button type="submit" label="Продължи" theme="primary" disabled={isLoading} id="forgotten-password-submit" />
        </form>
      </div>
    </Page>
  );
};

export default withRouter(connect(
  (state) => ({
    isLoading: state.requestMonitor.updatePassword === 'pending' || state.requestMonitor.initRequestInProgress === 'pending',
    redirectTo: selectRedirectionPathAfterLogin(state)
  }),
  (dispatch) => ({
    updatePassword: (payload) => {
      dispatch(updatePasswordAction(payload));
    }
  })
)(ForgottenPasswordPage));
