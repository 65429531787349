import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTooltip from '@material-ui/core/Tooltip';
import Icon from 'components/Icon/Icon';
import { useIsMobile } from 'hooks';
import tooltipIcon from 'assets/img/tooltip.svg';
import './Tooltip.scss';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    display: 'inline-block',
    padding: '12px 14px',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 30px rgba(27, 143, 39, 0.15)',
    backdropFilter: 'blur(10px)',
    borderRadius: '6px',
    fontSize: '14px',
    color: '#444444'
  },
  arrow: {
    color: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 30px rgba(27, 143, 39, 0.15)',
  }
}))(MaterialTooltip);

/**
 * Represents a tooltip.
 *
 * @param {String} text: text of the tooltip
 * @param {Component} children: optional, children to be rendered around the tooltip instead of the default icon
 * @param {Boolean} disable: disables the tooltip
 */
const Tooltip = ({ text, children, disable }) => {
  const isMobile = useIsMobile();

  return (
    !disable
      ? (
        <HtmlTooltip
          title={text}
          arrow
          enterTouchDelay={0}
          leaveTouchDelay={10000}
          {...(isMobile ? {} : { placement: 'right' })}
          disableHoverListener={disable}
          disableTouchListener={disable}
          disableFocusListener={disable}
        >
          {children
            || (
              <div>
                <Icon icon={tooltipIcon} className="tooltip-icon" />
              </div>
            )}
        </HtmlTooltip>
      )
      : (
        children
          || (
            <div>
              <Icon icon={tooltipIcon} className="tooltip-icon" />
            </div>
          )
      )
  );
};

export default Tooltip;
