import { format } from 'date-fns';
import { MOBILE_WIDTH } from 'config';

export const storeAccessToken = (accessToken) => {
  delete localStorage.icrStep;
  delete localStorage.pcrStep;
  localStorage.accessToken = accessToken;
};

export const isMobileWidth = (width) => (
  window.matchMedia(`(max-width: ${width || MOBILE_WIDTH}px)`).matches
);

export const formatDate = (dateString) => (
  format(new Date(dateString), 'dd.MM.yyyy')
);

/**
 * Returns parsed attribute label used for input fields.
 *
 * @param {String} label: a label as it comes from the server
 */
export const getAttributeFieldLabel = (label, { currencyLabel } = {}) => (
  label.replace('{currency}', currencyLabel)
);
