import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import qs from 'qs';
import { clearGeneralErrorRedirection } from 'store/reducers/generalError';
import Page from 'components/Page/Page';
import Button from 'components/Button/Button';
import errorImage from 'assets/img/general-error.svg';
import './ErrorPage.scss';

/**
 * Represents a general error page.
 */
const ErrorPage = ({ location, clearRedirection }) => {
  const { k: errorParam } = qs.parse(location.search, { ignoreQueryPrefix: true });
  // eslint-disable-next-line no-unused-vars
  const errorData = errorParam && JSON.parse(atob(errorParam));

  useEffect(() => {
    clearRedirection();
  }, []);

  return (
    <Page className="error-page">
      <h2>Грешка</h2>

      <div className="description">
        <img src={errorImage} alt="" />

        <p className="error-title">Възникна неочаквана грешка!</p>

        <Button theme="primary" label="Начало" href="/" id="error-page-dead-end" />
      </div>
    </Page>
  );
};

export default withRouter(connect(
  null,
  (dispatch) => ({
    clearRedirection: () => {
      dispatch(clearGeneralErrorRedirection());
    }
  })
)(ErrorPage));
