import React from 'react';
import styles from './index.module.scss';

export default function EmptyMessage({ children }) {
  return (
    <div className={styles.emptyMessage}>
      {children}
    </div>
  );
}
