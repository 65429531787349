import React from 'react';
import cn from 'classnames';
import { STEP_BEYOND_LAST_NAME } from 'const';
import checkIcon from 'assets/img/check.svg';
import './StepIndicator.scss';

/**
 * Represents the step indicator.
 */
const StepIndicator = ({ stepDefinitions, activeStep, onStepChange }) => (
  <div className="step-indicator">
    <IndicatorItem
      definition={{
        number: 0,
        displayName: 'Сума за кандидатстване'
      }}
      isDone={0 < activeStep}
      isActive={0 === activeStep}
      onClick={() => { onStepChange(0); }}
    />

    {stepDefinitions.map((def) => (
      <IndicatorItem
        key={def.number}
        definition={def}
        isDone={def.number < activeStep}
        isActive={def.number === activeStep}
        onClick={() => { onStepChange(def.number); }}
      />
    ))}

    <IndicatorItem
      definition={{
        number: stepDefinitions.length + 1,
        displayName: STEP_BEYOND_LAST_NAME
      }}
      isDone={false}
      isActive={stepDefinitions.length + 1 === activeStep}
      onClick={() => { onStepChange(stepDefinitions.length + 1); }}
    />
  </div>
);

const IndicatorItem = ({ definition, isDone, isActive, onClick }) => (
  <div
    className={cn('step-item', { active: isActive, done: isDone })}
    onClick={onClick}
  >
    <div className="circle-wrapper">
      <div className="circle">
        <div className="circle-text">{definition.number + 1}</div>
        {isDone && (
          <img src={checkIcon} className="checkmark-icon" alt="" />
        )}
      </div>

      <div className="connecting-line" />
    </div>

    <div className="name">{definition.displayName}</div>
  </div>
);

export default StepIndicator;
