import React from 'react';
import { connect } from 'react-redux';
import { useNavigateWhen } from 'hooks';
import { PATHS } from 'config';
import RegistrationUIFlow from 'components/RegisterPopup/RegistrationUIFlow';
import Page from 'components/Page/Page';
import SliderBox from 'components/SliderBox/SliderBox';
import MainBackground from './MainBackground';
import SecondaryBackground from './SecondaryBackground';
import HowItWorks from './HowItWorks';
import News from './News';
import RegisterButton from './RegisterButton';
import Feedback from './Feedback/Feedback';
import RegisterWithEmail from './RegisterWithEmail';
import Faq from './Faq';
import './HomePage.scss';

/**
 * Represents the home page.
 *
 * @param {Boolean} socialLoginSuccessful: indicates that the user has just successfully logged in with a social account.
 */
const HomePage = ({ socialLoginSuccessful }) => {
  useNavigateWhen({ to: PATHS.REQUEST_CREATION_FLOW, condition: socialLoginSuccessful });

  return (
    <Page className="home-page">
      <MainBackground />

      <div className="welcome-section">
        <h1>Изберете своя банков кредит сега!</h1>
        <div className="intro">
          Ние Ви предлагаме най-бързия и лесен начин да получите своята индивидуална оферта от банките в България.
        </div>
        {!localStorage.accessToken && (
          <RegisterButton />
        )}
      </div>

      <SecondaryBackground />
      <SliderBox />
      {/* <Partners /> */}
      <HowItWorks />
      <Faq />
      <News />
      <Feedback />
      {!localStorage.accessToken && (
        <RegisterWithEmail />
      )}

      <RegistrationUIFlow />
    </Page>
  );
};

export default connect(
  (state) => ({
    socialLoginSuccessful: state.requestMonitor.continueWithSocial === 'success'
  })
)(HomePage);
