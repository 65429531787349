import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import { selectAreBothRequestsInNotInitState } from 'store/reducers/requestDraft';
import { PATHS } from 'config';
import Tooltip from 'components/Tooltip/Tooltip';
import logoutIcon from 'assets/img/logout.svg';
import './UserMenuLinks.scss';

const UserMenuLinks = ({ active, disableNewRequest }) => (
  <ul className="user-link-list">
    <li className={cn({ active: active === PATHS.MY_CREDITS })}>
      <Link to={PATHS.MY_CREDITS}>Моите кредити</Link>
    </li>
    <li className={cn({ active: active === PATHS.MY_PROFILE })}>
      <Link to={PATHS.MY_PROFILE}>Моят профил</Link>
    </li>
    <li className={cn({ active: active === PATHS.MY_REQUESTS })}>
      <Link to={PATHS.MY_REQUESTS}>Моите заявки</Link>
    </li>
    <li className={cn({ active: active === PATHS.REQUEST_CREATION_FLOW, disabled: disableNewRequest })}>
      {disableNewRequest
        ? (
          <Tooltip text="Вече имате изпратени заявки">
            <span>Нова заявка</span>
          </Tooltip>
        )
        : (
          <Link to={PATHS.REQUEST_CREATION_FLOW}>Нова заявка</Link>
        )}
    </li>
    <li className="log-out" onClick={logout}>
      <img src={logoutIcon} alt="" className="icon" />
      Изход
    </li>
  </ul>
);

const logout = () => {
  delete localStorage.accessToken;
  window.location.href = '/';
};

export default connect(
  (state) => ({
    disableNewRequest: selectAreBothRequestsInNotInitState(state.requestDraft)
  })
)(UserMenuLinks);
