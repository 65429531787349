import { useMutation, useQueryClient } from 'react-query';
import * as api from 'api';
import useHTTPError from 'hooks/useHTTPError';

const useMarkAsSeen = () => {
  const queryClient = useQueryClient();

  const { mutate, error } = useMutation((id) => (
    api.markNotificationsAsSeen([{ id }])
  ), {
    onSuccess: () => {
      queryClient.invalidateQueries('notification-list');
    },
  });

  useHTTPError(error);

  const markAsSeen = (id) => {
    mutate(id);
  };

  return {
    markAsSeen
  };
};

export default useMarkAsSeen;
