import { createAction, createSlice } from '@reduxjs/toolkit';

export const getOfferRejectFeedbackDefinition = createAction('offerRejectFeedbackefinition/get');

const slice = createSlice({
  name: 'offerRejectFeedbackefinition',
  initialState: [],
  reducers: {
    setOfferRejectFeedbackDefinition: (state, { payload }) => (
      payload
    )
  }
});

export const { setOfferRejectFeedbackDefinition } = slice.actions;
export default slice.reducer;
