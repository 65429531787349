import React from 'react';
import { connect } from 'react-redux';
import { changeCreateClientUI } from 'store/reducers/ui';
import Button from 'components/Button/Button';

const RegisterButton = ({ openPopup }) => (
  <Button label="Регистрация" className="register-button" onClick={openPopup} id="Register" />
);

export default connect(
  null,
  (dispatch) => ({
    openPopup: () => {
      dispatch(changeCreateClientUI({ registerWithStepZero: false }));
    }
  })
)(RegisterButton);
