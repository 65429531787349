import React from 'react';
import loaderIcon from 'assets/img/Spinner-1s-200px.gif';
import './Loader.scss';

const Loader = () => (
  <div>
    <img className="loader" src={loaderIcon} alt="" />
  </div>
);

export default Loader;
