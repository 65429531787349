import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { usePreventGlobalScroll, useStepZeroData } from 'hooks';
import { USER_DATA_ATTRIBUTES, CURRENCY_LABELS } from 'const';
import { getAttributeFieldLabel } from 'lib/app';
import { getStepDefinitions, selectDefinitionByStep } from 'store/reducers/stepDefinitions';
import { getBankData } from 'store/reducers/bankData';
import { getLocationByCode } from 'store/reducers/locationCodes';
import { fetchUserData } from 'store/reducers/user';
import Popup from 'components/Popup/Popup';
import RequestSummary from 'components/RequestSummary/RequestSummary';
import './SummaryPopup.scss';

/**
 * Represents the summary popup on the MyRequests page.
 *
 * @param {Function} init: initializes the state and fetches necessary data if not available
 * @param {Object} request: request data
 * @param {Array} stepsData: an array of attribute data for each step;
 *                  [0]: represents data for step 1,
 *                  [1]: represents data for step 2,
 *                  ...
 * @param {Object} locationCodes: key-value pairs of location-code: location-object
 * @param {Array} bankData: an array of bank data items { code, name }
 * @param {Object} userData: key-value pair of personal user data
 * @param {Function} close: called to close the popup
 */
const SummaryPopup = ({ init, request, stepsData, locationCodes, bankData, userData, close }) => {
  usePreventGlobalScroll();

  useEffect(() => {
    init();
  }, []);

  const stepZeroData = useStepZeroData(request);

  return (
    <Popup onClose={close} title={`Oбобщение заявка № ${request.id}`} className="summary-popup">
      <RequestSummary
        stepZeroData={stepZeroData}
        stepsData={stepsData}
        locationCodes={locationCodes}
        bankData={bankData}
        userData={USER_DATA_ATTRIBUTES.map((attr) => ({
          ...attr,
          value: userData[attr.name]
        }))}
        getFieldLabel={(label) => (
          getAttributeFieldLabel(label, {
            currencyLabel: CURRENCY_LABELS[stepZeroData.currency.toLowerCase()]
          })
        )}
      />
    </Popup>
  );
};

export default connect(
  (state, ownProps) => ({
    stepsData: state.stepDefinitions[ownProps.request.serviceTypeCode]
      ? getStepsData(ownProps.request, state.stepDefinitions)
      : [],
    locationCodes: state.locationCodes,
    bankData: state.bankData.data,
    userData: state.user
  }),
  (dispatch, ownProps) => ({
    init: () => {
      dispatch(getStepDefinitions(ownProps.request.serviceTypeCode));
      dispatch(getBankData());
      dispatch(fetchUserData());

      const locationAttribute = ownProps.request.attributes.find((attr) => attr.type === 'LOCATION');

      if (locationAttribute) {
        dispatch(getLocationByCode(locationAttribute.value));
      }
    }
  })
)(SummaryPopup);

/**
 * Returns stepsData based on the given request with attribute values and stepDefinitions with attribute definitions.
 *
 * @param {Object} request: user request
 * @param {Array} stepDefinitions: request step definitions
 * @return {Array} stepsData: an array of attribute data for each step;
 *                  [0]: represents data for step 1,
 *                  [1]: represents data for step 2,
 *                  ...
 */
const getStepsData = (request, stepDefinitions) => {
  const stepsData = [];
  let currentStep = 1;

  let def = selectDefinitionByStep(stepDefinitions, currentStep, request.serviceTypeCode);

  while (def) {
    const stepAttributes = def.attributes.map((defAttr) => ({
      ...defAttr,
      ...request.attributes.find((reqAttr) => (
        reqAttr.name === defAttr.name
      ))
    }));

    // filter out attributes with boolean parents having value of false
    const attributes = stepAttributes.filter((attribute) => {
      if (attribute.parentName) {
        const parentAttribute = stepAttributes.find((attr) => (
          attr.name === attribute.parentName
        ));

        if (!parentAttribute.value) {
          return false;
        }
      }

      return true;
    });

    stepsData.push({
      stepName: def.displayName,
      attributes
    });

    currentStep += 1;
    def = selectDefinitionByStep(stepDefinitions, currentStep, request.serviceTypeCode);
  }

  return stepsData;
};
