import { useQuery } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

const useNotificationPreference = () => {
  const { isLoading, error, data } = useQuery(
    'notification-preference',
    () => (
      api.fetchNotificationPreference()
    )
  );

  useHTTPError(error);

  return {
    isLoading,
    data: data && data[0]
  };
};

export default useNotificationPreference;
