import { connect as connectRedux } from 'react-redux';
import { continueWithSocial } from 'store/reducers/user';

const SocialContainer = connectRedux(
  null,
  (dispatch, ownProps) => ({
    login: (payload) => {
      dispatch(continueWithSocial({
        ...payload,
        isLogin: ownProps.isLogin
      }));
    }
  })
);

export default SocialContainer;
