import { combineReducers } from 'redux';
import { LOAN_TYPES } from 'const';
import { PATHS } from 'config';
import { isAttributeError } from './util';
import serviceTypes from './serviceTypes';
import initialLoanDetails from './initialLoanDetails';
import ui from './ui';
import requestMonitor from './requestMonitor';
import stepDefinitions, * as fromStepDefinitions from './stepDefinitions';
import requestDraft, * as fromRequestDraft from './requestDraft';
import stepErrorFields from './stepErrorFields';
import searchLocationResults from './searchLocationResults';
import locationCodes from './locationCodes';
import loginRedirection from './loginRedirection';
import bankData from './bankData';
import userRequests from './userRequests';
import user, * as fromUser from './user';
import userDraft from './userDraft';
import userDraftErrorFields, * as fromUserDraftErrorFields from './userDraftErrorFields';
import providers from './providers';
import offerFile from './offerFile';
import offerRejectFeedbackDefinition from './offerRejectFeedbackDefinition';
import wrongCredentials from './wrongCredentials';
import generalError from './generalError';
import staticPages from './staticPages';

export default combineReducers({
  serviceTypes,
  initialLoanDetails,
  ui,
  requestMonitor,
  stepDefinitions,
  requestDraft,
  stepErrorFields,
  searchLocationResults,
  locationCodes,
  loginRedirection,
  bankData,
  userRequests,
  user,
  userDraft,
  userDraftErrorFields,
  providers,
  offerFile,
  offerRejectFeedbackDefinition,
  wrongCredentials,
  generalError,
  staticPages
});

/**
 * Selects the list of all step definitions for the currently selected service type (pcr or icr).
 *
 * @param {Object} state: redux state
 */
export const selectRequestDraftStepDefinitionList = (state) => (
  state.stepDefinitions[state.requestDraft.serviceTypeCode || LOAN_TYPES.PERSONAL]
);

/**
 * Selects a single step definition by its number for the currently selected service type (pcr or icr).
 *
 * @param {Object} state: redux state
 * @param {Number} stepNumber: the index of the step in the list of step definitions
 */
export const selectRequestDraftStepDefinition = (state, stepNumber) => (
  fromStepDefinitions.selectDefinitionByStep(state.stepDefinitions, stepNumber, state.requestDraft.serviceTypeCode || LOAN_TYPES.PERSONAL)
);

/**
 * Selects attributes per the given step, which can be saved on the server.
 * An additional boolean/string error field is added to the object indicating if the value is correct.
 *
 * @param {Object} state: redux state
 * @param {Number} stepNumber: step index in the list of steps
 * @return {Array} an array of attributes { name, type, value, error }
 */
export const selectRequestAttributesPerStep = (state, stepNumber) => {
  const serviceType = state.requestDraft.serviceTypeCode;

  const { attributes: attributeDefinitions } = fromStepDefinitions.selectDefinitionByStep(state.stepDefinitions, stepNumber, serviceType);
  const allAttributes = fromRequestDraft.selectCurrentAttributes(state.requestDraft);

  if (!allAttributes) {
    return [];
  }

  const attributes = attributeDefinitions.map((definition) => {
    const valueAttr = allAttributes.find((item) => item.name === definition.name);

    return {
      ...definition,
      error: isAttributeError({ definition, attribute: valueAttr }),
      value: definition.type === 'BOOLEAN' ? !!(valueAttr && valueAttr.value) : valueAttr && valueAttr.value
    };
  });

  // filter out attributes with boolean parents having value of false
  return attributes.filter((attribute) => {
    if (attribute.parentName) {
      const parentAttribute = attributes.find((attr) => (
        attr.name === attribute.parentName
      ));

      if (!parentAttribute.value) {
        return false;
      }
    }

    return true;
  });
};

/**
 * Selects an attribute data by the given attribute name.
 * An additional boolean/string error field is added to the object indicating if the value is correct.
 *
 * @param {Object} state: redux state
 * @param {String} name: attribute name
 * @param {Number} number: step number related to the attribute
 * @return {Object} attribute data { name, type, value, error }
 */
export const selectRequestAttributeByName = (state, { name, number }) => {
  const attribute = fromRequestDraft.selectAttributeByName(state.requestDraft, name);

  const definition = fromStepDefinitions.selectDefinitionByAttrName(state.stepDefinitions, {
    step: number,
    serviceType: state.requestDraft.serviceTypeCode,
    attrName: name
  });

  return {
    name: definition.name,
    type: definition.type,
    error: isAttributeError({ definition, attribute }),
    value: attribute && attribute.value
  };
};

/**
 * Returns the correct navigation path after the user logs in.
 *
 * @param {Object} state: redux state
 */
export const selectRedirectionPathAfterLogin = (state) => (
  state.requestMonitor.initRequestInProgress === 'success'
    ? window.location.search && window.location.pathname !== PATHS.LOGIN
      ? `${window.location.pathname}${window.location.search}`
      : state.requestDraft.requests.length === 0
        ? '/'
        : fromRequestDraft.selectHasRequestNotInInitState(state.requestDraft)
          ? PATHS.MY_REQUESTS
          : PATHS.REQUEST_CREATION_FLOW
    : null
);

/**
 * Checks whether the user data step can be saved (there are no errors).
 *
 * @param {Object} state: redux state
 */
export const selectCanSaveUserDataStep = (state) => (
  fromUserDraftErrorFields.selectCanSaveUserDataStep(state.userDraftErrorFields)
);

/**
 * Checks whether the initial loan details data is loaded.
 *
 * @param {Object} state: redux state
 */
export const selectIsInitialLoanDataLoaded = (state) => {
  if (!fromUser.selectIsUserLoggedIn(state.user) && !localStorage.accessToken) {
    return true; // data is always considered loaded for guests
  }

  return state.requestDraft.isDataLoaded;
};
