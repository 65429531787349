import React from 'react';
import cn from 'classnames';
import Button from 'components/Button/Button';
import CurrencyToggle from './CurrencyToggle';
import MoneySlider from './MoneySlider';
import YearSlider from './YearSlider';
import Tooltip from 'components/Tooltip/Tooltip';
import './TabContent.scss';

/**
 * Represents a tab content inside the SliderBox.
 *
 * @param {Object}  data: content data:
 *                          - currency: bgn / eur
 *                          - amount
 *                          - period
 * @param {Boolean}   isHidden: true when the tab is not active
 * @param {Function}  changeAmount: changes the amount acording to the provided value
 * @param {Function}  changePeriod: changes the period acording to the provided value
 * @param {Function}  changeCurrency: changes the currency acording to the provided value
 * @param {Function}  onSubmit: called when the submit button is pressed
 * @param {Boolean}   isSubmitDisabled: indicates that the submit button is disabled
 * @param {Object}    moneyRangeConfig: specifies the money range (min, max values) of the loan. Contains minValue, maxValue, minLabel, maxLabel
 * @param {Object}    yearRangeConfig: specifies the time range (min, max values) of the loan. Contains minValue, maxValue, minLabel, maxLabel
 */
const TabContent = ({ data, isHidden, changeAmount, changePeriod, changeCurrency, onSubmit, isSubmitDisabled, moneyRangeConfig, yearRangeConfig }) => (
  <div className={cn('slider-box-tab-content', { hidden: isHidden })}>
    <div className="header">
      <div className="title">Колко пари Ви трябват?</div>
      <CurrencyToggle currency={data.currency} onChange={changeCurrency} />
    </div>

    <MoneySlider value={data.amount} onChange={changeAmount} currency={data.currency} rangeConfig={moneyRangeConfig} />
    <YearSlider value={data.period} onChange={changePeriod} rangeConfig={yearRangeConfig} />

    <div className="button-wrapper">
      <Tooltip
        text="Вече имате изпратени заявки"
        disable={!isSubmitDisabled}
      >
        <div>
          <Button
            id="submit-initial-credit-data"
            label="Напред"
            theme="primary"
            visuallyDisabled={isSubmitDisabled}
            {...(isSubmitDisabled
              ? {}
              : { onClick: onSubmit }
            )}
          />
        </div>
      </Tooltip>
    </div>
  </div>
);

export default TabContent;
