/**
 * Downloads the given blob file.
 *
 * @param  {Blob} blob: represents the file
 * @param  {String} fileName
 */
export const downloadFile = ({ blob, fileName }) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
