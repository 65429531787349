import { createAction, createSlice } from '@reduxjs/toolkit';
import { changeRequestDraftAttributeValue } from './requestDraft';

export const getLocationByCode = createAction('locationCodes/getLocationByCode');

const slice = createSlice({
  name: 'locationCodes',
  initialState: {},
  reducers: {
    addLocationData: (state, { payload }) => {
      state[payload.code] = payload;
    }
  },
  extraReducers: {
    [changeRequestDraftAttributeValue]: (state, { payload }) => {
      if (payload.type === 'LOCATION' && payload.location) {
        state[payload.location.code] = payload.location;
      }
    }
  }
});

export const { addLocationData } = slice.actions;
export default slice.reducer;
