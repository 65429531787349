import { createSlice } from '@reduxjs/toolkit';
import { setRequestData, setRequestDraftServiceTypeCode } from './requestDraft';
import { LOAN_ATTRIBUTE_NAMES, LOAN_TYPES, CURRENCY_LABELS } from 'const';

export const CURRENCIES = {
  BGN: 'bgn',
  EUR: 'eur'
};

const DEFAULT_CURRENCY = CURRENCIES.BGN;
const DEFAULT_AMOUNT = 52000;
const DEFAULT_AMOUNT_MORTGAGE = 150000;
const DEFAULT_PERIOD = 10;

/**
 * Keeps the state of the initial loan details (slider box)
 */
const slice = createSlice({
  name: 'initialLoanDetails',
  initialState: {
    type: LOAN_TYPES.PERSONAL,
    [LOAN_TYPES.PERSONAL]: {
      currency: DEFAULT_CURRENCY,
      amount: DEFAULT_AMOUNT,
      period: DEFAULT_PERIOD
    },
    [LOAN_TYPES.MORTGAGE]: {
      currency: DEFAULT_CURRENCY,
      amount: DEFAULT_AMOUNT_MORTGAGE,
      period: DEFAULT_PERIOD
    }
  },
  reducers: {
    changeType: (state, action) => ({
      ...state,
      type: action.payload
    }),
    changeAmount: (state, action) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        amount: action.payload.amount
      }
    }),
    changePeriod: (state, action) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        period: action.payload.period
      }
    }),
    changeCurrency: (state, action) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        currency: action.payload.currency
      }
    })
  },
  extraReducers: {
    // sets the slider box's state as a response of the request-in-progress request
    [setRequestData]: (state, action) => {
      const personalData = action.payload.find((array) => (
        array.serviceTypeCode === LOAN_TYPES.PERSONAL
      ));

      const mortgateData = action.payload.find((array) => (
        array.serviceTypeCode === LOAN_TYPES.MORTGAGE
      ));

      if (personalData) {
        const attrNames = LOAN_ATTRIBUTE_NAMES[LOAN_TYPES.PERSONAL];
        const currency = getAttributeValue(personalData, attrNames.currency);

        state[LOAN_TYPES.PERSONAL] = {
          currency: currency ? currency.toLowerCase() : DEFAULT_CURRENCY,
          amount: getAttributeValue(personalData, attrNames.amount) || DEFAULT_AMOUNT,
          period: getAttributeValue(personalData, attrNames.period) || DEFAULT_PERIOD
        };
      }

      if (mortgateData) {
        const attrNames = LOAN_ATTRIBUTE_NAMES[LOAN_TYPES.MORTGAGE];
        const currency = getAttributeValue(mortgateData, attrNames.currency);

        state[LOAN_TYPES.MORTGAGE] = {
          currency: currency ? currency.toLowerCase() : DEFAULT_CURRENCY,
          amount: getAttributeValue(mortgateData, attrNames.amount) || DEFAULT_AMOUNT,
          period: getAttributeValue(mortgateData, attrNames.period) || DEFAULT_PERIOD
        };
      }
    },
    [setRequestDraftServiceTypeCode]: (state, action) => {
      if (action.payload) {
        state.type = action.payload;
      }
    }
  }
});

const getAttributeValue = (serviceTypeData, attrName) => {
  const attr = serviceTypeData.attributes.find((item) => (
    item.name === attrName
  ));

  return attr && attr.value;
};

export const selectLoanDetails = (state) => ({
  type: state.type,
  ...state[state.type]
});

/**
 * Selects loan attributes as defined by the back-end.
 * The returned array is saveable on the server.
 *
 * @param {Object} state: this reducer's state
 * @return {Array}
 */
export const selectLoanAttributes = (state) => {
  const loanDetails = selectLoanDetails(state);

  return [
    {
      name: LOAN_ATTRIBUTE_NAMES[loanDetails.type].currency,
      type: 'ENUM',
      value: loanDetails.currency.toUpperCase()
    },
    {
      name: LOAN_ATTRIBUTE_NAMES[loanDetails.type].period,
      type: 'NUMBER',
      value: loanDetails.period
    },
    {
      name: LOAN_ATTRIBUTE_NAMES[loanDetails.type].amount,
      type: 'NUMBER',
      value: loanDetails.amount
    }
  ];
};

export const selectCurrencyLabel = (state) => (
  CURRENCY_LABELS[selectLoanDetails(state).currency]
);

export const { changeType, changeAmount, changePeriod, changeCurrency } = slice.actions;
export default slice.reducer;
