import React from 'react';
import useNotificationList from './useNotificationList';
import MobileNotificationIcon from './MobileNotificationIcon';
import DesktopNotificationIcon from './DesktopNotificationIcon';

export default function NotificationIcon() {
  const { data } = useNotificationList();

  return (
    <>
      <DesktopNotificationIcon
        data={data}
      />

      <MobileNotificationIcon
        data={data}
      />
    </>
  );
}
