import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'searchLocationResults',
  initialState: [],
  reducers: {
    searchLocations: (state) => state,
    setSearchLocationResults: (state, action) => action.payload
  }
});

export const { searchLocations, setSearchLocationResults } = slice.actions;
export default slice.reducer;
