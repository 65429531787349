import { takeEvery, call, put, select } from 'redux-saga/effects';
import { handleRequestFailure } from './utils/errors';
import { getBankData, setBankData } from 'store/reducers/bankData';
import * as api from 'api';

function* watchGetBankData() {
  yield takeEvery(getBankData.type, checkFetchBankData);
}

function* checkFetchBankData() {
  const existingData = yield select((state) => state.bankData.data);

  if (existingData.length > 0) {
    return;
  }

  try {
    const [bankData] = yield call(api.fetchBankData);
    yield put(setBankData(bankData));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchGetBankData
];
