import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fetchServiceTypes } from 'store/reducers/serviceTypes';
import { fetchUserData } from 'store/reducers/user';
import { initRequestInProgress } from 'store/reducers/requestDraft';
import Routes from 'pages/Routes';
import './App.scss';
import { fetchStaticPages } from 'store/reducers/staticPages';

const App = ({ init }) => {
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener('storage', () => {
      if (localStorage.accessToken) {
        init();
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Kredy.bg</title>
      </Helmet>
      <Routes />
    </>
  );
};

export default connect(
  null,
  (dispatch) => ({
    init: () => {
      dispatch(fetchServiceTypes());
      dispatch(fetchStaticPages());

      if (localStorage.accessToken) {
        dispatch(fetchUserData());
        dispatch(initRequestInProgress());
      }
    }
  })
)(App);
