import React from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import './ProfilePage.scss';
import SideMenuPage from 'components/SideMenuPage/SideMenuPage';
import UserDetails from './UserDetails';
import ChangePassword from './ChangePassword';
import Notifications from './Notifications';

/**
 * Represents user profile page.
 *
 * @param {Boolean} isLoading: indicates if the data is still being fetched
 */
const ProfilePage = ({ isLoading }) => (
  <SideMenuPage
    isLoading={isLoading}
    activePath={PATHS.MY_PROFILE}
  >
    <h1>Моят профил</h1>

    <div className="profile-page">
      <UserDetails />
      <ChangePassword />
      <Notifications />
    </div>
  </SideMenuPage>
);

export default connect(
  (state) => ({
    isLoading: !state.user.email
  })
)(ProfilePage);
