import React from 'react';
import star from 'assets/img/star.svg';

const Stars = ({ count }) => (
  <div className="star-list">
    {Array(count).fill(null).map(() => (
      <img src={star} alt="" key={Math.random()} />
    ))}
  </div>
);

export default Stars;
