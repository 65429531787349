import { takeEvery, call, put } from 'redux-saga/effects';
import { fetchServiceTypes, setServiceTypes } from 'store/reducers/serviceTypes';
import * as api from 'api';
import { handleRequestFailure } from './utils/errors';

function* watchFetchServiceTypes() {
  yield takeEvery(fetchServiceTypes.type, fetchList);
}

export function* fetchList() {
  try {
    const [result] = yield call(api.getServiceTypes);
    yield put(setServiceTypes(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchFetchServiceTypes
];
