import { useMutation } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

const useUpdatePreference = () => {
  const { mutate, error, isLoading } = useMutation((data) => (
    api.changeNotificationPreference(data)
  ));

  useHTTPError(error);

  const updatePreference = (data) => {
    mutate(data);
  };

  return {
    isUpdating: isLoading,
    updatePreference
  };
};

export default useUpdatePreference;
