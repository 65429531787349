import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createClient } from 'store/reducers/user';
import { changeCreateClientUI } from 'store/reducers/ui';
import { useFieldErrors, useBulgarianPhone } from 'hooks';
import { CODE } from 'hooks/useBulgarianPhone';
import { isValidEmail, preloadImage } from 'lib';
import LABELS from 'labels';
import Popup from 'components/Popup/Popup';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import OrSeparator from 'components/OrSeparator/OrSeparator';
import GoogleButton from 'components/SocialButton/GoogleButton';
import FacebookButton from 'components/SocialButton/FacebookButton';
import Loader from 'components/Loader/Loader';
import mailImage from 'assets/img/mail-sent.svg';
import './RegisterPopup.scss';
import withGrecaptcha from 'lib/grecaptcha';
import AcceptTncCheckbox from 'components/AcceptTncCheckbox/AcceptTncCheckbox';

/**
 * A popup for register purposes.
 *
 * @param {Function} close: closes this component
 * @param {Function} register: register the user on the server
 * @param {Boolean}  isLoading: indicates if the create client request is in progress
 */
const RegisterPopup = ({ close, register, isLoading }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone, hasPhoneError] = useBulgarianPhone();
  const areTermsAcceptedRef = useRef();
  const [hasTermsAndConditionsError, setHasTermsAndConditionsError] = useState(false);

  const { errors, addError, hasErrors, clearFieldError, clearErrors } = useFieldErrors();

  useEffect(() => (
    close
  ), []);

  useEffect(() => {
    preloadImage(mailImage);
  }, []);

  const onRegisterClick = async () => {
    clearErrors();

    validateEmail();
    validatePhone();

    const hasError = await hasErrors();
    setHasTermsAndConditionsError(!areTermsAcceptedRef.current);

    if (areTermsAcceptedRef.current && !hasError) {
      withGrecaptcha(() => {
        register({
          email,
          phone: phone === CODE ? null : phone
        });
      });
    }
  };

  const validateEmail = () => {
    !isValidEmail(email) && addError({ email: LABELS.emailInvalid });
    email.length === 0 && addError({ email: LABELS.emailEmpty });

    if (isValidEmail(email)) {
      clearFieldError('email');
    }
  };

  const validatePhone = () => {
    hasPhoneError
      ? addError({ phone: LABELS.phoneInvalid })
      : clearFieldError('phone');
  };

  return (
    <Popup onClose={close} title="Регистрация" className="register-popup">
      {isLoading && <Loader />}
      <InputField
        type="email"
        label="E-mail"
        value={email}
        onChange={(e) => { setEmail(e.target.value); }}
        onBlur={validateEmail}
        error={errors.email}
      />

      <InputField
        type="tel"
        label="Вашият телефон"
        isMandatory={false}
        value={phone}
        onChange={(e) => { setPhone(e.target.value); }}
        onBlur={validatePhone}
        error={errors.phone}
      />

      <AcceptTncCheckbox
        onChange={(isChecked) => { areTermsAcceptedRef.current = isChecked; }}
        showError={hasTermsAndConditionsError}
      />

      <Button label="Продължи" theme="primary" disabled={isLoading} onClick={onRegisterClick} id="continue-registration" />

      <OrSeparator />

      <GoogleButton label="Продължи с Google" />
      <FacebookButton label="Продължи с Facebook" />

      <div className="already-registered details">
        Вече имате регистрация? Влезте от <Link to="/login">тук</Link>.
      </div>
    </Popup>
  );
};

export default connect(
  (state) => ({
    isLoading: state.requestMonitor.createClient === 'pending'
  }),
  (dispatch) => ({
    register: (payload) => {
      dispatch(createClient(payload));
    },
    close: () => {
      dispatch(changeCreateClientUI(false));
    }
  })
)(RegisterPopup);
