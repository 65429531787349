import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';

/**
 * The menu list of links.
 *
 * @param props
 */
const MenuLinks = ({ ...props }) => (
  <ul className="menu-link-list" {...props}>
    <li>
      <a href="/">Поискай оферта</a>
    </li>
    <li>
      <Link to={PATHS.ABOUT_US}>За нас</Link>
    </li>
    <li>
      <a href="/#how-it-works-section">Как работи Kredy?</a>
    </li>
    <li>
      <Link to={PATHS.NEWS}>Новини</Link>
    </li>
    <li>
      <Link to={PATHS.CONTACTS}>Контакти</Link>
    </li>
  </ul>
);

export default MenuLinks;
