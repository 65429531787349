import React from 'react';
import SocialContainer from './SocialContainer';
import facebookIcon from 'assets/img/facebook-circle.svg';
import SocialButton from './SocialButton';

/**
 * Represents a Facebook button and encapsulates facebook login functionality.
 */
const FacebookButton = ({ label, login }) => {
  const handleFacebookLogin = () => {
    FB.login((response) => { // eslint-disable-line no-undef
      if (response.status === 'connected') {
        login({
          idToken: response.authResponse.accessToken,
          socialType: 'FACEBOOK'
        });
      }
    },
    { scope: 'public_profile,email' });
  };

  return (
    <SocialButton icon={facebookIcon} label={label} onClick={handleFacebookLogin} />
  );
};

export default SocialContainer(FacebookButton);
