import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import './Label.scss';

/**
 * Represents a label in the context of the request creation flow.
 *
 * @param {String} label: text label
 * @param {String} htmlId: optional htmlId to match this Label to an input field
 * @param {Boolean} isMandatory: indicates if the value selection is mandatory
 * @param {String} tooltipText: help tooltip displayed next to the component
 */
const Label = ({ label, htmlId, isMandatory, tooltipText }) => (
  label ? (
    <label className="step-field-label" htmlFor={htmlId}>
      {label}
      {isMandatory && (
        <span className="mandatory-indicator"> *</span>
      )}
      {tooltipText && (
        <Tooltip text={tooltipText} />
      )}
    </label>
  ) : null
);

export default Label;
