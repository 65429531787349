import { createSlice } from '@reduxjs/toolkit';
import { successRequest } from './requestMonitor';

const slice = createSlice({
  name: 'ui',
  initialState: {
    createClientOpen: false,
    messageSentToEmail: false,
    registerWithStepZero: undefined
  },
  reducers: {
    changeCreateClientUI: (state, action) => ({
      ...state,
      createClientOpen: !!action.payload,
      registerWithStepZero: action.payload && action.payload.registerWithStepZero
    }),
    changeCreateClientEmailSentUI: (state, action) => ({
      ...state,
      messageSentToEmail: action.payload
    })
  },
  extraReducers: {
    [successRequest]: (state, action) => {
      if (action.payload === 'continueWithSocial') {
        state.createClientOpen = false;
      }
    }
  }
});

export const { changeCreateClientUI, changeCreateClientEmailSentUI } = slice.actions;
export default slice.reducer;
