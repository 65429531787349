import { useQuery } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

/**
 * Retrieves a single news by canonical slug.
 */
const useSingleNews = (canonical) => {
  const { isLoading, error, data } = useQuery(
    ['news-single', canonical],
    () => (
      canonical && api.fetchSingleNews(canonical)
    )
  );

  useHTTPError(error);

  return {
    isLoading,
    result: data && data[0] && data[0][0] // query by canonical returns an array of news instead of a single news
  };
};

export default useSingleNews;
