import { createSlice, createAction } from '@reduxjs/toolkit';

export const checkStepAttributeValueForError = createAction('stepErrorFields/checkStepAttributeValueForError');

/**
 * Keeps the field names of the request creation flow that has resulted in an error.
 * The key is the field name.
 * The value of the key is true if the error is the default one (value missing) or an error message otherwise.
 */
const slice = createSlice({
  name: 'stepErrorFields',
  initialState: {},
  reducers: {
    setStepErrorFields: (state, { payload }) => (
      payload.forEach((item) => {
        state[item.name] = item.error;
      })
    ),
    addStepErrorField: (state, { payload }) => {
      state[payload.name] = payload.error;
    },
    removeStepErrorField: (state, { payload }) => {
      delete state[payload];
    },
    clearStepErrorFields: () => (
      {}
    )
  }
});

export const { setStepErrorFields, addStepErrorField, removeStepErrorField, clearStepErrorFields } = slice.actions;
export default slice.reducer;
