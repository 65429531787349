import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'requestMonitor',
  initialState: {},
  reducers: {
    startRequest: (state, action) => ({
      ...state,
      [action.payload]: 'pending'
    }),
    successRequest: (state, action) => ({
      ...state,
      [action.payload]: 'success'
    }),
    failRequest: (state, action) => ({
      ...state,
      [action.payload]: 'fail'
    }),
    clearRequest: (state, action) => {
      delete state[action.payload]; /* eslint-disable-line no-param-reassign */
    },
    clearAllRequests: () => (
      {}
    )
  }
});

export const { startRequest, successRequest, failRequest, clearRequest, clearAllRequests } = slice.actions;
export default slice.reducer;
