import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from 'api';
import { addProviderData, fetchProviderData } from 'store/reducers/providers';
import { handleRequestFailure } from './utils/errors';

function* watchFetchProviderData() {
  yield takeEvery(fetchProviderData.type, fetch);
}

function* fetch({ payload }) {
  try {
    const [result] = yield call(api.fetchProviderData, payload);
    yield put(addProviderData(result));
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchFetchProviderData
];
