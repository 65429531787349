import React, { useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import MobileNotificationDropdown from './MobileNotificationDropdown';
import NotificationIconUI from './NotificationIconUI';

export default function MobileNotificationIcon({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(styles.icon, styles.mobile, { [styles.unseen]: data && data.totalUnseen > 0 })}
      onClick={() => { setIsOpen(true); }}
    >
      <NotificationIconUI data={data} />

      {isOpen && (
        <MobileNotificationDropdown
          onClose={() => { setIsOpen(false); }}
          data={data.notifications}
        />
      )}
    </div>
  );
}
