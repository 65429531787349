import { select, take } from 'redux-saga/effects';

/**
 * Waits for a specific state to be in the specified condition before proceeding.
 * Call like that: yield waitForState({ ... });
 *
 * @param {String} actionType: action.type
 * @param {Function} selector: function to select part of the state
 * @param {Function} waitUntil: function returning the condition the selector's result needs to meed in order to stop the waiting process
 */
function* waitForState({ actionType, selector, waitUntil }) {
  let result = yield select(selector);

  if (!waitUntil(result)) {
    while (true) {
      yield take(actionType);
      result = yield select(selector);

      if (waitUntil(result)) {
        break;
      }
    }
  }
}

export default waitForState;
