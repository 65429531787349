import React from 'react';
import Page from 'components/Page/Page';
import PageLoader from 'components/PageLoader/PageLoader';
import UserMenuLinks from 'components/UserMenuLinks/UserMenuLinks';
import LoginPage from 'pages/LoginPage/LoginPage';
import './SideMenuPage.scss';

/**
 * Represents a page with a left menu.
 *
 * @param {Boolean} isLoading: indicates if the data necessary to display the page is still being loaded
 * @param {String}  activePath: active page path used to highlight a specific menu item
 * @param {Component} children: page content
 */
const SideMenuPage = ({ isLoading, activePath, children }) => {
  if (!localStorage.accessToken) {
    return <LoginPage />;
  }

  return (
    <Page className="side-menu-page">
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="page-wrapper">
          <div className="menu">
            <UserMenuLinks active={activePath} />
          </div>

          <div className="main">
            {children}
          </div>
        </div>
      )}
    </Page>
  );
};

export default SideMenuPage;
