import { connect } from 'react-redux';
import { changeCreateClientEmailSentUI } from 'store/reducers/ui';
import EmailSentPopup from 'components/EmailSentPopup/EmailSentPopup';

/**
 * Represents the email sent pop-up.
 */
export default connect(
  () => ({
    text: 'Изпратихме Ви писмо за потвърждение на имейл адреса Ви и линк със следващата стъпка в Регистрацията.'
     + ' В редки случаи писмото може да попадне в папка "Спам", затова при забавяне, Ви молим да проверите и там.'
  }),
  (dispatch) => ({
    close: () => {
      dispatch(changeCreateClientEmailSentUI(false));
    }
  })
)(EmailSentPopup);
