import { connect } from 'react-redux';
import { searchLocations } from 'store/reducers/searchLocationResults';
import SearchField from './SearchField';

/**
 * Represents a search field for locations.
 */
export default connect(
  (state) => ({
    options: state.searchLocationResults
  }),
  (dispatch) => ({
    search: (value) => {
      dispatch(searchLocations(value));
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    onInputFocus: () => {
      if (stateProps.options.length === 0) {
        dispatchProps.search('');
      }
    },
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  })
)(SearchField);
