/* eslint-disable max-len */
import React from 'react';

export default function Minus() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="8.5"
        x2="15"
        y2="8.5"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
