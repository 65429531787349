import React from 'react';
import { useIsMobile } from 'hooks';
import desktopImg from 'assets/img/main-background.svg';
import mobileImg from 'assets/img/main-background-mobile.svg';
import './MainBackground.scss';

const MainBackground = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="main-background">
        <img alt="" src={isMobile ? mobileImg : desktopImg} />
      </div>
      <div className="large-screen-backup-background" />
    </>
  );
};

export default MainBackground;
