import React, { useState, useMemo, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

import passwordIcon from 'assets/img/password.svg';
import emailIcon from 'assets/img/email.svg';
import phoneIcon from 'assets/img/phone-simple.svg';

import passwordErrorIcon from 'assets/img/password-error.svg';
import emailErrorIcon from 'assets/img/email-error.svg';
import phoneErrorIcon from 'assets/img/phone-simple-error.svg';

import './InputField.scss';

/**
 * Represents an input field.
 *
 * @param {String}  type: html type attribute
 * @param {String}  label: optional label displayed on top of the field
 * @param {String}  placeholder: input placeholder
 * @param {Boolean} isMandatory: when true, displays an * symbil next to the label
 * @param {String}  background
 * @param {String}  error: when provided, displays the error state of the component and the error message
 * @param icon: input icon
 * @param {Boolean} noIcon: true to hide the icon
 * @param errorIcon: input error icon
 * @param {Boolean} disabled
 * @param {Boolean} multiline: when true, renders textarea
 * @param           props: rest of props passed to the <input />
 */
const InputField = ({ type = 'text', label, placeholder = '', isMandatory = true, background, error, icon, noIcon, errorIcon, disabled = false, multiline, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  const htmlId = useMemo(() => (
    uuid()
  ), []);

  useEffect(() => {
    if (disabled) {
      setIsActive(false);
    }
  }, [disabled]);

  const onFocus = () => {
    setIsActive(true);
  };

  const onBlur = () => {
    setIsActive(false);
  };

  return (
    <div className={cn('input-field-wrapper', { error })}>
      {label && (
        <label htmlFor={htmlId}>
          {label}
          {isMandatory && (
            <span className="mandatory-indicator"> *</span>
          )}
        </label>
      )}

      <div className={cn('input-field', { active: isActive, 'no-icon': noIcon, multiline })}>
        <div className="icon">
          <div className="inner-icon" style={{ background }}>
            <img src={error ? getErrorIcon(type, errorIcon) : getIcon(type, icon)} alt="" />
          </div>
        </div>
        <div className="field">
          <div className="inner-field">
            {multiline ? (
              <textarea
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                id={htmlId}
                style={{
                  background
                }}
                disabled={disabled}
                noResize /* eslint-disable-line react/no-unknown-property */
                {...props}
              />
            ) : (
              <input
                type={type}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                id={htmlId}
                style={{
                  background
                }}
                disabled={disabled}
                {...props}
              />
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">{error}</div>
      )}
    </div>
  );
};

const getIcon = (type, icon) => {
  if (icon) {
    return icon;
  }

  switch (type) {
    case 'password':
      return passwordIcon;
    case 'email':
      return emailIcon;
    case 'tel':
      return phoneIcon;
    default:
      return <></>;
  }
};

const getErrorIcon = (type, icon) => {
  if (icon) {
    return icon;
  }

  switch (type) {
    case 'password':
      return passwordErrorIcon;
    case 'email':
      return emailErrorIcon;
    case 'tel':
      return phoneErrorIcon;
    default:
      return <></>;
  }
};

export default InputField;
