import React from 'react';
import './OrSeparator.scss';

const OrSeparator = () => (
  <div className="or-separator">
    <span>или</span>
  </div>
);

export default OrSeparator;
