import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'lib/app';
import { PATHS } from 'config';
import arrow from 'assets/img/arrow-right-green.svg';
import './NewsSummary.scss';
import { CMS_BASE_URL } from 'const';

export default function NewsSummary({ item }) {
  return (
    <div className="news-summary" key={item.id}>
      <div className="cover-image">
        <Link to={`${PATHS.NEWS}/${item.canonical}`}>
          <img src={`${CMS_BASE_URL}${item.coverLarge.formats.small.url}`} alt={item.coverLarge.alternativeText} />
        </Link>
      </div>

      <div className="single-item-content">
        <div className="date">
          {formatDate(item.publishDate)}
        </div>

        <h2 className="title">
          <Link to={`${PATHS.NEWS}/${item.canonical}`}>
            {item.title}
          </Link>
        </h2>

        <div className="summary" data-richtext dangerouslySetInnerHTML={{ __html: item.summary }} />

        <Link to={`${PATHS.NEWS}/${item.canonical}`} className="link">Прочети още <img src={arrow} alt="" /></Link>
      </div>
    </div>
  );
}
