import React from 'react';
import cn from 'classnames';
import Label from '../Label/Label';

const FieldWrapper = React.forwardRef(({ children, className, htmlId, label, tooltip, isMandatory, error, isSuccess, ...props }, ref) => (
  <div className={cn(className, { error, success: isSuccess })} ref={ref} {...props}>
    <Label label={label} htmlId={htmlId} isMandatory={isMandatory} tooltipText={tooltip} />

    {children}

    {error && (
      <div className="error-message">{error}</div>
    )}
  </div>
));

export default FieldWrapper;
