import React, { Fragment } from 'react';
import Icon from 'components/Icon/Icon';
import { STEP_BEYOND_LAST_NAME } from 'const';
import editIcon from 'assets/img/edit.svg';
import { getStepZeroSummary } from './getStepZeroSummary';
import './RequestSummary.scss';

const BOOLEAN_TRUE_LABEL = 'да';
const BOOLEAN_FALSE_LABEL = 'не';

/**
 * Represents a request summary: steps and their attributes.
 *
 * @param {Object} stepZeroData: step zero data { type, period, amount, currency }
 * @param {Array} stepsData: an array of attribute data for each step;
 *                  [0]: represents data for step 1,
 *                  [1]: represents data for step 2,
 *                  ...
 * @param {Object} locationCodes: key-value pairs of location-code: location-object
 * @param {Array} bankData: an array of bank data items { code, name }
 * @param {Array} userData: when provided, displays the given array of personal user data attributes as a last item in the summary
 * @param {Boolean} displayEdit: when true, displays an edit icon next to each step
 * @param {Function} onStepChange: called when the user clicks to edit a specific step
 * @param {Function} getFieldLabel: returns the field label by the given displayName of the attribute
 */
const RequestSummary = ({ stepZeroData, stepsData, locationCodes, bankData, userData, displayEdit, onStepChange, getFieldLabel }) => (
  <div className="request-summary">
    <div className="step-name">
      <span className="text">1. Сума за кандидатстване</span>
      {displayEdit && (
        <Icon icon={editIcon} className="edit-icon" onClick={() => { onStepChange(0); }} />
      )}
    </div>

    <div className="attribute">
      {getStepZeroSummary(stepZeroData)}
    </div>

    {stepsData.map((stepData, index) => (
      <Fragment key={stepData.stepName}>
        <div className="step-name">
          <span className="text">{index + 2}. {stepData.stepName}</span>
          {displayEdit && (
            <Icon icon={editIcon} className="edit-icon" onClick={() => { onStepChange(index + 1); }} />
          )}
        </div>

        {stepData.attributes.map((attribute) => (
          <div className="attribute" key={attribute.name}>
            <span className="label">{getFieldLabel(attribute.displayName)}:</span>
            <strong>
              {attribute.type === 'BOOLEAN'
                ? getBooleanLabel(attribute.value)
                : attribute.type === 'LOCATION'
                  ? locationCodes[attribute.value]
                    ? `${locationCodes[attribute.value].name} (${locationCodes[attribute.value].region})`
                    : ''
                  : attribute.type === 'BANKDATA'
                    ? getBankDataLabel(attribute, bankData)
                    : attribute.value}
            </strong>
          </div>
        ))}
      </Fragment>
    ))}

    {userData && (
      <>
        <div className="step-name">
          <span className="text">{stepsData.length + 2}. {STEP_BEYOND_LAST_NAME}</span>
          {displayEdit && (
            <Icon icon={editIcon} className="edit-icon" onClick={() => { onStepChange(stepsData.length + 1); }} />
          )}
        </div>

        {userData.map((attr) => (
          attr.showInSummary !== false && (
            <div className="attribute" key={attr.name}>
              <span className="label">{attr.displayName}:</span>
              <strong>
                {attr.value}
              </strong>
            </div>
          )
        ))}
      </>
    )}
  </div>
);

const getBooleanLabel = (value) => (
  value === true
    ? BOOLEAN_TRUE_LABEL
    : value === false
      ? BOOLEAN_FALSE_LABEL
      : ''
);

const getBankDataLabel = (attribute, bankData) => {
  const bank = bankData.find((item) => item.code === attribute.value);
  return bank ? bank.name : '';
};

export default RequestSummary;
