import { useState, useEffect } from 'react';
import usePrevious from './usePrevious';

/**
 * A custom hook used to track a success state to a related input field.
 * A field state is successful when:
 *  - there is a default non-empty value
 *  - the error is cleared (it changes from non-empty to empty)
 *
 * @param {String} error: an error message related to the field
 * @param {any} defaultValue: initial value of the related field
 */
const useSuccessField = ({ error, defaultValue }) => {
  const [isSuccess, setIsSuccess] = useState(!!defaultValue || defaultValue === 0);

  const prevError = usePrevious(error);

  useEffect(() => {
    if (prevError && !error) {
      setIsSuccess(true);
    }

    if (error) {
      setIsSuccess(false);
    }
  }, [error]);

  return [isSuccess, setIsSuccess];
};

export default useSuccessField;
