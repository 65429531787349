import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { login } from 'store/reducers/user';
import { useFieldErrors, useLastPassEmailValidation, useNavigateWhen } from 'hooks';
import { isValidEmail } from 'lib';
import withGrecaptcha from 'lib/grecaptcha';
import { selectRedirectionPathAfterLogin } from 'store/reducers';
import { clearLoginRedirection } from 'store/reducers/loginRedirection';
import { clearWrongCredentials as clearWrongCredentialsAction } from 'store/reducers/wrongCredentials';
import LABELS from 'labels';
import Page from 'components/Page/Page';
import Button from 'components/Button/Button';
import FacebookButton from 'components/SocialButton/FacebookButton';
import GoogleButton from 'components/SocialButton/GoogleButton';
import OrSeparator from 'components/OrSeparator/OrSeparator';
import InputField from 'components/InputField/InputField';
import ForgottenPasswordPopup from './ForgottenPasswordPopup';
import Loader from 'components/Loader/Loader';
import './LoginPage.scss';

/**
 * Represents the login page.
 *
 * @param {Object} location: react-router's location object
 * @param {Function} loginUser: performs the log-in on the server
 * @param {Boolean} isLoading
 * @param {Boolean} wrongCredentials: indicates if the user tries to login with invalid credentials
 * @param {Function} clearWrongCredentials: clears wrong credentials state after showing the error message
 * @param {String} redirectTo: a link to redirect to when the login is successful, null while the user has not logged in
 * @param {Function} clearExistingLoginRedirect
 */
const LoginPage = ({ location, loginUser, isLoading, wrongCredentials, clearWrongCredentials, redirectTo, clearExistingLoginRedirect }) => {
  const { 'existing-account': existingEmail, 'invalid-token': invalidToken } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [email, setEmail] = useState(existingEmail ? atob(existingEmail) : '');
  const [password, setPassword] = useState('');
  const [isForgottenPasswordOpen, setIsForgottenPasswordOpen] = useState(false);
  const { errors, addError, clearFieldError, hasErrors } = useFieldErrors();

  useNavigateWhen({
    to: redirectTo,
    condition: !!redirectTo
  });

  useEffect(() => {
    clearExistingLoginRedirect();
  }, []);

  useEffect(() => {
    if (wrongCredentials) {
      addError({ password: 'Невалиден e-mail и/или парола' });
    }

    return clearWrongCredentials;
  }, [wrongCredentials]);

  const onSubmit = async (e) => {
    e.preventDefault();

    validateEmail(email);
    validatePassword();

    const hasError = await hasErrors();

    !hasError && withGrecaptcha(() => {
      loginUser({
        username: email,
        password
      });
    });
  };

  const validateEmail = (what) => {
    !isValidEmail(what) && addError({ email: LABELS.emailInvalid });
    what.length === 0 && addError({ email: LABELS.emailEmpty });

    if (isValidEmail(what)) {
      clearFieldError('email');
    }
  };

  const { lpValidate, hasLastPass } = useLastPassEmailValidation({ setEmail, validateEmail });

  const onEmailBlur = () => {
    if (hasLastPass()) {
      lpValidate();
      return;
    }

    validateEmail(email);
  };

  const validatePassword = () => {
    password.length === 0
      ? addError({ password: LABELS.passwordEmpty })
      : clearFieldError('password');
  };

  return (
    <Page className="login-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Kredy.bg</title>
      </Helmet>

      {isLoading && <Loader />}
      <div className="login-wrapper">
        <div className="box">
          <h1>Вход</h1>

          {existingEmail && (
            <div className="text">
              <p>Потребител с този e-mail адрес вече съществува.</p>
              { /* eslint-disable-next-line react/no-unescaped-entities */}
              <p>Ако сте забравил паролата си, натиснете линка "забравена парола".</p>
            </div>
          )}

          {invalidToken && (
            <div className="text">
              { /* eslint-disable-next-line react/no-unescaped-entities */}
              <p>Използвахте грешен или изтекъл линк за въвеждане на парола. Моля използвайте бутона "Забравена парола", за да получите нов линк.</p>
            </div>
          )}

          <GoogleButton label="Влез с Google" isLogin />
          <FacebookButton label="Влез с Facebook" isLogin />
          <OrSeparator />

          <form noValidate onSubmit={onSubmit}>
            <InputField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
              onBlur={onEmailBlur}
              error={errors.email}
            />

            <InputField
              type="password"
              label="Парола"
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
              onBlur={validatePassword}
              error={errors.password}
            />

            <div className="link forgotten-password" onClick={() => { setIsForgottenPasswordOpen(true); }}>Забравена парола?</div>

            <Button type="submit" label="Влез" theme="primary" disabled={isLoading} id="main-entrance" />
          </form>

          <div className="new-user">
            Нов потребител? Направете си <Link to="#todo" className="link">регистрация тук.</Link>
          </div>
        </div>
      </div>

      {isForgottenPasswordOpen && (
        <ForgottenPasswordPopup onClose={() => { setIsForgottenPasswordOpen(false); }} />
      )}
    </Page>
  );
};

export default withRouter(connect(
  (state) => ({
    isLoading: state.requestMonitor.login === 'pending' || state.requestMonitor.initRequestInProgress === 'pending',
    redirectTo: selectRedirectionPathAfterLogin(state),
    wrongCredentials: state.wrongCredentials
  }),
  (dispatch) => ({
    loginUser: (payload) => {
      dispatch(login(payload));
    },
    clearExistingLoginRedirect: () => {
      dispatch(clearLoginRedirection());
    },
    clearWrongCredentials: () => {
      dispatch(clearWrongCredentialsAction());
    }
  })
)(LoginPage));
