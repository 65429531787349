import { createSlice, createAction } from '@reduxjs/toolkit';

export const createClient = createAction('user/createClient');
export const verifyClient = createAction('user/verifyClient');
export const continueWithSocial = createAction('user/continueWithSocial');
export const login = createAction('user/login');
export const successfulLogin = createAction('user/successfulLogin');
export const sendForgottenPasswordRequest = createAction('user/sendForgottenPasswordRequest');
export const updatePassword = createAction('user/updatePassword');
export const fetchUserData = createAction('user/fetchUserData');
export const saveUserDataStep = createAction('user/saveUserDataStep');
export const updateUserAction = createAction('user/updateUserAction');

/**
 * Stores user data.
 */
const slice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUserData: (state, { payload }) => ({
      ...payload
    })
  }
});

/**
 * Checks whether there is data for a logged-in user.
 *
 * @param {Object} state: this reducer's state
 */
export const selectIsUserLoggedIn = (state) => (
  !!state.email
);

export const { setUserData } = slice.actions;
export default slice.reducer;
