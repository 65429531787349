import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * A custom hook used to select an option from a dropdown menu via the keyboard.
 * Increases accessibility.
 *
 * @param {Array} options: dropdown options
 * @param selectedOption: the currently selected option from the dropdown
 * @param {Boolean} isOpen: indicates if the dropdown is open
 * @param {Function} onSelectOption: called when an option is selected via the keyboard
 * @param {React.ref|Array} ref: react ref to the dropdown (element that can be blurred), can be an array of refs
 */
const useKeyboardSelection = ({ options, selectedOption, isOpen, onSelectOption, ref }) => {
  const [keyboardOptionIndex, setKeyboardOptionIndex] = useState(null);

  const refs = useMemo(() => (
    Array.isArray(ref) ? ref : [ref]
  ), [ref]);

  const getSelectedIndex = () => (
    options.findIndex((o) => o === selectedOption)
  );

  const onKeyDown = useCallback((e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();

      setKeyboardOptionIndex((state) => (
        Math.min(
          state !== null
            ? state + 1
            : selectedOption ? getSelectedIndex() + 1 : 0,
          options.length - 1
        )
      ));
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();

      setKeyboardOptionIndex((state) => (
        Math.max(
          state !== null
            ? state - 1
            : selectedOption ? getSelectedIndex() - 1 : options.length - 1,
          0
        )
      ));
    }

    if (e.key === 'Enter') {
      onSelectOption(options[keyboardOptionIndex]);
      refs.forEach((r) => {
        r.current.blur();
      });
      setKeyboardOptionIndex(null);
    }

    if (e.key === 'Escape') {
      refs.forEach((r) => {
        r.current.blur();
      });
      setKeyboardOptionIndex(null);
    }
  }, [keyboardOptionIndex, options, selectedOption, onSelectOption]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onKeyDown]);

  return keyboardOptionIndex;
};

export default useKeyboardSelection;
