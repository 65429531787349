import React from 'react';
import cn from 'classnames';

/**
 * Represents tab navigation for active / final requests.
 *
 * @param {String} activeTab: the name of the active tab ('active' / 'final')
 * @param {Function} onTabChange: called when the user changes the active tab
 */
const Tabs = ({ activeTab, onTabChange }) => (
  <div className="tabs">
    <div
      className={cn('tab', { active: activeTab === 'active' })}
      onClick={() => { onTabChange('active'); }}
    >
      Активни
    </div>
    <div
      className={cn('tab', { active: activeTab === 'final' })}
      onClick={() => { onTabChange('final'); }}
    >
      История
    </div>
  </div>
);

export default Tabs;
