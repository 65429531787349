import React from 'react';
import Slider from 'components/Slider/Slider';
import './InputBoundSlider.scss';

/**
 * Represents a slider that is two-way bound to an input field
 */
const InputBoundSlider = ({ className, value, preInputLabel, postInputLabel, minValue, maxValue, minLabel, maxLabel, step, onChange }) => {
  const onSliderChange = (nextValue) => {
    onChange(nextValue);
  };

  const onInputChange = (nextValue) => {
    onChange(Number(nextValue));
  };

  const validateRange = () => {
    onChange(Math.max(minValue, Math.min(maxValue, value)));
  };

  const selectAllText = (e) => {
    e.target.select();
  };

  return (
    <div className={`input-bound-slider ${className}`}>
      <div className="input-row">
        <label>
          <span>{preInputLabel}</span>
          <input
            type="number"
            value={value.toString()}
            onChange={(e) => { onInputChange(e.target.value); }}
            onBlur={validateRange}
            onClick={selectAllText}
          />
          <span>{postInputLabel}</span>
        </label>
      </div>

      <Slider
        min={minValue}
        max={maxValue}
        minLabel={minLabel}
        maxLabel={maxLabel}
        step={step}
        value={value}
        onChange={onSliderChange}
      />
    </div>
  );
};

export default InputBoundSlider;
