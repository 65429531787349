import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PATHS } from 'config';
import { clearAllRequests } from 'store/reducers/requestMonitor';
import { useNavigateWhen, useScrollToTop } from 'hooks';
import TopBar from 'components/TopBar/TopBar';
import Footer from './Footer/Footer';
import BottomBar from './BottomBar/BottomBar';
import useCookieBanner from './useCookieBanner';
import CookieBanner from 'components/CookieBanner/CookieBanner';
import './Page.scss';

/**
 * Common page layout.
 *
 * @param {React.Component} children: page-specific components
 * @param {String} className
 * @param {String} existingEmail: the existing email the user has entered which causes the app to redirect to login
 * @param {String} invalidToken: the invalid token the user uses which causes the app to redirect to login
 * @param {Object} generalError: contains error data and redirection info when a general error occurs
 * @param {React.Component} prefixComponent: component rendered outside the main layout wrapper, usually to take the 100vw
 */
const Page = ({ children, className, existingEmail, invalidToken, generalError, prefixComponent }) => {
  const dispatch = useDispatch();

  useScrollToTop();
  useNavigateWhen({ to: `${PATHS.LOGIN}?existing-account=${btoa(existingEmail)}`, condition: !!existingEmail });
  useNavigateWhen({ to: `${PATHS.LOGIN}?invalid-token=${invalidToken}`, condition: !!invalidToken });
  useNavigateWhen({ to: `${PATHS.ERROR}?k=${btoa(JSON.stringify(generalError))}`, condition: generalError.redirectToError });

  useEffect(() => (
    () => {
      dispatch(clearAllRequests());
    }
  ), []);

  const { showCookieBanner, onCookiesAgree } = useCookieBanner();

  return (
    <div className={`App ${className}`}>
      <Helmet>
        <meta property="og:image" content="https://cms.kredy.bg/uploads/Kredy_logo_c87d8cdcd3.png" />
      </Helmet>

      <TopBar />

      {prefixComponent}

      <div className="main-wrapper">
        {children}
      </div>

      <Footer />
      <BottomBar />

      {showCookieBanner && (
        <CookieBanner onAgree={onCookiesAgree} />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    existingEmail: state.loginRedirection.email,
    invalidToken: state.loginRedirection.invalidToken,
    generalError: state.generalError
  })
)(Page);
