import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { isValidEGN, isValidLNCH } from 'lib/egn';
import { isValidBulgarianPhone } from 'lib';
import { USER_DATA_ATTRIBUTES } from 'const';
import { fetchUserData } from 'store/reducers/user';
import { changeFieldValue } from 'store/reducers/userDraft';
import { addUserDraftErrorField, removeUserDraftErrorField } from 'store/reducers/userDraftErrorFields';
import FieldGenerator from './FieldGenerator';

/**
 * Custom validations for the hardcoded fields (in addition to the empty value validation).
 */
const CUSTOM_VALIDATIONS = {
  egn: {
    isInvalid: (value) => (
      !isValidEGN(value) && !isValidLNCH(value)
    ),
    message: 'Невалидно ЕГН/ЛНЧ.'
  },
  phone: {
    isInvalid: (value) => (
      !isValidBulgarianPhone(value)
    ),
    message: 'Невалиден телефон'
  },
  tna: {
    isInvalid: (value) => (
      !value
    )
  }
};

/**
 * Represents the final hardcoded step (called "Personal Data") in the Request Creation Flow.
 * It uses the FieldGenerator and provides attributes custom defined here (in contrast to being provided by the server).
 *
 * This component provides a validate() method of the given ref.
 *
 * @param {Function} init: requests user data and initializes the state
 * @param {Object} values: attribute values { key (field name): value }
 * @param {Function} changeField: changes field value
 * @param {Object} errorFields: indicates fields with errors { key (field name): error message or true }
 * @param {Function} addErrorField: adds state indicating an error to a field
 * @param {Function} removeErrorField: removes the error state from a field
 * @param {React.ref} ref: used to call the validate() method of the given ref (ref.current.validate())
 */
const StepBeyondLast = React.forwardRef(({ init, values, changeField, errorFields, addErrorField, removeErrorField }, ref) => {
  useEffect(() => {
    init();
  }, []);

  ref.current.validate = useCallback(() => {
    USER_DATA_ATTRIBUTES.forEach((attr) => {
      validateField(attr.name);
    });
  }, [values]);

  const onFieldBlur = (field) => {
    validateField(field.name);
  };

  const validateField = (fieldName) => {
    const value = values[fieldName];

    if (CUSTOM_VALIDATIONS[fieldName]) {
      !value
        ? addErrorField({ name: fieldName, error: true })
        : CUSTOM_VALIDATIONS[fieldName].isInvalid(value)
          ? addErrorField({ name: fieldName, error: CUSTOM_VALIDATIONS[fieldName].message })
          : removeErrorField(fieldName);

      return;
    }

    !value
      ? addErrorField({ name: fieldName, error: true })
      : removeErrorField(fieldName);
  };

  if (Object.keys(values).length === 0) {
    return null;
  }

  return (
    <FieldGenerator
      attributes={USER_DATA_ATTRIBUTES.map((attr) => ({
        ...attr,
        value: values[attr.name]
      }))}
      onFieldChange={changeField}
      onFieldBlur={onFieldBlur}
      errorFields={errorFields}
      locationCodes={{}}
      bankData={[]}
      getFieldLabel={(label) => label}
    />
  );
});

export default connect(
  (state) => ({
    values: state.userDraft,
    errorFields: state.userDraftErrorFields
  }),
  (dispatch) => ({
    init: () => {
      dispatch(fetchUserData());
    },
    changeField: (payload) => {
      dispatch(changeFieldValue(payload));
    },
    addErrorField: (payload) => {
      dispatch(addUserDraftErrorField(payload));
    },
    removeErrorField: (name) => {
      dispatch(removeUserDraftErrorField(name));
    }
  }),
  null,
  { forwardRef: true }
)(StepBeyondLast);
