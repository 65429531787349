import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { rejectOffer as rejectOfferAction } from 'store/reducers/offer';
import { clearRequest } from 'store/reducers/requestMonitor';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import './RejectFeedbackPopup.scss';

/**
 * Represents the reject feedback form. The user can provide feedback and reject the offer.
 *
 * @param {Object} offer: offer to be rejected
 * @param {Array} options: predefined options each one representing a reason for the rejection
 * @param {Function} rejectOffer: rejects the offer on the server
 * @param {Boolean} areButtonsDisabled: indicates that the reject offer request is in progress so the buttons should be disabled
 * @param {Boolean} isRequestRejected: indicates if the offer is rejected on the server, so the popup can close
 * @param {Function} cleanUp: clears global state
 * @param {Function} close: called to close the popup
 */
const RejectFeedbackPopup = ({ offer, options, rejectOffer, areButtonsDisabled, isOfferRejected, cleanUp, close }) => {
  const [reasonValue, setReasonValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => (
    cleanUp
  ), []);

  useEffect(() => {
    isOfferRejected && close();
  }, [isOfferRejected]);

  const changeReasonValue = (value) => {
    setReasonValue(value);
  };

  const onOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onReject = () => {
    rejectOffer({
      offerId: offer.id,
      reason: selectedOption,
      feedbackNote: reasonValue
    });
  };

  return (
    <Popup
      onClose={close}
      title="Отказ на оферта"
      className="reject-offer-feedback-popup"
    >
      <p className="description">
        Моля, отделете време, за да кажете защо отхвърляте офертата.
        Това ще ни помогне да Ви предоставяме по-добра услуга в бъдеще.
      </p>

      <ul className="options">
        {options.map((option) => {
          const key = Object.keys(option)[0];
          const label = option[key];

          return (
            <li key={key}>
              <input
                type="radio"
                id={`feedback_option_${key}`}
                name="option"
                value={key}
                onChange={onOptionChange}
                disabled={areButtonsDisabled}
              />
              <label htmlFor={`feedback_option_${key}`}>{label}</label>
            </li>
          );
        })}
      </ul>

      <textarea
        disabled={areButtonsDisabled || selectedOption !== 'OTHER'}
        onChange={(e) => { changeReasonValue(e.target.value); }}
      />

      <Button
        label="Изпрати"
        theme="primary"
        disabled={areButtonsDisabled || !selectedOption || (selectedOption === 'OTHER' && !reasonValue)}
        onClick={onReject}
        id="reject-offer-confirm"
      />
    </Popup>
  );
};

export default connect(
  (state) => ({
    options: state.offerRejectFeedbackDefinition,
    areButtonsDisabled: state.requestMonitor.rejectOffer === 'pending',
    isOfferRejected: state.requestMonitor.rejectOffer === 'success'
  }),
  (dispatch) => ({
    rejectOffer: (payload) => {
      dispatch(rejectOfferAction(payload));
    },
    cleanUp: () => {
      dispatch(clearRequest('rejectOffer'));
    }
  })
)(RejectFeedbackPopup);
