import { useQuery } from 'react-query';
import * as api from 'api';
import { useHTTPError } from 'hooks';

const useCreditList = () => {
  const { isLoading, error, data } = useQuery(
    'client-contracts',
    () => (
      api.fetchClientContracts()
    )
  );

  useHTTPError(error);

  return {
    isLoading,
    data: data && data[0] && data[0].content
  };
};

export default useCreditList;
