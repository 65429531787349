import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import image from 'assets/img/handshake.svg';
import './OfferAcceptedPopup.scss';

const OfferAcceptedPopup = ({ close }) => {
  usePreventGlobalScroll();

  return (
    <Popup onClose={close} title="Заявката е изпратена" className="offer-sent-popup">
      <img src={image} className="image" alt="" />
      {/* eslint-disable-next-line max-len */}
      <div className="details">Благодарим Ви, че се доверихте на Kredy.bg! Срокът за изпращане на оферта до Вас е пет работни дни. За всяка нова оферта ще получавате съобщение в профила си и на посочената от Вас електронна поща. Всяка оферта е валидна за приемане до 3 календарни дни. След приемането на офертата от Ваша страна, кредитен консултант ще се свърже с Вас, за да преминете към етапа на кандидатстване.</div>
      <Button label="Напред" theme="primary" onClick={close} id="accept-offer-confirm" />
    </Popup>
  );
};

export default OfferAcceptedPopup;
