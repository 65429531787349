import { useEffect } from 'react';
import qs from 'qs';
import { useMarkAsSeen } from 'hooks';

/**
 * Marks notification as seen if url contains ?n=[notificationId].
 */
const useHandleRoute = () => {
  const { markAsSeen } = useMarkAsSeen();

  useEffect(() => {
    const { n: notificationId } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!notificationId) {
      return;
    }

    markAsSeen(notificationId);
  }, []);
};

export default useHandleRoute;
