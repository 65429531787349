import React from 'react';
import cn from 'classnames';
import { CURRENCIES } from 'store/reducers/initialLoanDetails';
import Toggle from 'components/Toggle/Toggle';
import './CurrencyToggle.scss';

/**
 * Represents the BGN / EUR currency toggle
 */
const CurrencyToggle = ({ currency, onChange }) => {
  const onToggleChange = (isChecked) => {
    onChange(isChecked ? CURRENCIES.EUR : CURRENCIES.BGN);
  };

  return (
    <div className="currency-toggle">
      <div className={cn('text', { active: currency === CURRENCIES.BGN })}>BGN</div>
      <Toggle isChecked={currency === CURRENCIES.EUR} onChange={onToggleChange} />
      <div className={cn('text', { active: currency === CURRENCIES.EUR })}>EUR</div>
    </div>
  );
};

export default CurrencyToggle;
