import { createAction, createSlice } from '@reduxjs/toolkit';

export const fetchUserRequests = createAction('userRequests/fetchUserRequests');

const slice = createSlice({
  name: 'userRequests',
  initialState: [],
  reducers: {
    setUserRequests: (state, { payload }) => (
      [...payload]
    )
  }
});

export const { setUserRequests } = slice.actions;
export default slice.reducer;
