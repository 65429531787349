import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import AppPortal from 'components/AppPortal';
import Icon from 'components/Icon/Icon';
import closeIcon from 'assets/img/close.svg';
import Logo from 'components/Logo/Logo';
import styles from './index.module.scss';
import NotificationList from '../NotificationList';

/**
 * An overlay shown when the user opens the notification menu on mobile.
 *
 * @param {Function} onClose
 */
const MobileNotificationDropdown = ({ onClose, data }) => {
  usePreventGlobalScroll();

  const close = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <AppPortal>
      <div className={styles.mobileNotificationDropdown}>
        <div className={styles.header}>
          <Logo />
          <Icon icon={closeIcon} className={styles.close} onClick={close} />
        </div>

        <div className={styles.menuLinks}>
          <NotificationList data={data} />
        </div>
      </div>
    </AppPortal>
  );
};

export default MobileNotificationDropdown;
