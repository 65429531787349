import React from 'react';
import './SectionTitle.scss';

const SectionTitle = ({ label }) => (
  <div className="section-title">
    <h2>{label}</h2>
    <div className="title-separator" />
  </div>
);

export default SectionTitle;
