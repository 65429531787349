import React, { useEffect, useRef, useState } from 'react';
import { usePreventGlobalScroll } from 'hooks';
import AppPortal from 'components/AppPortal';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import InputField from 'components/InputField/InputField';
import closeIcon from 'assets/img/close.svg';
import Logo from 'components/Logo/Logo';
import MenuLinks from './MenuLinks';
import './MobileMenuContent.scss';
import LABELS from 'labels';
import { isValidEmail } from 'lib';
import withGrecaptcha from 'lib/grecaptcha';
import { connect } from 'react-redux';
import { createClient } from 'store/reducers/user';
import AcceptTncCheckbox from 'components/AcceptTncCheckbox/AcceptTncCheckbox';

/**
 * An overlay shown when the user opens the mobile menu.
 *
 * @param {Function} onClose
 */
const MobileMenuContent = ({ onClose, register, isButtonDisabled }) => {
  usePreventGlobalScroll();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const areTermsAcceptedRef = useRef();
  const [hasTermsAndConditionsError, setHasTermsAndConditionsError] = useState(false);

  useEffect(() => {
    window.addEventListener('hashchange', onClose);

    return () => {
      window.removeEventListener('hashchange', onClose);
    };
  }, []);

  const handleRegister = (e) => {
    e.preventDefault();

    setHasTermsAndConditionsError(!areTermsAcceptedRef.current);

    if (email.length === 0) {
      setEmailError(LABELS.emailEmpty);
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError(LABELS.emailInvalid);
      return;
    }

    setEmailError();
    if (areTermsAcceptedRef.current) {
      withGrecaptcha(() => {
        register({ email });
      });
    }
  };

  return (
    <AppPortal>
      <div className="mobile-menu-content">
        <div className="header">
          <Logo />
          <Icon icon={closeIcon} className="close" onClick={onClose} />
        </div>

        <div className="menu-links">
          <MenuLinks />
        </div>

        {!localStorage.accessToken && (
          <form noValidate onSubmit={handleRegister} className="form">
            <InputField
              type="email"
              placeholder="Email"
              isMandatory={false}
              value={email}
              error={emailError}
              onChange={(e) => { setEmail(e.target.value); }}
            />

            <AcceptTncCheckbox
              onChange={(isChecked) => { areTermsAcceptedRef.current = isChecked; }}
              showError={hasTermsAndConditionsError}
            />

            <Button label="Започни сега" theme="primary" type="submit" className="register-button" disabled={isButtonDisabled} id="mobile-start-now" />
          </form>
        )}
      </div>
    </AppPortal>
  );
};

export default connect(
  (state) => ({
    isButtonDisabled: state.requestMonitor.createClient === 'pending'
  }),
  (dispatch) => ({
    register: (args) => {
      dispatch(createClient(args));
    }
  })
)(MobileMenuContent);
