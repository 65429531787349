export const isValidEmail = (email) => (
  email.includes('@') && email.includes('.')
);

export const isValidBulgarianPhone = (phone) => (
  !!phone.match(/^\+359[8-9]{1}[0-9]{8}$/)
);

export const callWhenValueExists = ({ effect, getValue }) => {
  if (getValue()) {
    effect(getValue());
  } else {
    setTimeout(() => {
      callWhenValueExists({ effect, getValue });
    }, 50);
  }
};

export const preloadImage = (url) => {
  const img = new window.Image();
  img.src = url;
};
