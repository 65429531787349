import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSuccessField } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldIndicatorIcon from '../FieldIndicatorIcon/FieldIndicatorIcon';
import './StepInput.scss';

const StepInput = ({ type = 'text', label, tooltip, isMandatory = true, error, defaultValue, onChange, onBlur, ...props }) => {
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });

  const htmlId = useMemo(() => (
    uuid()
  ), []);

  const handleChange = (e) => {
    onChange(parseValue(e.target.value));
  };

  const handleBlur = (e) => {
    onBlur();

    if (e.target.value && !error) {
      setIsSuccess(true);
    }
  };

  const parseValue = (value) => (
    value.length === 0
      ? ''
      : type === 'number' ? Number(value) : value
  );

  return (
    <FieldWrapper
      className="step-input-wrapper"
      htmlId={htmlId}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
    >
      <div className="input">
        <input
          {...props}
          type={type}
          onBlur={handleBlur}
          id={htmlId}
          defaultValue={defaultValue}
          onChange={handleChange}
        />

        <FieldIndicatorIcon isError={!!error} isSuccess={isSuccess} />
      </div>
    </FieldWrapper>

  );
};

export default StepInput;
