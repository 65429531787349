export const isValidEGN = (value) => {
  if (value.length !== 10) {
    return false;
  }

  const month = parseInt(value.substr(2, 2));
  if (month <= 0 || month > 12 + 40) {
    return false;
  }

  const day = parseInt(value.substr(4, 2));
  if (day <= 0 || day > 31) {
    return false;
  }

  const digits = value.split('').splice(0, 9).map((char) => parseInt(char));
  const weights = [2, 4, 8, 5, 10, 9, 7, 3, 6];

  const checksum = digits.reduce((result, currentDigit, index) => (
    result + currentDigit * weights[index]
  ), 0) % 11;

  const lastDigit = parseInt(value.substr(9, 1));
  return checksum % 10 === lastDigit;
};

export const isValidLNCH = (value) => {
  if (value.length !== 10) {
    return false;
  }

  const digits = value.split('').splice(0, 9).map((char) => parseInt(char));
  const weights = [21, 19, 17, 13, 11, 9, 7, 3, 1];

  const checksum = digits.reduce((result, currentDigit, index) => (
    result + currentDigit * weights[index]
  ), 0) % 10;

  const lastDigit = parseInt(value.substr(9, 1));
  return checksum % 10 === lastDigit;
};
