import React from 'react';
import Logo from 'components/Logo/Logo';
import Icon from 'components/Icon/Icon';
import facebookIcon from 'assets/img/facebook.svg';
import linkedinIcon from 'assets/img/linkedin.svg';
import './BottomBar.scss';

/**
 * Represents the bottom bar of the home page.
 */
const BottomBar = () => (
  <div className="bottom-bar">
    <Logo isBig />

    <div className="social-icons">
      <a href="https://www.facebook.com/Kredy-106185378769724" target="_blank" rel="noreferrer">
        <Icon icon={facebookIcon} />
      </a>
      <a href="https://www.linkedin.com/company/kredybg/about/" target="_blank" rel="noreferrer">
        <Icon icon={linkedinIcon} />
      </a>
    </div>

    <div className="copyright">
      © 2022 Всички права запазени | Kredy.bg
    </div>
  </div>
);

export default BottomBar;
