import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import logo from 'assets/img/logo.svg';
import kredyText from 'assets/img/kredy-text.svg';
import './Logo.scss';

/**
 * Represents the corporate logo.
 *
 * @param {Boolean} isBig: when true, the logo stays big and doesn't change its size depending on the resolution
 */
const Logo = ({ isBig }) => (
  <Link to="/" className={cn('logo', { big: isBig })}>
    <img alt="kredy.bg" src={logo} className="kredy-logo" />
    <img alt="kredy.bg" src={kredyText} className="kredy-text" />
  </Link>
);

export default Logo;
