import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'config';
import { fetchUserRequests } from 'store/reducers/userRequests';
import RequestList from './RequestList';
import './MyRequestsPage.scss';
import SideMenuPage from 'components/SideMenuPage/SideMenuPage';
import OfferAcceptedPopup from './OfferAcceptedPopup/OfferAcceptedPopup';
import LoginPage from 'pages/LoginPage/LoginPage';

/**
 * Represents user requests page.
 *
 * @param {Function} init: fetches necessary data and initializes the state
 * @param {Array} requestList: user requests
 * @param {Boolean} isLoading: indicates if the data is still being fetched
 */
const MyRequestsPage = ({ init, requestList, isLoading }) => {
  const [showOfferAcceptedPopup, setShowOfferAcceptedPopup] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (localStorage.offerAccepted) {
      delete localStorage.offerAccepted;
      setShowOfferAcceptedPopup(true);
    }
  }, []);

  if (!localStorage.accessToken) {
    return <LoginPage />;
  }

  return (
    <SideMenuPage
      isLoading={isLoading}
      activePath={PATHS.MY_REQUESTS}
    >
      <h1>Моите заявки и оферти</h1>
      <RequestList list={requestList} />

      {showOfferAcceptedPopup && (
        <OfferAcceptedPopup close={() => { setShowOfferAcceptedPopup(false); }} />
      )}
    </SideMenuPage>
  );
};

export default connect(
  (state) => ({
    requestList: state.userRequests,
    isLoading: state.requestMonitor.userRequests === 'pending'
  }),
  (dispatch) => ({
    init: () => {
      dispatch(fetchUserRequests());
    }
  })
)(MyRequestsPage);
