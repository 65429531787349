import { useMutation } from 'react-query';
import * as api from 'api';

const useChangePassword = () => {
  const { mutate, isLoading, error, isSuccess } = useMutation((data) => (
    api.changePassword(data)
  ));

  const changePassword = (data) => {
    mutate(data);
  };

  return {
    isUpdating: isLoading,
    changePassword,
    isSuccess,
    error: error && error.result && error.result.errors && error && error.result && error.result.errors[0] && error.result.errors[0].code
  };
};

export default useChangePassword;
