import { useState } from 'react';
import { isValidBulgarianPhone } from 'lib';

export const CODE = '+359';
const NEXT_DIGIT = ['8', '9'];
const LENGTH = 13;
const REGEX = /^\+359[8-9]{1}[0-9]{0,8}$/;

/**
 * A custom hook used to track the state of an user input of a valid Bulgarian phone.
 *
 * @param {String} defaultValue: initial phone value
 * @return {Array} [
 *            phone: the input value
 *            changePhone: changes the value if it is a valid one
 *            hasError: indicates if the value is invalid
 * ]
 */
const useBulgarianPhone = (defaultValue) => {
  const [phone, setPhone] = useState(defaultValue || CODE);
  const [hasError, setHasError] = useState(false);

  const changePhone = (userValue) => {
    if (userValue.length > LENGTH) {
      return;
    }

    setHasError(!isValidBulgarianPhone(userValue));

    if (userValue.length === 0) {
      setPhone(CODE);
      return;
    }

    if (!userValue.startsWith(CODE)) {
      return;
    }

    if (userValue.length > CODE.length && !NEXT_DIGIT.includes(userValue[CODE.length])) {
      return;
    }

    if (userValue.length > CODE.length + 1 && !userValue.match(REGEX)) {
      return;
    }

    setPhone(userValue);
  };

  return [phone, changePhone, hasError];
};

export default useBulgarianPhone;
