import React from 'react';
import styles from './index.module.scss';
import NotificationList from '../NotificationList';

export default function DesktopNotificationDropdown({ data }) {
  return (
    <div className={styles.notificationDropdown}>
      <NotificationList data={data} />
    </div>
  );
}
