import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'generalError',
  initialState: {
    redirectToError: false
  },
  reducers: {
    redirectToError: (state, { payload }) => ({
      redirectToError: true,
      ...payload
    }),
    clearGeneralErrorRedirection: () => ({
      redirectToError: false
    })
  }
});

export const { redirectToError, clearGeneralErrorRedirection } = slice.actions;
export default slice.reducer;
