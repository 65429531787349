import Button from 'components/Button/Button';
import InputField from 'components/InputField/InputField';
import Loader from 'components/Loader/Loader';
import { useFieldErrors } from 'hooks';
import LABELS from 'labels';
import React, { useEffect, useState } from 'react';
import useChangePassword from './useChangePassword';
import styles from './index.module.scss';

const PASSWORD_MIN_LENGTH = 8;

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const { errors, addError, hasErrors, clearErrors } = useFieldErrors();

  const {
    changePassword,
    isUpdating,
    error: changePasswordError,
    isSuccess: changePasswordSuccess
  } = useChangePassword();

  useEffect(() => {
    setOldPassword('');
    setPassword('');
    setRepeatPassword('');
  }, [changePasswordSuccess]);

  const submitNewPassword = async (e) => {
    e.preventDefault();
    clearErrors();

    oldPassword.length === 0 && addError({ oldPassword: LABELS.passwordEmpty });
    oldPassword.length > 0 && oldPassword.length < PASSWORD_MIN_LENGTH && addError({ oldPassword: LABELS.passwordShort });

    password.length === 0 && addError({ password: LABELS.passwordEmpty });
    repeatPassword.length === 0 && addError({ repeatPassword: LABELS.repeatPasswordEmpty });

    password.length > 0 && password.length < PASSWORD_MIN_LENGTH && addError({ password: LABELS.passwordShort });
    repeatPassword.length > 0 && repeatPassword.length < PASSWORD_MIN_LENGTH && addError({ repeatPassword: LABELS.repeatPasswordShort });

    const hasError = await hasErrors();
    !hasError && password !== repeatPassword && addError({ repeatPassword: LABELS.noMatch });

    if (await hasErrors()) {
      return;
    }

    changePassword({
      oldPassword,
      newPassword: password
    });
  };

  return (
    <form noValidate className={styles.form} onSubmit={submitNewPassword}>
      <div className="title">Промяна на парола</div>

      {isUpdating && (
        <Loader />
      )}

      <div className="profile-page-field">
        <InputField
          type="password"
          label="Настояща парола"
          value={oldPassword}
          onChange={(e) => { setOldPassword(e.target.value); }}
          error={errors.oldPassword || getServerError(changePasswordError)}
        />
      </div>

      <div className="profile-page-field">
        <InputField
          type="password"
          label="Нова парола"
          value={password}
          onChange={(e) => { setPassword(e.target.value); }}
          error={errors.password}
        />
      </div>

      <div className="profile-page-field">
        <InputField
          type="password"
          label="Потвърди нова парола"
          value={repeatPassword}
          onChange={(e) => { setRepeatPassword(e.target.value); }}
          error={errors.repeatPassword}
        />
      </div>

      <Button
        type="submit"
        label="Промени"
        theme="primary"
        disabled={!oldPassword || !password || !repeatPassword || isUpdating}
        id="change-password-change"
      />
    </form>
  );
}

const getServerError = (code) => {
  switch (code) {
    case '1':
      return 'Грешна парола';
    default:
      return null;
  }
};
