import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton } from './EmblaCarouselButtons';
import { useIsMobile } from 'hooks';
import './embla.scss';

/**
 * A reusable Carousel component that allows for sliding of content.
 *
 * @param {React.Component} children: slides are provided as children
 */
const EmblaCarousel = ({ children }) => {
  const isMobile = useIsMobile();

  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    containScroll: 'trimSnaps',
    slidesToScroll: 1
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    if (!embla) {
      return;
    }

    nextBtnEnabled || isMobile
      ? embla.scrollPrev()
      : embla.scrollTo(embla.selectedScrollSnap() - 2);
  }, [embla, nextBtnEnabled, isMobile]);

  const scrollNext = useCallback(() => {
    if (!embla) {
      return;
    }

    prevBtnEnabled || isMobile
      ? embla.scrollNext()
      : embla.scrollTo(2);
  }, [embla, prevBtnEnabled, isMobile]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {children.map((child, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="embla__slide" key={index}>
                <div className="embla__slide__inner">
                  {child}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="embla__prev-next-buttons">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </div>
    </>
  );
};

export default EmblaCarousel;
