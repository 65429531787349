import React, { useState } from 'react';
import cn from 'classnames';
import { PATHS } from 'config';
import Icon from 'components/Icon/Icon';
import chevronDown from 'assets/img/chevron-down.svg';
import { Link } from 'react-router-dom';
import { useNewsList } from 'hooks';
import './Footer.scss';

/**
 * Represents the home page footer.
 */
const Footer = () => {
  const [activeItem, setActiveItem] = useState();

  const toggle = (index) => {
    activeItem === index
      ? setActiveItem(null)
      : setActiveItem(index);
  };

  const { result: newsList } = useNewsList();

  return (
    <div className="page-footer">
      <div className="wrapper">
        <div className={cn('section toggleable', { expanded: activeItem === 0 })}>
          <Icon icon={chevronDown} className="chevron" />

          <div
            className="title"
            onClick={() => { toggle(0); }}
          >
            Меню
          </div>

          <ul>
            <li>
              <a href="/">Поискай оферта</a>
            </li>
            <li>
              <Link to={PATHS.ABOUT_US}>За нас</Link>
            </li>
            <li>
              <a href="/#how-it-works-section">Как работи Kredy?</a>
            </li>
            <li>
              <Link to={PATHS.NEWS}>Новини</Link>
            </li>
            <li>
              <Link to={PATHS.CONTACTS}>Контакти</Link>
            </li>
          </ul>
        </div>

        <div className={cn('section toggleable', { expanded: activeItem === 1 })}>
          <Icon icon={chevronDown} className="chevron" />

          <div
            className="title"
            onClick={() => { toggle(1); }}
          >
            За потребителя
          </div>

          <ul>
            <li>
              <Link to={PATHS.FAQ}>Често Задавани Въпроси</Link>
            </li>
            <li>
              <Link to={PATHS.TERMS}>Общи условия</Link>
            </li>
            <li>
              <Link to={PATHS.PRIVACY}>Политика за поверителност</Link>
            </li>
          </ul>
        </div>

        <div className={cn('section toggleable', { expanded: activeItem === 2 })}>
          <Icon icon={chevronDown} className="chevron" />

          <div
            className="title"
            onClick={() => { toggle(2); }}
          >
            Последни новини
          </div>

          {newsList && (
            <ul>
              {newsList.slice(0, 5).map((item) => (
                <li key={item.id}>
                  <Link to={`${PATHS.NEWS}/${item.canonical}`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* <div className="section">
          <div className="title">Контакти</div>
          <ul className="contact-list">
            <li>
              <img src={locationIcon} alt="" />
              <div>София, бул. България №1, Офис сграда Креди</div>
            </li>
            <li>
              <img src={emailIcon} alt="" />
              <div>hello@kredy.bg</div>
            </li>
            <li>
              <img src={phoneIcon} alt="" />
              <div>0888 123 456</div>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
