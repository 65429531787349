import React, { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSuccessField, useBulgarianPhone } from 'hooks';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldIndicatorIcon from '../FieldIndicatorIcon/FieldIndicatorIcon';
import './StepInput.scss';

/**
 * A Phone-specific field that restricts user input to a valid Bulgarian phone format.
 */
const PhoneInput = ({ label, tooltip, isMandatory = true, error, defaultValue, onChange, onBlur, ...props }) => {
  const [phone, setPhone] = useBulgarianPhone(defaultValue);
  const [isSuccess, setIsSuccess] = useSuccessField({ error, defaultValue });

  const htmlId = useMemo(() => (
    uuid()
  ), []);

  useEffect(() => {
    onChange(phone);
  }, [phone]);

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const handleBlur = (e) => {
    onBlur();

    if (e.target.value && !error) {
      setIsSuccess(true);
    }
  };

  return (
    <FieldWrapper
      className="step-input-wrapper"
      htmlId={htmlId}
      label={label}
      tooltip={tooltip}
      isMandatory={isMandatory}
      error={error}
      isSuccess={isSuccess}
    >
      <div className="input">
        <input
          {...props}
          type="tel"
          onBlur={handleBlur}
          id={htmlId}
          value={phone}
          onChange={handleChange}
        />

        <FieldIndicatorIcon isError={!!error} isSuccess={isSuccess} />
      </div>
    </FieldWrapper>

  );
};

export default PhoneInput;
