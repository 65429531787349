import Button from 'components/Button/Button';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import emailIcon from 'assets/img/email.svg';
import mobileIcon from 'assets/img/mobile.svg';
import viberIcon from 'assets/img/viber.svg';
import Checkbox from 'components/Checkbox/Checkbox';
import useNotificationPreference from './useNotificationPreference';
import useUpdatePreference from './updatePreference';
import Loader from 'components/Loader/Loader';

export default function Notifications() {
  const { data, isLoading } = useNotificationPreference();
  const [draftData, setDraftData] = useState();

  const { updatePreference, isUpdating } = useUpdatePreference();

  useEffect(() => {
    setDraftData(data);
  }, [data]);

  const changeDraftData = ({ type, field, isChecked }) => {
    setDraftData(draftData.map((preference) => (
      preference.type === type
        ? {
          ...preference,
          [field]: isChecked
        } : preference
    )));
  };

  const submit = (e) => {
    e.preventDefault();
    updatePreference(draftData);
  };

  return (
    <form noValidate onSubmit={submit}>
      <div className="title notification-title">Нотификации</div>

      {!isLoading && draftData && (
        <>
          <div className={styles.wrapper}>
            {isUpdating && (
              <Loader />
            )}

            <div className={styles.notifType}>
              <div className={styles.title}>
                <img src={emailIcon} alt="" />
                По имейл
              </div>

              <ul className={styles.list}>
                {draftData.map((preference) => (
                  <li key={preference.type}>
                    <Checkbox
                      label={getCheckboxLabel(preference.type)}
                      defaultValue={preference.email}
                      onChange={(isChecked) => {
                        changeDraftData({
                          type: preference.type,
                          field: 'email',
                          isChecked
                        });
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.notifType}>
              <div className={styles.title}>
                <img src={mobileIcon} alt="" />
                СМС Известие
              </div>

              <ul className={styles.list}>
                {draftData.map((preference) => (
                  <li key={preference.type}>
                    <Checkbox
                      label={getCheckboxLabel(preference.type)}
                      defaultValue={preference.sms}
                      onChange={(isChecked) => {
                        changeDraftData({
                          type: preference.type,
                          field: 'sms',
                          isChecked
                        });
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.notifType}>
              <div className={styles.title}>
                <img src={viberIcon} alt="" />
                По Viber
              </div>

              <ul className={styles.list}>
                {draftData.map((preference) => (
                  <li key={preference.type}>
                    <Checkbox
                      label={getCheckboxLabel(preference.type)}
                      defaultValue={preference.viber}
                      onChange={(isChecked) => {
                        changeDraftData({
                          type: preference.type,
                          field: 'viber',
                          isChecked
                        });
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <Button
            type="submit"
            label="Запази"
            theme="primary"
            disabled={isUpdating}
            id="notification-settings-save"
          />
        </>
      )}
    </form>
  );
}

const getCheckboxLabel = (type) => {
  switch (type) {
    case 'NEWSLETTER':
      return 'Новини';
    case 'ADVERTISEMENT':
      return 'Реклами';
    case 'PROMOTION':
      return 'Промоции';
    case 'REMINDER':
      return 'Напомняния';
    case 'REQUEST':
      return 'Заявки';
    case 'SURVEY':
      return 'Проучване';
    default:
      return type;
  }
};
