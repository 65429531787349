import { useMutation } from 'react-query';
import * as api from 'api';

const useSendMessage = () => {
  const { mutate, error, isLoading, isSuccess } = useMutation((data) => (
    api.sendContactUsMessage(data)
  ));

  const sendMessage = (data) => {
    mutate(data);
  };

  return {
    isLoading,
    sendMessage,
    error,
    isSuccess
  };
};

export default useSendMessage;
