import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loginRedirection',
  initialState: {
    email: null,
    invalidToken: null
  },
  reducers: {
    redirectToLogin: (state, { payload }) => ({
      email: payload.email,
      invalidToken: payload.token
    }),
    clearLoginRedirection: () => ({
      email: null,
      invalidToken: null
    })
  }
});

export const { redirectToLogin, clearLoginRedirection } = slice.actions;
export default slice.reducer;
