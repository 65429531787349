import React, { useState } from 'react';
import InputField from 'components/InputField/InputField';
import Action from './Action';

/**
 * Represents an input field that can only be changed when the user clicks on the change text.
 */
export default function Field({ type, icon, errorIcon, label, value, onChange, onBlur, error, onSave }) {
  const [isEditActive, setIsEditActive] = useState(false);

  const toggleEdit = () => {
    if (isEditActive) {
      onSave();
    }

    setIsEditActive((state) => !state);
  };

  return (
    <>
      <InputField
        type={type}
        icon={icon}
        errorIcon={errorIcon}
        label={label}
        isMandatory={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        disabled={!isEditActive}
      />

      <Action onClick={toggleEdit} showSave={isEditActive} hasError={!!error} />
    </>
  );
}
