import { useEffect, useState } from 'react';

/**
 * A custom hook used to track whether a boolean value has been recently set to true.
 *
 * @param {Number} interval: optional interval defining how many milliseconds are considered recent
 * @return {Array} [isRecent, setIsRecent]
 */
const useRecent = (interval) => {
  const [isRecent, setIsRecent] = useState(false);

  useEffect(() => {
    if (isRecent) {
      setTimeout(() => {
        setIsRecent(false);
      }, interval || 500);
    }
  }, [isRecent]);

  return [isRecent, setIsRecent];
};

export default useRecent;
