import Accordion from 'components/Accordion/Accordion';
import { useFaq } from 'hooks';
import React from 'react';

const FaqAccordion = ({ maxItems = undefined }) => {
  const { isLoading, result } = useFaq();

  if (isLoading) {
    return null;
  }

  return (
    <Accordion
      items={result.slice(0, maxItems).map((item) => ({
        title: item.title,
        content: item.answer
      }))}
    />
  );
};

export default FaqAccordion;
