import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import Carousel from 'components/Carousel/Carousel';
import FeedbackItem from './FeedbackItem';
import useClientReviews from './useClientReviews';
import './Feedback.scss';

/**
 * Represents the feedback section containing a carousel of slides.
 */
const Feedback = () => {
  const { isLoading, result: reviews } = useClientReviews();

  if (isLoading) {
    return null;
  }

  return (
    <div className="feedback-section">
      <SectionTitle label="Какво казват нашите клиенти" />
      <Carousel>
        {reviews.map((review) => (
          <FeedbackItem
            key={review.id}
            data-key={review.id}
            text={review.content}
            name={review.fullname}
            city={review.years}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default Feedback;
