import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { getStepDefinitions } from 'store/reducers/stepDefinitions';
import {
  setRequestData,
  setRequestDataSkipUpdatingInitialLoanDetails,
  initRequestInProgress as initRequestInProgressAction,
  setRequestDraftServiceTypeCode,
  saveStepZero as saveStepZeroAction,
  saveStep as saveStepAction,
  selectRequestDraftIdByServiceType,
  selectRequestDraftId,
  selectHasRequestForServiceType,
  changeRequestDraftAttributes,
  submitFinalRequest,
  initiateRequestFlowWithStepZero,
  endInitRequestInProgress,
  startInitRequestInProgress
} from 'store/reducers/requestDraft';
import { selectLoanAttributes, selectLoanDetails } from 'store/reducers/initialLoanDetails';
import { selectServiceTypeIdByCode } from 'store/reducers/serviceTypes';
import {
  checkStepAttributeValueForError,
  setStepErrorFields,
  addStepErrorField,
  removeStepErrorField,
  clearStepErrorFields
} from 'store/reducers/stepErrorFields';
import { addLocationData } from 'store/reducers/locationCodes';
import { selectRequestAttributesPerStep, selectRequestAttributeByName } from 'store/reducers';
import { startRequest, successRequest, failRequest } from 'store/reducers/requestMonitor';
import { successfulLogin } from 'store/reducers/user';
import { getBankData } from 'store/reducers/bankData';
import { LOAN_TYPES, getOppositeLoanType } from 'const';
import * as api from 'api';
import { handleRequestFailure } from './utils/errors';

function* watchInitRequestInProgress() {
  yield takeEvery(initRequestInProgressAction.type, initRequestInProgress);
}

/**
 * Initializes the requests-in-progress for the Request Creation Flow.
 * Depending on the requests' state, configures the request draft for different service type.
 *
 * @param {Object} action: when action.payload.requestId is provided, select the specified request by its id for the request draft
 */
function* initRequestInProgress(action) {
  yield put(startInitRequestInProgress());

  try {
    yield put(startRequest('initRequestInProgress'));

    const result = yield call(fetchRequestInProgress);

    if (result.length === 0) {
      yield put(setRequestDraftServiceTypeCode(LOAN_TYPES.PERSONAL));
    }

    if (result.length === 1) {
      if (result[0].initState) {
        yield put(setRequestDraftServiceTypeCode(result[0].serviceTypeCode));
      } else {
        const oppositeServiceType = getOppositeLoanType(result[0].serviceTypeCode);
        yield put(setRequestDraftServiceTypeCode(oppositeServiceType));
      }
    }

    if (result.length === 2) {
      if (action && action.payload && action.payload.requestId) {
        // when payload.requestId is provided, select the specified request by its id for the request draft

        const request = result.find((req) => req.id === action.payload.requestId);

        if (request) {
          yield put(setRequestDraftServiceTypeCode(request.serviceTypeCode));
          yield put(successRequest('initRequestInProgress'));
          return;
        }
      }
      // select request based on initState
      const requestInInitState = result.find((req) => req.initState);

      if (requestInInitState) {
        yield put(setRequestDraftServiceTypeCode(requestInInitState.serviceTypeCode));
      } else {
        // do nothing
        // ui will handle 2 requests that are both in initState === false
      }
    }

    yield put(successRequest('initRequestInProgress'));
  } catch (e) {
    yield put(failRequest('initRequestInProgress'));
    yield call(handleRequestFailure, e);
  } finally {
    yield put(endInitRequestInProgress());
  }
}

function* fetchRequestInProgress({ skipUpdatingInitialLoanDetails = false } = {}) {
  const [result] = yield call(api.fetchRequestInProgress);

  if (skipUpdatingInitialLoanDetails) {
    yield put(setRequestDataSkipUpdatingInitialLoanDetails(result));
  } else {
    yield put(setRequestData(result));
  }

  yield all(Object.keys(LOAN_TYPES).map((key) => (
    put(getStepDefinitions(LOAN_TYPES[key]))
  )));

  const allAttributes = result.reduce((prevValue, currentValue) => (
    prevValue.concat(currentValue.attributes)
  ), []);

  const locationAttribute = allAttributes.find((attr) => (
    attr.type === 'LOCATION'
  ));

  if (locationAttribute) {
    const [location] = yield call(api.fetchLocationByCode, locationAttribute.value);
    yield put(addLocationData(location));
  }

  yield put(getBankData());

  return result;
}

function* watchSaveStepZero() {
  yield takeEvery(saveStepZeroAction.type, saveStepZero);
}

function* saveStepZero() {
  const selectedServiceType = yield select((state) => state.initialLoanDetails.type);
  const selectedServiceTypeId = yield select((state) => selectServiceTypeIdByCode(state.serviceTypes, selectedServiceType));
  const saveableAttributes = yield select((state) => selectLoanAttributes(state.initialLoanDetails));

  const isExistingRequest = yield select((state) => selectHasRequestForServiceType(state.requestDraft, selectedServiceType));

  try {
    yield put(startRequest('saveRequestStep'));

    if (isExistingRequest) { // updates the existing request
      const requestId = yield select((state) => selectRequestDraftIdByServiceType(state.requestDraft, selectedServiceType));

      yield call(api.saveRequestData, {
        requestId,
        data: {
          serviceTypeId: selectedServiceTypeId,
          attributes: saveableAttributes
        }
      });

      yield put(setRequestDraftServiceTypeCode(selectedServiceType));
    } else { // creates a new request
      yield put(setRequestDraftServiceTypeCode(selectedServiceType));

      yield call(api.createRequest, {
        data: {
          serviceTypeId: selectedServiceTypeId,
          attributes: saveableAttributes
        }
      });

      yield call(fetchRequestInProgress);
    }

    yield put(changeRequestDraftAttributes({
      attributes: saveableAttributes,
      serviceTypeCode: selectedServiceType
    }));

    yield put(successRequest('saveRequestStep'));
  } catch (e) {
    yield put(failRequest('saveRequestStep'));
    yield call(handleRequestFailure, e);
  }
}

function* watchCheckAttributeForError() {
  yield takeEvery(checkStepAttributeValueForError.type, checkAttributeForError);
}

function* checkAttributeForError({ payload }) {
  const attribute = yield select((state) => selectRequestAttributeByName(state, payload));

  attribute.error
    ? yield put(addStepErrorField({ name: attribute.name, error: attribute.error }))
    : yield put(removeStepErrorField(attribute.name));
}

function* watchSaveStep() {
  yield takeEvery(saveStepAction.type, saveStep);
}

function* saveStep({ payload: stepIndex }) {
  const saveableAttributes = yield select((state) => selectRequestAttributesPerStep(state, stepIndex));

  const errorAttributes = saveableAttributes.filter((item) => item.error);

  if (errorAttributes.length > 0) {
    yield put(setStepErrorFields(errorAttributes.map((item) => ({
      name: item.name,
      error: item.error
    }))));

    return;
  }

  const selectedServiceType = yield select((state) => state.initialLoanDetails.type);
  const selectedServiceTypeId = yield select((state) => selectServiceTypeIdByCode(state.serviceTypes, selectedServiceType));
  const requestId = yield select((state) => selectRequestDraftId(state.requestDraft));

  try {
    yield put(startRequest('saveRequestStep'));

    yield call(api.saveRequestData, {
      requestId,
      data: {
        serviceTypeId: selectedServiceTypeId,
        attributes: saveableAttributes
      }
    });

    yield put(successRequest('saveRequestStep'));
    yield put(clearStepErrorFields());
  } catch (e) {
    yield put(failRequest('saveRequestStep'));
    yield call(handleRequestFailure, e);
  }
}

function* watchSubmit() {
  yield takeEvery(submitFinalRequest.type, submit);
}

function* submit() {
  const requestId = yield select((state) => selectRequestDraftId(state.requestDraft));

  try {
    yield put(startRequest('submitRequest'));
    yield call(api.submitRequest, requestId);
    yield put(successRequest('submitRequest'));

    yield call(fetchRequestInProgress);
    yield put(setRequestDraftServiceTypeCode(null));
  } catch (e) {
    yield put(failRequest('submitRequest'));
    yield call(handleRequestFailure, e);
  }
}

function* watchInitiateRequestFlow() {
  yield takeEvery(initiateRequestFlowWithStepZero.type, initiateFlowWithStepZero);
}

/**
 * Initiates the Request creation flow by saving new data for step zero.
 * It works for both existing and non-existing requests.
 */
function* initiateFlowWithStepZero() {
  try {
    yield call(fetchRequestInProgress, { skipUpdatingInitialLoanDetails: true });

    const userData = yield select((state) => selectLoanDetails(state.initialLoanDetails));
    yield put(setRequestDraftServiceTypeCode(userData.type));

    yield put(saveStepZeroAction());
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

function* watchSuccessfulLogin() {
  yield takeEvery(successfulLogin.type, afterSuccessfulLogin);
}

function* afterSuccessfulLogin() {
  try {
    yield call(initRequestInProgress);
  } catch (e) {
    yield call(handleRequestFailure, e);
  }
}

export default [
  watchInitRequestInProgress,
  watchCheckAttributeForError,
  watchSaveStepZero,
  watchSaveStep,
  watchSubmit,
  watchInitiateRequestFlow,
  watchSuccessfulLogin
];
