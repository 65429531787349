import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'wrongCredentials',
  initialState: false,
  reducers: {
    setWrongCredentials: () => true,
    clearWrongCredentials: () => false
  }
});

export const { setWrongCredentials, clearWrongCredentials } = slice.actions;
export default slice.reducer;
