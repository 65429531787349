import React, { useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useIsMobile } from 'hooks';
import { selectLoanDetails } from 'store/reducers/initialLoanDetails';
import { selectRequestAttributesPerStep, selectRequestDraftStepDefinition } from 'store/reducers';
import RequestSummary from 'components/RequestSummary/RequestSummary';

/**
 * Represents the summary on each step of the create request flow.
 *
 * @param {Object} stepZeroData: step zero data { type, period, amount, currency }
 * @param {Array} stepsData: an array of attribute data for each step;
 *                  [0]: represents data for step 1,
 *                  [1]: represents data for step 2,
 *                  ...
 * @param {Function} onStepChange: called when the user clicks to edit a specific step
 * @param {Object} locationCodes: key-value pairs of location-code: location-object
 * @param {Array} bankData: an array of bank data items { code, name }
 * @param {Function} getFieldLabel: returns the field label by the given displayName of the attribute
 */
const Summary = ({ stepZeroData, stepsData, onStepChange, locationCodes, bankData, getFieldLabel }) => {
  const isMobile = useIsMobile(1024);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const toggleCollapse = () => {
    setIsCollapsed((state) => !state);
  };

  return (
    <div className={cn('summary', { collapsed: isCollapsed, collapsible: isMobile })}>
      <div className="title" onClick={toggleCollapse}>Обобщение</div>

      <RequestSummary
        stepZeroData={stepZeroData}
        stepsData={stepsData}
        displayEdit
        locationCodes={locationCodes}
        onStepChange={onStepChange}
        bankData={bankData}
        getFieldLabel={getFieldLabel}
      />
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    stepZeroData: selectLoanDetails(state.initialLoanDetails),
    stepsData: new Array(ownProps.currentStep - 1).fill(null).map((_, index) => ({
      attributes: selectRequestAttributesPerStep(state, index + 1),
      stepName: selectRequestDraftStepDefinition(state, index + 1).displayName
    }))
  }),
)(Summary);
