import React from 'react';
import './SocialButton.scss';

const SocialButton = ({ icon, label, children, ...props }) => (
  <button type="button" className="social-button" {...props}>
    <img src={icon} alt="" />
    <span>{label}</span>
    {children}
  </button>
);

export default SocialButton;
