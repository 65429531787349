import EmblaCarousel from 'components/EmblaCarousel';
import NewsSummary from 'components/NewsSummary/NewsSummary';
import { useNewsList } from 'hooks';
import React from 'react';

export default function LatestNewsCarousel() {
  const { isLoading, result: newsList } = useNewsList();

  if (isLoading) {
    return null;
  }

  return (
    <EmblaCarousel>
      {newsList.map((item) => (
        <NewsSummary item={item} key={item.id} />
      ))}
    </EmblaCarousel>
  );
}
