import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import Checkbox from 'components/Checkbox/Checkbox';
import './AcceptTncCheckbox.scss';

/**
 * Represents a Checkbox to accept the terms and conditions and privacy policy.
 */
export default function AcceptTncCheckbox({ onChange, showError }) {
  return (
    <>
      <Checkbox
        label={(
          <div>
            Съгласявам се с <Link to={PATHS.TERMS}>общите условия</Link> и <Link to={PATHS.PRIVACY}>политиката за лични данни</Link> на Kredy.bg
          </div>
        )}
        onChange={onChange}
      />

      {showError && (
        <div className="tnc-error">
          За да продължите напред, моля, отбележете съгласието си.
        </div>
      )}
    </>
  );
}
