import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import qs from 'qs';
import { changeCreateClientUI } from 'store/reducers/ui';
import { changeType, changeAmount, changePeriod, changeCurrency, CURRENCIES } from 'store/reducers/initialLoanDetails';
import { selectIsRequestOfTypeNotInInitState } from 'store/reducers/requestDraft';
import { selectIsInitialLoanDataLoaded } from 'store/reducers';
import { LOAN_TYPES } from 'const';
import RequestRedirect from './RequestRedirect';
import TabContent from './TabContent';
import Tooltip from 'components/Tooltip/Tooltip';
import './SliderBox.scss';

const IS_MORTGAGE_FEATURE_COMING_SOON = false;

const MONEY_RANGE_CONFIG = {
  [LOAN_TYPES.PERSONAL]: {
    [CURRENCIES.BGN]: {
      minValue: 10000,
      maxValue: 100000,
      minLabel: '10 000 лв.',
      maxLabel: '100 000 лв.'
    },
    [CURRENCIES.EUR]: {
      minValue: 5000,
      maxValue: 50000,
      minLabel: '5 000 €',
      maxLabel: '50 000 €'
    }
  },
  [LOAN_TYPES.MORTGAGE]: {
    [CURRENCIES.BGN]: {
      minValue: 20000,
      maxValue: 2000000,
      minLabel: '20 000 лв.',
      maxLabel: '2 000 000 лв.'
    },
    [CURRENCIES.EUR]: {
      minValue: 10000,
      maxValue: 1000000,
      minLabel: '10 000 €',
      maxLabel: '1 000 000 €'
    }
  }
};

const YEAR_RANGE_CONFIG = {
  [LOAN_TYPES.PERSONAL]: {
    minValue: 1,
    maxValue: 10,
    minLabel: '1 година',
    maxLabel: '10 години'
  },
  [LOAN_TYPES.MORTGAGE]: {
    minValue: 1,
    maxValue: 35,
    minLabel: '1 година',
    maxLabel: '35 години'
  }
};

/**
 * Represents the slider box UI.
 *
 * @param {Object}   loanDetails: current user selection about loan
 * @param {Function} changeLoanType
 * @param {Function} changeLoanAmount
 * @param {Function} changeLoanPeriod
 * @param {Function} changeLoanCurrency
 * @param {Function} openPopup: opens the register popup
 * @param {Boolean}  isPersonalTabDisabled: indicates if the personal loan tab is disabled
 * @param {Boolean}  isMortgageTabDisabled: indicates if the mortgage loan tab is disabled
 * @param {Boolean}  isDataLoading: indicates if the slider box data is still loading from the server. Always false if the user is not logged-in
 */
const SliderBox = ({
  loanDetails,
  changeLoanType,
  changeLoanAmount,
  changeLoanPeriod,
  changeLoanCurrency,
  openPopup,
  isPersonalTabDisabled,
  isMortgageTabDisabled,
  isDataLoading
}) => {
  const [requestRedirect, setRequestRedirect] = useState(false);
  const [showAnimationClass, setShowAnimationClass] = useState(true);

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (params.crtype && Object.values(LOAN_TYPES).includes(params.crtype)) {
      changeLoanType(params.crtype);
    }
  }, []);

  useEffect(() => {
    if (!isDataLoading) {
      setTimeout(() => {
        setShowAnimationClass(false);
      }, 500);
    }
  }, [isDataLoading]);

  /**
   * Changes the active tab if the active tab is disabled
   */
  useEffect(() => {
    if (!isPersonalTabDisabled || !isMortgageTabDisabled) {
      if (isPersonalTabDisabled) {
        changeLoanType(LOAN_TYPES.MORTGAGE);
      }

      if (isMortgageTabDisabled) {
        changeLoanType(LOAN_TYPES.PERSONAL);
      }
    }
  }, [isPersonalTabDisabled, isMortgageTabDisabled]);

  const submit = () => {
    localStorage.accessToken
      ? setRequestRedirect(true)
      : openPopup();
  };

  return (
    <div
      className={cn('slider-box', {
        loading: requestRedirect,
        'no-data': isDataLoading,
        'data-loaded-animation': !isDataLoading && showAnimationClass
      })}
    >
      {requestRedirect && <RequestRedirect onFail={() => { setRequestRedirect(false); }} />}

      <div className="tabs">
        <Tooltip
          text={isPersonalTabDisabled ? 'Вече имате изпратена заявка от този вид' : 'Изберете потребителски'}
          disable={!isPersonalTabDisabled && loanDetails.type === LOAN_TYPES.PERSONAL}
        >
          <button
            type="button"
            {...(isPersonalTabDisabled
              ? {}
              : { onClick: () => { changeLoanType(LOAN_TYPES.PERSONAL); } }
            )}
            className={cn({
              active: loanDetails.type === LOAN_TYPES.PERSONAL,
              disabled: isPersonalTabDisabled || isDataLoading
            })}
          >
            Потребителски
          </button>
        </Tooltip>
        <Tooltip
          text={IS_MORTGAGE_FEATURE_COMING_SOON
            ? 'Очаквайте скоро'
            : isMortgageTabDisabled ? 'Вече имате изпратена заявка от този вид' : 'Изберете ипотечен'}
          disable={!IS_MORTGAGE_FEATURE_COMING_SOON && !isPersonalTabDisabled && loanDetails.type === LOAN_TYPES.MORTGAGE}
        >
          <button
            type="button"
            {...(IS_MORTGAGE_FEATURE_COMING_SOON || isMortgageTabDisabled
              ? {}
              : { onClick: () => { changeLoanType(LOAN_TYPES.MORTGAGE); } }
            )}
            className={cn({
              active: loanDetails.type === LOAN_TYPES.MORTGAGE,
              disabled: IS_MORTGAGE_FEATURE_COMING_SOON || isMortgageTabDisabled || isDataLoading
            })}
          >
            Ипотечен
          </button>
        </Tooltip>
      </div>

      <TabContent
        isHidden={loanDetails.type !== LOAN_TYPES.PERSONAL}
        data={loanDetails[LOAN_TYPES.PERSONAL]}
        moneyRangeConfig={MONEY_RANGE_CONFIG[LOAN_TYPES.PERSONAL]}
        yearRangeConfig={YEAR_RANGE_CONFIG[LOAN_TYPES.PERSONAL]}
        onSubmit={submit}
        isSubmitDisabled={isPersonalTabDisabled && isMortgageTabDisabled}
        changeAmount={(amount) => {
          changeLoanAmount({
            type: LOAN_TYPES.PERSONAL,
            amount
          });
        }}
        changePeriod={(period) => {
          changeLoanPeriod({
            type: LOAN_TYPES.PERSONAL,
            period
          });
        }}
        changeCurrency={(currency) => {
          changeLoanCurrency({
            type: LOAN_TYPES.PERSONAL,
            currency
          });
        }}
      />

      <TabContent
        isHidden={loanDetails.type !== LOAN_TYPES.MORTGAGE}
        data={loanDetails[LOAN_TYPES.MORTGAGE]}
        moneyRangeConfig={MONEY_RANGE_CONFIG[LOAN_TYPES.MORTGAGE]}
        yearRangeConfig={YEAR_RANGE_CONFIG[LOAN_TYPES.MORTGAGE]}
        onSubmit={submit}
        isSubmitDisabled={isPersonalTabDisabled && isMortgageTabDisabled}
        changeAmount={(amount) => {
          changeLoanAmount({
            type: LOAN_TYPES.MORTGAGE,
            amount
          });
        }}
        changePeriod={(period) => {
          changeLoanPeriod({
            type: LOAN_TYPES.MORTGAGE,
            period
          });
        }}
        changeCurrency={(currency) => {
          changeLoanCurrency({
            type: LOAN_TYPES.MORTGAGE,
            currency
          });
        }}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    loanDetails: state.initialLoanDetails,
    isPersonalTabDisabled: selectIsRequestOfTypeNotInInitState(state.requestDraft, LOAN_TYPES.PERSONAL),
    isMortgageTabDisabled: selectIsRequestOfTypeNotInInitState(state.requestDraft, LOAN_TYPES.MORTGAGE),
    isDataLoading: !selectIsInitialLoanDataLoaded(state)
  }),
  (dispatch) => ({
    changeLoanType: (type) => {
      dispatch(changeType(type));
    },
    changeLoanAmount: (amount) => {
      dispatch(changeAmount(amount));
    },
    changeLoanPeriod: (period) => {
      dispatch(changePeriod(period));
    },
    changeLoanCurrency: (currency) => {
      dispatch(changeCurrency(currency));
    },
    openPopup: () => {
      dispatch(changeCreateClientUI({ registerWithStepZero: true }));
    }
  })
)(SliderBox);
