import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Generates a clickable element - <a> or <button> depending on whether the href prop is defined.
 *
 * @param {String} href
 * @param {React.Component} children
 * @param props
 */
const Clickable = ({ href, children, ...props }) => (
  href
    ? props.target
      ? <a href={href} {...props}>{children}</a>
      : <Link to={href} {...props}>{children}</Link>
    : <button type="button" {...props}>{children}</button>
);

export default Clickable;
