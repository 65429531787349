import React from 'react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page/Page';
import PageLoader from 'components/PageLoader/PageLoader';
import { CMS_BASE_URL } from 'const';
import useAboutUsData from './useAboutUsData';
import { useIsMobile } from 'hooks';
import './AboutUsPage.scss';

/**
 * Represents the About is info.
 */
const AboutUsPage = () => {
  const { isLoading, result: data } = useAboutUsData();
  const isMobile = useIsMobile();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  const coverImage = isMobile
    ? data.cover.formats.small
    : data.cover;

  return (
    <Page
      className="about-us-page"
      prefixComponent={(
        <>
          <div className="main-cover-image">
            <img src={`${CMS_BASE_URL}${coverImage.url}`} alt={data.cover.alternativeText} />
          </div>
        </>
      )}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>За нас | Kredy.bg</title>

        <meta property="og:title" content="За нас | Kredy.bg" />
        <meta property="og:image" content={`${CMS_BASE_URL}${data.cover.url}`} />
      </Helmet>

      <div className="about-us-wrapper">
        <h1 className="title">{data.title_bg}</h1>

        <div className="content" data-richtext dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
    </Page>
  );
};

export default AboutUsPage;
