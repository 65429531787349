import { useState } from 'react';
import { isMobileWidth } from 'lib/app';
import useResize from './useResize';

/**
 * A custom hook used to check whether the current resolution is the mobile one.
 *
 * @param {Number} width: specify a mobile width treshold, if not specified, the app-global is used
 * @return {Boolean} true if on mobile resolution
 */
const useIsMobile = (width) => {
  const [isMobile, setIsMobile] = useState(isMobileWidth(width));

  useResize(() => {
    setIsMobile(isMobileWidth(width));
  });

  return isMobile;
};

export default useIsMobile;
