import { useEffect } from 'react';
/* eslint-disable */

const useInjectPinterestCode = () => {
  useEffect(() => {
    (function (d) {
      var f = d.getElementsByTagName('SCRIPT')[0],
        p = d.createElement('SCRIPT');
      p.type = 'text/javascript';
      p.async = true;
      p.src = '//assets.pinterest.com/js/pinit.js';
      f.parentNode.insertBefore(p, f);
    })(document);
  }, []);
}

export default useInjectPinterestCode;
