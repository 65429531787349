import React from 'react';
import Page from 'components/Page/Page';
import './StaticPage.scss';
import Loader from 'components/Loader/Loader';
import { Helmet } from 'react-helmet';

/**
 * Represents a static page.
 */
const StaticPage = ({ page }) => {
  if (!page) {
    return <Loader />;
  }

  return (
    <Page className="static-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.title_bg} | Kredy.bg</title>
      </Helmet>
      <div className="static-page-wrapper">
        <h1 dangerouslySetInnerHTML={{ __html: page.fulltext_title_bg }} />
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    </Page>
  );
};

export default StaticPage;
