import React, { useEffect } from 'react';
import SocialContainer from './SocialContainer';
import { callWhenValueExists } from 'lib';
import googleIcon from 'assets/img/google.svg';
import SocialButton from './SocialButton';

/**
 * Represents a Google button and encapsulates google login functionality.
 */
const GoogleButton = ({ label, login }) => {
  const onSignIn = (googleUser) => {
    login({
      idToken: googleUser.getAuthResponse().id_token,
      socialType: 'GOOGLE'
    });
  };

  useEffect(() => {
    callWhenValueExists({
      effect: (gapi) => {
        gapi.signin2.render('g-signin2', {
          scope: 'https://www.googleapis.com/auth/plus.login',
          longtitle: true,
          theme: 'dark',
          onsuccess: onSignIn
        });
      },
      getValue: () => window.gapi
    });
  }, []);

  return (
    <SocialButton icon={googleIcon} label={label}>
      <div id="g-signin2" />
    </SocialButton>
  );
};

export default SocialContainer(GoogleButton);
