import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import UserMenuLinks from 'components/UserMenuLinks/UserMenuLinks';
import userSignedIcon from 'assets/img/user-signed.svg';
import chevronIcon from 'assets/img/chevron-down.svg';
import './UserMenu.scss';

const UserMenu = ({ userName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  const onBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const onClick = () => {
    setIsOpen((state) => !state);
  };

  return (
    <div className="user-menu-wrapper">
      <div
        className={cn('user-menu', { open: isOpen })}
        role="button"
        tabIndex={-1}
        onBlur={onBlur}
        onClick={onClick}
        ref={menuRef}
      >
        <img src={userSignedIcon} alt="" />
        <div className="name">{userName || ''}</div>
        <img src={chevronIcon} alt="" className="chevron" />
      </div>

      {isOpen && (
        <UserMenuLinks active={window.location.pathname} />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    userName: state.user.firstName
  })
)(UserMenu);
