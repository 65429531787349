import { createSlice, createAction } from '@reduxjs/toolkit';
import { LOAN_TYPES } from 'const';

export const saveStepZero = createAction('requestDraft/saveStepZero');
export const saveStep = createAction('requestDraft/saveStep');
export const submitFinalRequest = createAction('requestDraft/submitFinalRequest');
export const initiateRequestFlowWithStepZero = createAction('requestDraft/initiateRequestFlowWithStepZero');

const INITIAL_STATE = {
  serviceTypeCode: null,
  isDataLoaded: false,
  isInitializing: false,
  requests: []
};

/**
 * Keeps the data related to the creation of a request.
 */
const slice = createSlice({
  name: 'requestDraft',
  initialState: { ...INITIAL_STATE },
  reducers: {
    initRequestInProgress: (state) => {
      state.isDataLoaded = false;
    },
    setRequestDraftServiceTypeCode: (state, action) => {
      state.serviceTypeCode = action.payload;
    },
    setRequestData: (state, action) => {
      state.requests = action.payload;
      state.isDataLoaded = true;
    },
    setRequestDataSkipUpdatingInitialLoanDetails: (state, action) => {
      state.requests = action.payload;
      state.isDataLoaded = true;
    },
    changeRequestDraftAttributeValue: (state, action) => {
      const requestData = state.requests.find((req) => (
        req.serviceTypeCode === state.serviceTypeCode
      ));

      const attribute = requestData.attributes.find((attr) => attr.name === action.payload.name);

      attribute
        ? attribute.value = action.payload.value
        : requestData.attributes.push(action.payload);
    },
    changeRequestDraftAttributes: (state, action) => {
      const requestData = state.requests.find((req) => (
        req.serviceTypeCode === action.payload.serviceTypeCode
      ));

      action.payload.attributes.forEach((newAttribute) => {
        const index = requestData.attributes.findIndex((oldAttribute) => (
          oldAttribute.name === newAttribute.name
        ));

        requestData.attributes[index] = newAttribute;
      });
    },
    startInitRequestInProgress: (state) => {
      state.isInitializing = true;
    },
    endInitRequestInProgress: (state) => {
      state.isInitializing = false;
    }
  },
});

/**
 * Selects the request id for the draft's current serviceType.
 *
 * @param {Object} state: this reducer's state
 * @return {Number}
 */
export const selectRequestDraftId = (state) => (
  state.requests.find((req) => (
    req.serviceTypeCode === state.serviceTypeCode
  )).id
);

/**
 * Selects the request id for the given serviceType.
 *
 * @param {Object} state: this reducer's state
 * @param {String} serviceType: pcr or icr
 * @return {Number}
 */
export const selectRequestDraftIdByServiceType = (state, serviceType) => (
  state.requests.find((req) => (
    req.serviceTypeCode === serviceType
  )).id
);

/**
 * Checks if there is a pending (in progress) request for the given service type.
 *
 * @param {Object} state: this reducer's state
 * @param {String} serviceType
 * @return {Boolean}
 */
export const selectHasRequestForServiceType = (state, serviceType) => (
  state.requests.some((req) => (
    req.serviceTypeCode === serviceType
  ))
);

/**
 * Selects all attributes for the request draft.
 * The attributes keep field data filled by the user.
 *
 * @param {Object} state: this reducer's state
 * @return {Array}
 */
export const selectCurrentAttributes = (state) => {
  const requestData = state.requests.length > 0 && state.requests.find((req) => (
    req.serviceTypeCode === state.serviceTypeCode
  ));

  return requestData && requestData.attributes;
};

/**
 * Selects an attribute data by the given attribute name.
 *
 * @param {Object} state: this reducer's state
 * @param {String} attrName: attribute name
 * @return {Object} attribute data { name, value }
 */
export const selectAttributeByName = (state, attrName) => (
  selectCurrentAttributes(state).find((item) => (
    item.name === attrName
  ))
);

/**
 * Selects completedStep for the request draft.
 *
 * @param {Object} state: this reducer's state
 * @return {Number}
 */
export const selectRequestDraftCompletedStep = (state) => {
  const requestData = state.serviceTypeCode && state.requests.length > 0 && state.requests.find((req) => (
    req.serviceTypeCode === state.serviceTypeCode
  ));

  return requestData && requestData.completedStep;
};

/**
 * Checks whether there is at least one request not in InitState (already published).
 *
 * @param {Object} state: this reducer's state
 */
export const selectHasRequestNotInInitState = (state) => (
  state.requests.find((req) => (
    !req.initState
  ))
);

/**
 * Checks whether the request related to the given serviceTypeCode is not in InitState (already published).
 *
 * @param {String} state: this reducer's state
 * @param {String} serviceTypeCode: pcr/icr
 */
export const selectIsRequestOfTypeNotInInitState = (state, serviceTypeCode) => {
  const request = state.requests.find((req) => (
    req.serviceTypeCode === serviceTypeCode
  ));

  return request && !request.initState;
};

/**
 * Returns true only when:
 *  - there are 2 request, one per serviceTypeCode
 *  - both requests are not in InitState (they are both already published).
 *
 * @param {String} state: this reducer's state
 */
export const selectAreBothRequestsInNotInitState = (state) => {
  const personalLoanRequest = state.requests.find((req) => (
    req.serviceTypeCode === LOAN_TYPES.PERSONAL
  ));

  const mortgateRequest = state.requests.find((req) => (
    req.serviceTypeCode === LOAN_TYPES.MORTGAGE
  ));

  return personalLoanRequest && !personalLoanRequest.initState
    && mortgateRequest && !mortgateRequest.initState;
};

export const {
  initRequestInProgress,
  setRequestDraftServiceTypeCode,
  setRequestData,
  setRequestDataSkipUpdatingInitialLoanDetails,
  changeRequestDraftAttributeValue,
  changeRequestDraftAttributes,
  startInitRequestInProgress,
  endInitRequestInProgress
} = slice.actions;

export default slice.reducer;
