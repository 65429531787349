import React from 'react';
import { usePreventGlobalScroll } from 'hooks';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import mailImage from 'assets/img/mail-sent.svg';
import './EmailSentPopup.scss';

const KNOWN_EMAIL_PROVIDERS = ['abv.bg', 'gmail.com', 'dir.bg', 'yahoo.com', 'mail.bg', 'hotmail.com'];

const EmailSentPopup = ({ title, close, text, email }) => {
  usePreventGlobalScroll();
  const domain = getDomainByEmail(email);

  return (
    <Popup onClose={close} title={title || 'Писмо за потвърждение'} className="email-sent-popup">
      <img src={mailImage} className="image" alt="" />
      <div className="details">{text}</div>
      {KNOWN_EMAIL_PROVIDERS.includes(domain) && (
        <Button label="Към пощата" theme="primary" href={`https://${domain}`} target="_blank" onClick={close} id="towards-mail" />
      )}
    </Popup>
  );
};

const getDomainByEmail = (email) => (
  email.substr(email.indexOf('@') + 1)
);

export default EmailSentPopup;
