import React from 'react';
import ArrowButton from './ArrowButton';

const RightArrowButton = ({ onClick, disabled }) => (
  <ArrowButton
    className="chevron-right"
    onClick={onClick}
    disabled={disabled}
    svgPath="M0.614746 15.2669L7.39428 8.36094L0.614746 1.45496"
  />
);

export default RightArrowButton;
