import React, { useState } from 'react';
import cn from 'classnames';
import { Minus, Plus } from 'svg';
import './Accordion.scss';

const Accordion = ({ items }) => {
  const [activeItem, setActiveItem] = useState();

  const toggle = (index) => {
    activeItem === index
      ? setActiveItem(null)
      : setActiveItem(index);
  };

  return (
    <div className="accordion">
      <div className="wrapper">
        {items.map((item, index) => (
          <div className={cn('section toggleable', { expanded: activeItem === index })} key={item.title}>
            <div className="icon plus">
              <Plus />
            </div>
            <div className="icon minus">
              <Minus />
            </div>

            <div
              className="title"
              onClick={() => { toggle(index); }}
            >
              {item.title}
            </div>

            <div className="content" data-richtext dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
