const LABELS = {
  passwordEmpty: 'Паролата не може да е празна',
  repeatPasswordEmpty: 'Паролата не може да е празна',
  passwordShort: 'Паролата трябва да е минимум 8 символа',
  repeatPasswordShort: 'Паролата трябва да е минумум 8 символа',
  noMatch: 'Паролите трябва да съвпадат',
  emailEmpty: 'Email-ът не може да е празен',
  emailInvalid: 'Email-ът е невалиден',
  phoneInvalid: 'Телефонът е невалиден'
};

export default LABELS;
