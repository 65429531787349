/* eslint-disable max-len */
import LeftArrowButton from 'components/ArrowButtons/LeftArrowButton';
import RightArrowButton from 'components/ArrowButtons/RightArrowButton';
import React from 'react';

export const PrevButton = ({ enabled, onClick }) => (
  <LeftArrowButton onClick={onClick} disabled={!enabled} />
);

export const NextButton = ({ enabled, onClick }) => (
  <RightArrowButton onClick={onClick} disabled={!enabled} />
);
