import React, { useEffect, useRef, useState } from 'react';
import { useBulgarianPhone, useFieldErrors } from 'hooks';
import LABELS from 'labels';
import userIcon from 'assets/img/user.svg';
import userErrorIcon from 'assets/img/user-error.svg';
import egnIcon from 'assets/img/hash.svg';
import Field from '../Field';
import InputField from 'components/InputField/InputField';
import { connect } from 'react-redux';
import { updateUserAction } from 'store/reducers/user';
import LoginPage from 'pages/LoginPage/LoginPage';

/**
 * Represents user details on the profile page.
 *
 * @param {Object} user: user data
 * @param {Function} updateUser: updates user data on the server
 */
const UserDetails = ({ user, updateUser }) => {
  const didMountRef = useRef(false);
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [phone, setPhone, hasPhoneError] = useBulgarianPhone(user.phone || '');

  const { errors, addError, clearFieldError } = useFieldErrors();

  useEffect(() => {
    if (user.email) {
      user.firstName && setFirstName(user.firstName);
      user.lastName && setLastName(user.lastName);
      user.phone && setPhone(user.phone);
      didMountRef.current = true;
    }
  }, [user]);

  useEffect(() => {
    didMountRef.current && validateFirstName();
  }, [firstName]);

  useEffect(() => {
    didMountRef.current && validateLastName();
  }, [lastName]);

  if (!localStorage.accessToken) {
    return <LoginPage />;
  }

  const validateFirstName = () => {
    firstName.length === 0
      ? addError({ firstName: 'Името не може да е празно' })
      : clearFieldError('firstName');
  };

  const validateLastName = () => {
    lastName.length === 0
      ? addError({ lastName: 'Името не може да е празно' })
      : clearFieldError('lastName');
  };

  const validatePhone = () => {
    hasPhoneError
      ? addError({ phone: LABELS.phoneInvalid })
      : clearFieldError('phone');
  };

  const save = () => {
    updateUser({
      firstName,
      lastName,
      phone,
      egn: user.egn
    });
  };

  return (
    <>
      <div className="profile-page-field">
        <Field
          type="text"
          icon={userIcon}
          errorIcon={userErrorIcon}
          label="Име"
          value={firstName}
          onChange={(e) => { setFirstName(e.target.value); }}
          error={errors.firstName}
          onSave={save}
        />
      </div>

      <div className="profile-page-field">
        <Field
          type="text"
          icon={userIcon}
          errorIcon={userErrorIcon}
          label="Фамилия"
          value={lastName}
          onChange={(e) => { setLastName(e.target.value); }}
          error={errors.lastName}
          onSave={save}
        />
      </div>

      <div className="profile-page-field">
        <Field
          type="tel"
          label="Телефон"
          value={phone}
          onChange={(e) => { setPhone(e.target.value); }}
          onBlur={validatePhone}
          error={errors.phone}
          onSave={save}
        />
      </div>

      <div className="profile-page-field disabled">
        <InputField
          type="email"
          label="Email"
          value={user.email}
          isMandatory={false}
          disabled
        />
      </div>

      <div className="profile-page-field disabled">
        <InputField
          type="text"
          label="ЕГН"
          icon={egnIcon}
          value={user.egn || ''}
          isMandatory={false}
          disabled
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    user: state.user
  }),
  (dispatch) => ({
    updateUser: (data) => {
      dispatch(updateUserAction(data));
    }
  })
)(UserDetails);
