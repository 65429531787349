import { createAction, createSlice } from '@reduxjs/toolkit';

export const fetchProviderData = createAction('providers/fetchProviderData');

const slice = createSlice({
  name: 'providers',
  initialState: {},
  reducers: {
    addProviderData: (state, { payload }) => {
      state[payload.id] = payload.name;
    }
  },
});

export const { addProviderData } = slice.actions;
export default slice.reducer;
